import generateRandomNumber from './generateRandomNumber';

export default function getRandomColor() {
  const characters = '0123456789ABCDEF';
  let color = '#';

  for (let i: number = 0; i < 6; i++) {
    color += characters[generateRandomNumber(0, 15)];
  }

  return color;
}
