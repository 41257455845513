import * as React from 'react';
import {I18n} from 'react-i18next';

import Collapse from '@material-ui/core/Collapse';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Search from '@material-ui/icons/Search';
import memoize from 'memoize-one';

import {createStyled} from '../../../style';

/**
 * Data item and its display value, along with whatever item is to be reported on user click.
 */
interface IDataItem<T> {
  name: string;
  disabled: boolean;
  item: T;
}

/**
 * Grouped items and their (translated) group display name.
 */
export interface IGroupedItems<T> {
  [group: string]: Array<IDataItem<T>>;
}

interface IProps<T> {
  groupedItems: IGroupedItems<T>;
  onClick: (value: T) => void;
}

interface IState<T> {
  expandedGroupIndex: number | undefined;
  // Used when filtering, or when there's only a single group, both for which no groups are shown;
  // when not set then the props' `groupedItems` are rendered.
  flatItems: Array<IDataItem<T>> | undefined;
}

const Styled = createStyled(theme => ({
  formControl: {
    minWidth: '100px',
    flexGrow: 1,
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    // TODO arjan this needs a better solution, especially to keep the sticky search
    maxHeight: 300,
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 5,
  },
}));

/**
 * Selector for the report "dimensions" or "metrics".
 */
export default class DataSelector<T> extends React.Component<IProps<T>, IState<T>> {
  constructor(props: IProps<T>) {
    super(props);

    this.state = {
      expandedGroupIndex: undefined,
      flatItems: this.getFlatItemsIfNeeded(),
    };
  }

  private handleDataItemClick = (event: React.MouseEvent<HTMLElement>, item: T) => {
    this.props.onClick(item);
  };

  private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {groupedItems} = this.props;
    const dataItems = this.getDataItems(groupedItems);
    const search = event.target.value.trim().toLowerCase();

    if (!search) {
      this.setState({flatItems: this.getFlatItemsIfNeeded()});
      return;
    }

    const filteredItems = dataItems.filter(item => item.name.toLowerCase().indexOf(search) !== -1);
    this.setState({flatItems: filteredItems});
  };

  private handleGroupClick = (idx: number) => {
    this.setState(state => {
      if (state.expandedGroupIndex === idx) {
        return {expandedGroupIndex: undefined};
      }
      return {expandedGroupIndex: idx};
    });
  };

  /**
   * Get a list of flat items if there's only a single group or if explicitly forced.
   */
  private getFlatItemsIfNeeded = () => {
    const {groupedItems} = this.props;
    const groupCount = Object.keys(groupedItems).length;
    return groupCount === 1 ? this.getDataItems(groupedItems) : undefined;
  };

  /**
   * Get all nested data items from the grouped list, discarding the group information.
   */
  private getDataItems = memoize(
    (groupedItems: IGroupedItems<T>): Array<IDataItem<T>> => {
      return Object.keys(groupedItems).reduce((acc: Array<IDataItem<T>>, groupName: string) => {
        return acc.concat(groupedItems[groupName]);
      }, []);
    }
  );

  public render() {
    const {groupedItems} = this.props;
    const {expandedGroupIndex, flatItems} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <React.Fragment>
                <List
                  className={classes.list}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      <TextField
                        placeholder={t('filter.search_placeholder')}
                        className={classes.formControl}
                        autoFocus={true}
                        onChange={this.handleSearchChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListSubheader>
                  }
                >
                  {/* No grouping wanted, or search active: show single flat list without any grouping */}
                  {flatItems &&
                    flatItems.map((dataItem, idx: number) => (
                      <ListItem
                        key={idx}
                        divider={true}
                        button={true}
                        disabled={dataItem.disabled}
                        onClick={e => this.handleDataItemClick(e, dataItem.item)}
                      >
                        <ListItemText primary={dataItem.name} />
                      </ListItem>
                    ))}
                  {flatItems &&
                    flatItems.length === 0 && (
                      <ListItem divider={true} button={true} disabled={true}>
                        <ListItemText primary={t('filter.search_no_results')} />
                      </ListItem>
                    )}

                  {/* No search or no forced flat-view: show grouped/expandable lists */}
                  {!flatItems &&
                    Object.keys(groupedItems).map((groupName, groupIndex) => (
                      <React.Fragment key={groupIndex}>
                        <ListItem
                          divider={true}
                          button={true}
                          onClick={() => this.handleGroupClick(groupIndex)}
                        >
                          <ListItemText primary={groupName} />
                          {expandedGroupIndex === groupIndex ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        {/* unmountOnExit to ensure hidden items don't steal focus on keyboard navigation */}
                        <Collapse
                          in={expandedGroupIndex === groupIndex}
                          timeout="auto"
                          unmountOnExit={true}
                        >
                          <List disablePadding={true}>
                            {groupedItems[groupName].map((dataItem, idx: number) => (
                              <ListItem
                                key={groupIndex + '-' + idx}
                                divider={true}
                                button={true}
                                className={classes.nested}
                                disabled={dataItem.disabled}
                                onClick={e => this.handleDataItemClick(e, dataItem.item)}
                              >
                                <ListItemText primary={dataItem.name} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ))}
                </List>
              </React.Fragment>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
