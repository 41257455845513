export type Formatter = (value: any, column: IColumn) => any;

export interface IColumn {
  alias?: string;
  column: string;
  function?: string;
}

export function formatter(fns: Formatter[], value: any, column: IColumn): any {
  let formattedValue = value;
  const isValidValue = value !== undefined && value !== null;

  if (!isValidValue) {
    return value;
  }

  for (const fn of fns) {
    formattedValue = fn(formattedValue, column);
  }

  return formattedValue;
}

export {default as time} from './time';
export {default as price} from './price';
export {default as logType} from './logType';
export {default as weekDay} from './weekDay';
export {default as dayHour} from './dayHour';
export {default as month} from './month';
export {default as quarter} from './quarter';
export {default as toDate} from './toDate';
export {default as stringToDate} from './stringToDate';
export {default as translateEnum} from './translateEnum';
export {default as cleanNumber} from './cleanNumber';
