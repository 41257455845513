import {format as dateFnsFormat} from 'date-fns';

export type DateFormat =
  | typeof dateTimeFormat
  | typeof dateTimeFormatExt
  | typeof dateTimeFormatTz
  | typeof downloadFileTsFormat
  | typeof readableDateFormatMoment
  | typeof timeWithSecondFormat
  | typeof timeInMinuteFormat
  | typeof dateTimeFormatMoment
  | typeof dateFormat
  | typeof readableDateFormat
  | typeof readableDateTimeFormat
  | typeof readableDateMonthRevFormat
  | typeof readableDateRevFormat;

export const readableDateFormatMoment = 'D MMM, YYYY';
export const readableDateMonthRevFormat = 'MMM d';
export const readableDateRevFormat = 'MMM d, yyyy';
export const readableDateFormat = 'd MMM, yyyy';
export const readableDateTimeFormat = 'd MMM, yyyy HH:mm:ss';
export const timeWithSecondFormat = 'HH:mm:ss';
export const timeInMinuteFormat = 'HH:mm';
export const dateTimeFormatMoment = 'YYYY-MM-DD HH:mm';
export const dateFormat = 'yyyy-MM-dd';
export const dateTimeFormat = 'yyyy-MM-dd HH:mm';
export const dateTimeFormatExt = 'yyyy-MM-dd HH:mm:ss';
export const dateTimeFormatTz = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const downloadFileTsFormat = 'yyyy-MM-dd_HH-mm';

export function getClientTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function format(date: Date, formatStr?: DateFormat) {
  return dateFnsFormat(date, formatStr ? formatStr : dateTimeFormatExt);
}
