import * as React from 'react';
import {omit, groupBy} from 'lodash';

import {createStyled} from '../../style/';
import {IChart} from '../../api';
import BarChartCmp from './resultRenderers/BarChart';
import {ISelect} from '../ChartDialog/ChartDialog';
import LineChartCmp from './resultRenderers/LineChart';
import PieChartCmp from './resultRenderers/PieChart';

import {properties, getSelectKey} from '../../components/ChartDialog/properties';
import {formatter, IColumn} from '../../formatters';

const Styled = createStyled(theme => {
  return {
    linePadding: {
      // paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit * 2.5,
      width: '100%',
      height: '100%',
    },
  };
});
interface IProps {
  chart: IChart;
}

export default function ChartViewRenderer(props: IProps) {
  const {chart} = props;
  const metricData = getMetric(chart.criteria.select);
  const dimensionData = getDimension(chart.criteria.select);
  const {data, bars} = generateData(chart.results || [], metricData, dimensionData);
  const columnNames: string[] = chart.criteria.select.map((column: ISelect) =>
    getSelectKey(column)
  );
  const tableProperties = properties[chart.criteria.from];
  const columnsByName = tableProperties.reduce((acc: {}, column) => {
    if (columnNames.indexOf(getSelectKey(column)) > -1) {
      acc[getSelectKey(column)] = column;
    }

    return acc;
  }, {});
  const xValueFormatter = (value: string | number) => {
    return columnsByName[dimensionData.columnName] &&
      columnsByName[dimensionData.columnName].formatters
      ? formatter(columnsByName[dimensionData.columnName].formatters, value, dimensionData.column)
      : value;
  };

  const yValueFormatter = (value: string | number | Array<string | number>) => {
    return columnsByName[metricData.columnName] && columnsByName[metricData.columnName].formatters
      ? formatter(columnsByName[metricData.columnName].formatters, value, metricData.column)
      : value;
  };

  return (
    <>
      {chart.view === 'bars' && (
        <BarChartCmp
          xProperty={dimensionData.property}
          data={data}
          bars={bars}
          xAxisLabelFormat={xValueFormatter}
          yAxisLabelFormat={yValueFormatter}
          tooltipValueFormat={yValueFormatter}
        />
      )}
      {chart.view === 'lines' && (
        <Styled>
          {({classes}) => (
            <div className={classes.linePadding}>
              <LineChartCmp
                xProperty={dimensionData.property}
                data={data}
                lines={bars}
                xAxisLabelFormat={xValueFormatter}
                yAxisLabelFormat={yValueFormatter}
                tooltipValueFormat={yValueFormatter}
              />
            </div>
          )}
        </Styled>
      )}
      {chart.view === 'pie' &&
        chart.results && (
          <PieChartCmp
            data={chart.results}
            nameProperty={dimensionData.property}
            metricProperty={metricData.property}
            useIncrementalIndex={false}
            labelFormat={xValueFormatter}
            valueFormat={yValueFormatter}
          />
        )}
    </>
  );
}

function generateData(
  dataSets: any[][],
  metricProperty: {label: string; property: string},
  xProperty: {label: string; property: string}
): {data: any[]; bars: Array<{dataKey: string; label: string}>} {
  const fullData: any[] = [];
  const bars: Array<{dataKey: string; label: string}> = [];

  dataSets.forEach((d, i) => {
    const propertyName = `${metricProperty.property}${i}`;
    bars.push({
      dataKey: propertyName,
      label: metricProperty.label,
    });

    // Rename the metric property from the dataSet with given index as suffix
    // So it doesn't collide with the other dataSets
    const oData = d.map((od: object) => {
      return {
        ...omit(od, [metricProperty.property]),
        [propertyName]: od[metricProperty.property],
      };
    });

    fullData.push.apply(fullData, oData);
  });

  const dataGroupedByXProperty = groupBy(fullData, d => d[xProperty.property]);
  const data: any[] = [];

  // Finally, we merge the data into a single flat array
  Object.keys(dataGroupedByXProperty).map(xPropertyValue => {
    data.push(Object.assign({}, ...dataGroupedByXProperty[xPropertyValue]));
  });

  return {data, bars};
}

function getMetric(
  select: ISelect[]
): {label: string; property: string; columnName: string; column: IColumn} {
  return {
    property: (select[1].alias || select[1].column).toLowerCase(),
    label: select[1].alias || select[1].column,
    columnName: getSelectKey(select[1]),
    column: select[1],
  };
}

function getDimension(
  select: ISelect[]
): {label: string; property: string; columnName: string; column: IColumn} {
  return {
    property: (select[0].alias || select[0].column).toLowerCase(),
    label: select[0].alias || select[0].column,
    columnName: getSelectKey(select[0]),
    column: select[0],
  };
}
