export function shiftItemLeft(list: any[], index: number) {
  if (index > 0 && list.length > 0) {
    const item = list[index];
    const left = list.slice(0, index);
    const right = list.slice(index + 1);

    return [...left.slice(0, left.length - 1), item, left[left.length - 1], ...right];
  }

  return list;
}

export function shiftItemRight(list: any[], index: number) {
  if (index < list.length - 1) {
    const item = list[index];
    const left = list.slice(0, index);
    const right = list.slice(index + 1);

    return [...left, right[0], item, ...right.slice(1)];
  }

  return list;
}
