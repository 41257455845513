import * as React from 'react';
import {isEqual} from 'lodash';
import {I18n} from 'react-i18next';
import {Provider, Subscribe} from 'unstated';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {withStyles} from '@material-ui/core/styles';

import {createStyled} from '../../style/index';
import {ApiClient, IReportData} from '../../api';

import {Paper} from '@material-ui/core';
import ReportNameDialog from './ReportNameDialog';
import GoogleAnalytics from '../../GoogleAnalytics';

interface IState {
  tabIndex: number | false;
  showDefaultReport: boolean;
  loadingReport: boolean;
  showReportNameDialog: boolean;
  orderedReports: IReportData[];
}

interface IProps {
  reports: IReportData[];
  selectedReport?: number;
  // As we want to change the URL of the page when clicking a report tab
  // `handleReportChange` should be optional
  onReportNavigation?: (id: number) => void;
  onCreateReport: (name: string) => void;
  ga: GoogleAnalytics;
}

const Styled = createStyled(theme => {
  return {
    root: {
      flexGrow: 1,
      width: '100%',
    },
    row: {
      display: 'flex',
      width: '100%',
      marginBottom: theme.spacing.unit * 1.5,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'wrap',
        justifyContent: 'center',
      },
    },
    addLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  };
});

const StyledTabs = withStyles({
  root: {
    textTransform: 'none',
    flex: 1,
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    fontSize: '13px',
  },
})(Tab);

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: '13px',
  },
  '@media (max-width: 500px)': {
    root: {
      display: 'none',
    },
  },
})(Button);

export default class ReportSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabIndex: props.selectedReport || false,
      showDefaultReport: true,
      loadingReport: false,
      showReportNameDialog: false,
      orderedReports: this.orderReports(),
    };
  }

  private orderReports = (): IReportData[] => {
    const {reports} = this.props;
    const sortedUserReports = reports.sort((a, b) => {
      // In case the report have the same name,
      // We should sort them by id
      if (a.name === b.name) {
        return a.id > b.id ? 1 : -1;
      }

      return a.name > b.name ? 1 : -1;
    });

    const defaultReportIndex = sortedUserReports.findIndex(report => report.isDefault);

    return sortedUserReports.length && defaultReportIndex !== -1
      ? move(sortedUserReports, defaultReportIndex, 0)
      : [];
  };

  public componentDidUpdate(prevProps: IProps) {
    const {selectedReport, reports} = this.props;

    this.setState((prevState: IState) => {
      // Avoid infinite re-render
      if (prevState.tabIndex === selectedReport && isEqual(prevProps.reports, reports)) {
        return null;
      }

      return {tabIndex: selectedReport || false, orderedReports: this.orderReports()};
    });
  }

  public handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: any) => {
    this.setState((prevState: any) => {
      if (prevState.tabIndex === newTabIndex) {
        return prevState;
      }

      return {tabIndex: newTabIndex, loadingReport: true};
    });
  };

  private isDefaultReport = (reportId: number) => {
    const {orderedReports} = this.state;
    const defaultReport = orderedReports.find(report => report.isDefault);

    return defaultReport ? defaultReport.id === reportId : false;
  };

  public handleReportTabClick = (reportId: number) => {
    const {onReportNavigation, ga} = this.props;

    ga.trackEvent({
      category: 'New Reports',
      action: 'Tab Click',
      label: this.isDefaultReport(reportId) ? 'Default reports' : 'Custom reports',
    });

    if (onReportNavigation) {
      onReportNavigation(reportId);
    }
  };

  public handleDefaultReportClick = () => {
    this.setState({showDefaultReport: true});
  };

  public handleCreateReportPage = async () => {
    this.setState({showReportNameDialog: true});
  };

  public handleNewReportCancel = async () => {
    this.setState({showReportNameDialog: false});
  };

  public render() {
    const {onCreateReport} = this.props;
    const {tabIndex, showReportNameDialog, orderedReports} = this.state;

    return (
      <I18n>
        {t => (
          <Provider>
            <Subscribe to={[ApiClient]}>
              {(api: ApiClient) => (
                <Styled>
                  {({classes}) => (
                    <React.Fragment>
                      <Paper className={`${classes.row} hideOnPrint`}>
                        <StyledTabs
                          value={tabIndex}
                          onChange={this.handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          scrollButtons="on"
                          scrollable={true}
                        >
                          {/* Report tabs */}
                          {orderedReports &&
                            orderedReports.length &&
                            orderedReports.map(report => (
                              <StyledTab
                                key={report.id}
                                label={report.name}
                                value={report.id}
                                onClick={this.handleReportTabClick.bind(this, report.id)}
                              />
                            ))}
                        </StyledTabs>

                        <StyledButton onClick={this.handleCreateReportPage} color="primary">
                          <AddIcon />
                          <span className={classes.addLabel}>
                            {t('report.actions.add_report_page_bt')}
                          </span>
                        </StyledButton>
                      </Paper>
                      {showReportNameDialog && (
                        <ReportNameDialog
                          showDialog={showReportNameDialog}
                          onSubmit={onCreateReport}
                          onNewReportClose={this.handleNewReportCancel}
                          title={t('report.actions.new_confirm_title')}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Styled>
              )}
            </Subscribe>
          </Provider>
        )}
      </I18n>
    );
  }
}

const move = (originalArray: any[], from: number, to: number) => {
  const clone = [...originalArray];
  Array.prototype.splice.call(clone, to, 0, Array.prototype.splice.call(clone, from, 1)[0]);
  return clone;
};
