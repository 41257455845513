import * as React from 'react';
import {I18n} from 'react-i18next';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

interface IState {
  reportName: string;
}

interface IProps {
  onSubmit: (name: string) => void;
  onNewReportClose: () => void;
  showDialog: boolean;
  title: string;
  initialValue?: string;
}

export default class ReportNameDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      reportName: props.initialValue || '',
    };
  }

  public handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({reportName: event.target.value});
  };

  public handleNewReportCreate = async () => {
    const {onSubmit, onNewReportClose} = this.props;
    const reportName = this.state.reportName || 'New report';

    onNewReportClose();
    onSubmit(reportName);
  };

  public render() {
    const {showDialog, title, onNewReportClose} = this.props;
    const {reportName} = this.state;

    return (
      <I18n>
        {t => (
          <Dialog
            open={showDialog}
            aria-labelledby="new-report-confirmation-title"
            aria-describedby="new-report-confirmation-description"
          >
            {/* Dialog title */}
            <DialogTitle id="new-report-confirmation-title">{title}</DialogTitle>
            {/* Dialog content */}
            <DialogContent>
              <FormControl>
                <TextField
                  label={t('chart_dialog.steps.name.report_name_label')}
                  value={reportName}
                  onChange={this.handleNameChange}
                />
              </FormControl>
            </DialogContent>
            {/* Dialog actions */}
            <DialogActions>
              <Button onClick={onNewReportClose} color="primary">
                {t('report.actions.cancel_bt')}
              </Button>
              <Button onClick={this.handleNewReportCreate} color="primary">
                {t('report.actions.ok_bt')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </I18n>
    );
  }
}
