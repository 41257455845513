import * as React from 'react';
import {Subscribe} from 'unstated';
import {I18n} from 'react-i18next';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import ListItemText from '@material-ui/core/ListItemText';

import {createStyled, theme as mainTheme} from '../../style/index';
import {
  ApiClient,
  findUserReport,
  IChart,
  IReportData,
  ChartType,
  ChartView,
  IChartCriteria,
} from '../../api';
import OrderListDialog from '../OrderListDialog';

import {IReport} from './Report';
import Grid from '@material-ui/core/Grid/Grid';
import ReportNameDialog from './ReportNameDialog';
import AddChartBt from './AddChartBt';
import GoogleAnalytics from '../../GoogleAnalytics';

const Styled = createStyled(theme => {
  return {
    quickAccessIcon: {
      // padding: theme.spacing.unit * 1,
    },
    templateIcon: {
      backgroundColor: mainTheme.palette.primary.main,
      color: 'white',
    },
    actionBlock: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      marginLeft: theme.spacing.unit * 1.5,
    },
  };
});

interface IProps {
  selectedReportId: number;
  newReportEnabled: boolean;
  charts: IChart[];
  report: IReportData;
  onRewReportClick?: () => Promise<void>;
  onLoadReportClick?: (reportId: number) => Promise<void>;
  onDeleteReportClick?: () => Promise<void>;
  onChartOrderUpdate: (charts: IChart[]) => void;
  onReportNameUpdate: (name: string) => void;
  onDuplicateReport: (id: number, name: string) => void;
  onCreateChartClick: (
    data: {
      name: string;
      type: ChartType;
      view: ChartView;
      criteria: IChartCriteria;
    }
  ) => Promise<void>;
  onCreateDefaultChartClick: (
    data: {
      name?: string;
      type: ChartType;
    }
  ) => Promise<void>;
  ga: GoogleAnalytics;
}

interface IState {
  reportName: string;
  userReports: IReport[];
  actionsMenuAnchorEl: HTMLElement | undefined;
  newReportDialogOpen: boolean;
  loadDialogOpen: boolean;
  saveDialogOpen: boolean;
  orderDialogOpen: boolean;
  deleteConfirmationDialogOpen: boolean;
  showReportNameDialog: boolean;
  showDuplicateReportDialog: boolean;
}

export default class ReportActions extends React.Component<IProps, IState> {
  public state: IState = {
    // ui
    actionsMenuAnchorEl: undefined,
    newReportDialogOpen: false,
    loadDialogOpen: false,
    saveDialogOpen: false,
    orderDialogOpen: false,
    deleteConfirmationDialogOpen: false,
    showReportNameDialog: false,
    showDuplicateReportDialog: false,
    // data
    reportName: 'New report',
    userReports: [],
  };

  public handleActionsClick = (event: any) => {
    this.setState({actionsMenuAnchorEl: event.currentTarget || undefined});
  };

  public handleMenuClose = () => {
    this.setState({actionsMenuAnchorEl: undefined});
  };

  public handleNewReportClick = () => {
    this.setState({newReportDialogOpen: true});

    this.handleMenuClose();
  };

  public handleNewReportCancel = () => {
    this.setState({showReportNameDialog: false, showDuplicateReportDialog: false});
  };

  public handleNewReportOk = () => {
    const {onRewReportClick} = this.props;

    this.setState({newReportDialogOpen: false});

    if (onRewReportClick) {
      onRewReportClick();
    }
  };

  public handleLoadReportClick = async (api: ApiClient) => {
    const sortedUserReports = (await findUserReport(api, {where: {isDraft: 0}})).sort(
      (a, b) => (a.name > b.name ? 1 : -1)
    );

    this.setState({userReports: sortedUserReports, loadDialogOpen: true});

    this.handleMenuClose();
  };

  public handleLoadReportClose = () => {
    this.setState({loadDialogOpen: false});
  };

  public handleReportClick = (reportId: number) => {
    const {selectedReportId, onLoadReportClick} = this.props;

    if (reportId !== selectedReportId && onLoadReportClick) {
      onLoadReportClick(reportId);
    }

    this.setState({loadDialogOpen: false});
  };

  public handleRenameReportClick = () => {
    this.setState({showReportNameDialog: true, actionsMenuAnchorEl: undefined});
  };

  public handleDeleteReportClick = () => {
    this.setState({deleteConfirmationDialogOpen: true, actionsMenuAnchorEl: undefined});

    this.handleMenuClose();
  };

  public handleDuplicateReportClick = () => {
    this.setState({showDuplicateReportDialog: true, actionsMenuAnchorEl: undefined});
  };

  public handleDeleteCancel = () => {
    this.setState({deleteConfirmationDialogOpen: false});
  };

  public handleDeleteOk = () => {
    const {onDeleteReportClick} = this.props;

    if (onDeleteReportClick) {
      onDeleteReportClick();
    }

    this.setState({deleteConfirmationDialogOpen: false});
  };

  public handleOrderChartsClick = () => {
    this.setState({orderDialogOpen: true, actionsMenuAnchorEl: undefined});
  };

  public handleOrderChartsCancel = () => {
    this.setState({orderDialogOpen: false});
  };

  public handleOrderChartsSave = async (api: ApiClient, charts: IChart[]) => {
    const {onChartOrderUpdate, report, ga} = this.props;

    ga.trackEvent({
      category: 'New Reports',
      action: 'Page Settings',
      label: (report.isDefault ? 'Default page' : 'Custom page') + ' / Reorder reports',
    });

    await onChartOrderUpdate(charts);

    this.setState({orderDialogOpen: false});
  };

  public handlePdfExportClick = () => {
    const {report, ga} = this.props;

    ga.trackEvent({
      category: 'New Reports',
      action: 'Page Settings',
      label: (report.isDefault ? 'Default page' : 'Custom page') + ' / PDF Export',
    });

    window.print();
  };

  public render() {
    const {
      userReports,
      actionsMenuAnchorEl,
      orderDialogOpen,
      showReportNameDialog,
      showDuplicateReportDialog,
    } = this.state;
    const {
      report,
      charts,
      onReportNameUpdate,
      onDuplicateReport,
      onCreateChartClick,
      onCreateDefaultChartClick,
      ga,
    } = this.props;

    return (
      <Subscribe to={[ApiClient]}>
        {(api: ApiClient) => (
          <Styled>
            {({classes}) => (
              <I18n>
                {t => (
                  <React.Fragment>
                    <Grid item={true} className={`${classes.actionBlock} hideOnPrint`}>
                      {/* Report quick access buttons */}
                      <Grid container={true} justify={'flex-end'} alignItems={'center'} spacing={0}>
                        <AddChartBt
                          variant="contained"
                          onSave={onCreateChartClick}
                          isDefaultReport={report.isDefault}
                          onDefaultChartSave={onCreateDefaultChartClick}
                          ga={ga}
                        />

                        {report.isDefault ? (
                          <Grid item={true}>
                            <Button
                              className={classes.button}
                              size="medium"
                              variant="contained"
                              onClick={this.handlePdfExportClick}
                            >
                              <PrintIcon />
                              &nbsp;
                              {t('report.actions.export_pdf')}
                            </Button>
                          </Grid>
                        ) : null}

                        {/* Report Actions button */}
                        <Grid item={true}>
                          {
                            <Button
                              className={classes.button}
                              size="medium"
                              variant="contained"
                              aria-label="More"
                              aria-owns={open ? 'long-menu' : undefined}
                              aria-haspopup="true"
                              onClick={this.handleActionsClick}
                            >
                              <MoreVertIcon />
                            </Button>
                            // <IconButton
                            //   aria-label="More"
                            //   aria-owns={open ? 'long-menu' : undefined}
                            //   aria-haspopup="true"
                            //   onClick={this.handleActionsClick}
                            // >
                            //   <MoreVertIcon />
                            // </IconButton>
                          }

                          {/* Context Menu */}
                          <Menu
                            id="menu-report-actions"
                            anchorEl={actionsMenuAnchorEl}
                            getContentAnchorEl={undefined}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(actionsMenuAnchorEl)}
                            onClose={this.handleMenuClose}
                          >
                            {/* Rename Report page */}
                            {!report.isDefault && (
                              <MenuItem onClick={this.handleRenameReportClick}>
                                <ListItemText>{t('report.actions.rename_bt')}</ListItemText>
                              </MenuItem>
                            )}
                            {/* Reorder Reports */}
                            <MenuItem onClick={this.handleOrderChartsClick}>
                              <ListItemText>{t('report.actions.reorder_bt')}</ListItemText>
                            </MenuItem>
                            {/* Duplicate Report page */}
                            {!report.isDefault && (
                              <MenuItem onClick={this.handleDuplicateReportClick}>
                                <ListItemText>{t('report.actions.duplicate_bt')}</ListItemText>
                              </MenuItem>
                            )}
                            {/* Delete Report */}
                            {!report.isDefault && (
                              <MenuItem onClick={this.handleDeleteReportClick}>
                                <ListItemText>{t('report.actions.delete_bt')}</ListItemText>
                              </MenuItem>
                            )}
                          </Menu>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* `list` doesn't update when toggling the open state directly on the component */}
                    {orderDialogOpen ? (
                      <OrderListDialog
                        onSave={c => this.handleOrderChartsSave(api, c)}
                        onCancel={this.handleOrderChartsCancel}
                        list={charts}
                      />
                    ) : null}
                    {/* Delete report confirm dialog */}
                    <Dialog
                      open={this.state.deleteConfirmationDialogOpen}
                      aria-labelledby="delete-report-confirmation-title"
                      aria-describedby="delete-report-confirmation-description"
                    >
                      {/* Dialog title */}
                      <DialogTitle id="delete-report-confirmation-title">
                        {t('report.actions.delete_confirm_title')}
                      </DialogTitle>
                      {/* Dialog content */}
                      <DialogContent>
                        <DialogContentText id="delete-report-confirmation-description">
                          {t('report.actions.delete_confirm_text')}
                        </DialogContentText>
                      </DialogContent>
                      {/* Dialog actions*/}
                      <DialogActions>
                        <Button onClick={this.handleDeleteCancel} color="primary">
                          {t('report.actions.cancel_bt')}
                        </Button>
                        <Button onClick={this.handleDeleteOk} color="primary">
                          {t('report.actions.ok_bt')}
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* Rename report dialog */}
                    {(showReportNameDialog || showDuplicateReportDialog) && (
                      <ReportNameDialog
                        showDialog={showReportNameDialog || showDuplicateReportDialog}
                        onSubmit={
                          showReportNameDialog
                            ? onReportNameUpdate
                            : (name: string) => onDuplicateReport(report.id, name)
                        }
                        onNewReportClose={this.handleNewReportCancel}
                        initialValue={report.name}
                        title={t(
                          `report.actions.${
                            showReportNameDialog
                              ? 'update_confirm_title'
                              : 'duplicate_confirm_title'
                          }`
                        )}
                      />
                    )}
                  </React.Fragment>
                )}
              </I18n>
            )}
          </Styled>
        )}
      </Subscribe>
    );
  }
}
