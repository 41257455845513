import ApiClient from './ApiClient';
import {omit} from 'lodash';
import {JobState, ChartStatus} from '../components/Chart/Chart';
import {IOrderBy, ISelect, Table, TimeFrame} from '../components/ChartDialog/ChartDialog';
import {getClientTimeZone} from '../lib/dateUtils';
import {CancelToken} from 'axios';

export {ApiClient};

export async function userMe(api: ApiClient) {
  const response = await api.get<{
    id: number;
    username: string;
    email: string;
    userGroup: any;
    language: any;
    emailConfirmed: 0 | 1;
    disabledAt: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  }>('/user/me');

  return response.data;
}

export type resourcesType =
  | 'machine'
  | 'location'
  | 'errorIdentity'
  | 'currency'
  | 'machinegroup'
  | 'locationgroup'
  | 'manufacturer'
  | 'machinemodel'
  | 'product'
  | 'machinetype'
  | 'debtor';

export type ChartView = 'table' | 'bars' | 'pie' | 'lines';
export const chartViews: ChartView[] = ['table', 'bars', 'pie', 'lines'];
export const singleTimeFrameChartType = ['lines', 'pie'];
export type DefaultChartType =
  | 'revenue_full'
  | 'revenue_grouped'
  | 'payment_types'
  | 'offline_machines'
  | 'machine_cleaning'
  | 'machine_alarms'
  | 'machine_issues';
export type ChartType = DefaultChartType | 'custom';
export interface IReportCriteria {
  where?: Array<{
    column: string;
    operator: string;
    value: string | number | number[];
    joinType?: 'left' | 'right';
  }>;
  timeFrames?: TimeFrame[];
}
export interface IChartCriteria extends IReportCriteria {
  from: Table;
  where: Array<{
    column: string;
    operator: string;
    value: string | number | number[];
    joinType?: 'left' | 'right';
  }>;
  select: ISelect[];
  timeFrames: TimeFrame[];
  orderBy: IOrderBy[];
}

export interface IReportData {
  id: number;
  name: string;
  isDraft: boolean;
  isDefault: boolean;
  criteria?: IReportCriteria;
}

export interface IChartData {
  name: string;
  type: ChartType;
  view: ChartView;
  criteria: IChartCriteria;
  position: number;
}

export interface IChart extends IChartData {
  id: number;
  jobStartedAt?: Date;
  jobFinishedAt?: Date;
  jobState?: JobState;
  status?: ChartStatus;
  results?: any[];
  statusDetails?: string;
}

export interface IWidgetData {
  name: string;
  widgetType: number;
  chart: number | null;
}

export interface IChartTemplate {
  id: number;
  name: string;
  type: ChartType;
  view: ChartView;
  criteria: IChartCriteria;
}

interface IDebtor {
  externalId: number;
  name: string;
  description: number;
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface IMachine {
  hostname: string;
  location: number;
  debtor: number;
  notes: string;
  machineModel: number;
  machineType: number;
  manufacturer: number;
  lastSoldId: number;
  lastRevenuetransaction: number;
  lastLog: number;
  tag: string;
  virtualPrices: boolean;
  active: boolean;
  currency: number;
  logSynced: boolean;
  revenuetransactionSynced: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
  networkStatus: string;
  lastMessageAt: string;
}

interface IProduct {
  id: number;
  plu: number;
  name: string;
  reportName: string;
  machine: number;
  price: number;
  barcode: string;
  type: 'base' | 'flavour' | 'extra';
  createdAt: string;
  updatedAt: string;
}

interface ILocation {
  name: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  activeShift: number;
  remote: number;
  latitude: string;
  longitude: string;
  placeId: string;
  timeZone: string;
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface IErrorIdentity {
  error: string;
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface ICurrency {
  code: string;
  rate: string; // float
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface IMachineGroup {
  name: string;
  userGroup: number;
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface ILocationGroup {
  name: string;
  userGroup: number;
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface IManufacturer {
  name: string;
  userGroup: number;
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface IMachineModel {
  name: string;
  userGroup: number;
  manufacturer: number;
  machineType: number;
  errorProfile: number;
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface IMachineType {
  name: number;
  image: number;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export async function updateChart(
  api: ApiClient,
  chartId: number,
  criteria: IChart | {name: string}
): Promise<{id: number}> {
  await api.put(`/chart/${chartId}`, criteria);

  return {id: chartId};
}

export async function refreshChart(
  api: ApiClient,
  chartId: number,
  forceReload?: boolean
): Promise<{id: number}> {
  const value = forceReload ? {status: 'inProgress', forceReload} : {status: 'inProgress'};

  await api.put(`/chart/${chartId}`, value);

  return {id: chartId};
}

export async function createChart(
  api: ApiClient,
  report: number,
  criteria: IChartData
): Promise<{id: number}> {
  const response = await api.post<{
    id: number;
  }>(`/userreport/${report}/chart`, criteria);

  return response.data;
}

export async function createDefaultChart(
  api: ApiClient,
  report: number,
  data: {type: ChartType; name: string; position: number}
): Promise<{id: number}> {
  const response = await api.post<{
    id: number;
  }>(`/userreport/${report}/defaultchart`, data);

  return response.data;
}

export function getAutoCompleteOptions(
  api: ApiClient,
  params: {
    resource: resourcesType;
    property: string;
    search: string | number;
  }
): Promise<any[]> {
  const {resource, property, search} = params;
  const criteria = {where: {[property]: {contains: search}}};

  switch (resource) {
    case 'machine':
      return getMachines(api, criteria);
    case 'location':
      return getLocations(api, criteria);
    case 'errorIdentity':
      return getErrorIdentities(api, criteria);
    case 'currency':
      return getCurrencies(api, criteria);
    case 'machinegroup':
      return getMachineGroups(api, criteria);
    case 'locationgroup':
      return getLocationGroups(api, criteria);
    case 'manufacturer':
      return getManufacturers(api, criteria);
    case 'machinemodel':
      return getMachineModels(api, criteria);
    case 'product':
      return getProducts(api, criteria);
    case 'machinetype':
      return getMachineTypes(api, criteria);
    case 'debtor':
      return getDebtors(api, criteria);
    default:
      throw new Error(`unknown resource '${resource}' given`);
  }
}

export function getItemsByResource(
  api: ApiClient,
  params: {
    resource: string;
    criteria?: object;
    property?: string;
    search?: string | number;
  }
): Promise<any[]> {
  const {resource, property, search} = params;
  const criteria =
    params.criteria || (property && search ? {where: {[property]: {contains: search}}} : {});

  switch (resource) {
    case 'machine':
      return getMachines(api, criteria);
    case 'location':
      return getLocations(api, criteria);
    case 'machinegroup':
      return getMachineGroups(api, criteria);
    case 'locationgroup':
      return getLocationGroups(api, criteria);
    default:
      throw new Error(`unknown resource '${resource}' given`);
  }
}

export async function getItemById(
  api: ApiClient,
  values: {resource: string; id: number}
): Promise<{id: number}> {
  const {resource, id} = values;

  switch (resource) {
    case 'machine':
      return getMachineById(api, id);
    case 'location':
      return getLocationById(api, id);
    case 'errorIdentity':
      return getErrorIdentityById(api, id);
    case 'currency':
      return getCurrencyById(api, id);
    case 'machinegroup':
      return getMachineGroupById(api, id);
    case 'locationgroup':
      return getLocationGroupById(api, id);
    case 'manufacturer':
      return getManufacturerById(api, id);
    case 'machinemodel':
      return getMachineModelById(api, id);
    case 'machinetype':
      return getMachineTypeById(api, id);
    case 'debtor':
      return getDebtorById(api, id);
    default:
      throw new Error(`unknown resource '${resource}' given`);
  }
}

export async function getMachineById(api: ApiClient, id: number) {
  const response = await api.get<IMachine>(`/machine/${id}`);

  return response.data;
}

export async function getMachines(api: ApiClient, criteria: {}) {
  const response = await api.get<IMachine[]>('/machine', {params: criteria});

  return response.data;
}

export async function getLocationById(api: ApiClient, id: number) {
  const response = await api.get<ILocation>(`/location/${id}`);

  return response.data;
}

export async function getLocations(api: ApiClient, criteria: {}) {
  const response = await api.get<ILocation[]>('/location', {params: criteria});

  return response.data;
}

export async function getErrorIdentityById(api: ApiClient, id: number) {
  const response = await api.get<IErrorIdentity>(`/erroridentity/${id}`);

  return response.data;
}

export async function getErrorIdentities(api: ApiClient, criteria: {}) {
  const response = await api.get<IErrorIdentity[]>('/erroridentity', {params: criteria});

  return response.data;
}

export async function getDebtorById(api: ApiClient, id: number) {
  const response = await api.get<IDebtor>(`/debtor/${id}`);

  return response.data;
}

export async function getDebtors(api: ApiClient, criteria: {}) {
  const response = await api.get<IDebtor[]>('/debtor', {params: criteria});

  return response.data;
}

export async function getCurrencyById(api: ApiClient, id: number) {
  const response = await api.get<ICurrency>(`/currency/${id}`);

  return response.data;
}

export async function getCurrencies(api: ApiClient, criteria: {}) {
  const response = await api.get<ICurrency[]>('/currency', {params: criteria});

  return response.data;
}

export async function getManufacturerById(api: ApiClient, id: number) {
  const response = await api.get<IManufacturer>(`/manufacturer/${id}`);

  return response.data;
}

export async function getManufacturers(api: ApiClient, criteria: {}) {
  const response = await api.get<IManufacturer[]>('/manufacturer', {params: criteria});

  return response.data;
}

export async function getMachineGroupById(api: ApiClient, id: number) {
  const response = await api.get<IMachineGroup>(`/machinegroup/${id}`);

  return response.data;
}

export async function getMachineGroups(api: ApiClient, criteria: {}) {
  const response = await api.get<IMachineGroup[]>('/machinegroup', {params: criteria});

  return response.data;
}

export async function getLocationGroupById(api: ApiClient, id: number) {
  const response = await api.get<ILocationGroup>(`/locationgroup/${id}`);

  return response.data;
}

export async function getLocationGroups(api: ApiClient, criteria: {}) {
  const response = await api.get<ILocationGroup[]>('/locationgroup', {params: criteria});

  return response.data;
}

export async function getMachineModelById(api: ApiClient, id: number) {
  const response = await api.get<IMachineModel>(`/machinemodel/${id}`);

  return response.data;
}

export async function getMachineModels(api: ApiClient, criteria: {}) {
  const response = await api.get<IMachineModel[]>('/machinemodel', {params: criteria});

  return response.data;
}

export async function getMachineTypeById(api: ApiClient, id: number) {
  const response = await api.get<IMachineType>(`/machinetype/${id}`);

  return response.data;
}

export async function getMachineTypes(api: ApiClient, criteria: {}) {
  const response = await api.get<IMachineType[]>('/machinetype', {params: criteria});

  return response.data;
}

export async function getProducts(api: ApiClient, criteria: {}) {
  const response = await api.get<IProduct[]>('/product', {params: criteria});

  return response.data;
}

export async function createUserReport(
  api: ApiClient,
  values: {
    name: string;
    isDraft?: boolean;
  }
): Promise<{id: number}> {
  const response = await api.post<{
    id: number;
  }>('/userreport', values);

  return response.data;
}

export async function duplicateUserReport(
  api: ApiClient,
  values: {
    name: string;
  },
  params: {
    report: number;
  }
): Promise<{id: number}> {
  const response = await api.post<{
    id: number;
  }>('/userreport/duplicate', values, {params});

  return response.data;
}

export async function removeReportById(api: ApiClient, id: number) {
  const response = await api.delete(`/userreport/${id}`);

  return response.data;
}

export async function findUserReport(
  api: ApiClient,
  values: {
    where?: {};
  },
  options: {
    cancelToken?: CancelToken;
  } = {cancelToken: undefined}
): Promise<IReportData[]> {
  const {cancelToken} = options;
  const response = await api.get<IReportData[]>('/userreport', {params: values.where, cancelToken});

  return response.data;
}

export async function findUserReportById(
  api: ApiClient,
  id: number,
  options: {
    cancelToken?: CancelToken;
  } = {cancelToken: undefined}
): Promise<IReportData> {
  const {cancelToken} = options;
  const response = await api.get<IReportData>(`/userreport/${id}`, {cancelToken});

  return response.data;
}

export async function findDefaultUserReport(api: ApiClient): Promise<IReportData> {
  const response = await api.get<IReportData[]>(`/userreport`, {params: {isDefault: 1}});

  if (!response.data.length) {
    throw new Error('Default user report does not exist');
  }

  return response.data[0];
}

export async function findChartsByReportId(
  api: ApiClient,
  id: number,
  options: {
    cancelToken?: CancelToken;
  } = {cancelToken: undefined}
): Promise<IChart[]> {
  const {cancelToken} = options;
  const response = await api.get<IChart[]>(`/userreport/${id}/chart`, {cancelToken});

  // Add timezone to timeFrame.tz if not provided (backwards-compatibility)
  response.data.forEach(chart => {
    chart.criteria.timeFrames.forEach((timeFrame, i) => {
      if (
        chart.criteria.timeFrames[i] &&
        chart.criteria.timeFrames[i].type === 'trailing' &&
        !chart.criteria.timeFrames[i].tz
      ) {
        chart.criteria.timeFrames[i].tz = getClientTimeZone();
      }
    });
  });

  return response.data;
}

export async function updateUserReport(
  api: ApiClient,
  values: {id: number; name?: string; isDraft?: boolean; criteria?: IReportCriteria}
) {
  const response = await api.put<{
    id: number;
    name: string;
    isDraft: boolean;
  }>(`/userreport/${values.id}`, omit(values, ['id']));

  return response.data;
}

export async function removeChartById(api: ApiClient, id: number) {
  const response = await api.delete(`/chart/${id}`);

  return response.data;
}

export async function findChartById(
  api: ApiClient,
  id: number,
  options: {
    cancelToken?: CancelToken;
  } = {cancelToken: undefined}
): Promise<IChart> {
  const {cancelToken} = options;
  const response = await api.get<IChart>(`/chart/${id}`, {cancelToken});

  return response.data;
}

export async function getChartTemplate(api: ApiClient): Promise<IChartTemplate[]> {
  const response = await api.get<IChartTemplate[]>(`/charttemplate`);

  return response.data;
}

export async function findChartTemplateById(api: ApiClient, id: number): Promise<IChartTemplate> {
  const response = await api.get<IChartTemplate>(`/charttemplate/${id}`);

  return response.data;
}

export async function getRawTransactions(api: ApiClient, queryString: object) {
  const response = await api.get<any>('/report-export/transactions', {params: queryString});

  return response.data;
}

export async function createWidget(api: ApiClient, widgetData: IWidgetData): Promise<{id: number}> {
  const dashboardResponse = await api.get<
    Array<{
      id: number;
    }>
  >(`/dashboard`);

  if (!dashboardResponse.data.length) {
    throw new Error(`User does not have any dashboard`);
  }

  const dashboardId = dashboardResponse.data[0].id;
  const response = await api.post<{
    id: number;
  }>(`/dashboard/${dashboardId}/widget`, widgetData);

  return response.data;
}
