import {
  format,
  readableDateMonthRevFormat,
  readableDateRevFormat,
  timeInMinuteFormat,
  DateFormat,
} from '../lib/dateUtils';
import moment from 'moment';

const formatMapping: {date_day: DateFormat; date_hour: DateFormat} = {
  date_day: readableDateMonthRevFormat,
  date_hour: timeInMinuteFormat,
};

type duration = 'date_day' | 'date_hour';

export default function stringToDate(
  value: string,
  opts: {type?: duration; withYear?: boolean} = {type: 'date_day', withYear: false}
): string {
  const {withYear, type} = opts;
  const dateFormat = withYear ? readableDateRevFormat : formatMapping[type || 'date_day'];

  return format(moment(value).toDate(), dateFormat);
}
