import * as React from 'react';
import deepEqual from 'fast-deep-equal';
import memoize from 'memoize-one';
import {Subscribe} from 'unstated';

import {ApiClient} from '../api';

/**
 * The Request component allows you to talk to the API. This is a declarative
 * abstraction; you describe the request using props, and render the loading
 * state and/or eventual result via a render prop.
 *
 * The `for` prop is function that takes a Client and a `props` argument. The
 * rest of the props are inferred from function's `props` argument's type.
 *
 * The `children` render prop is called with a single argument, a **result
 * object** that represents the current state of your request. The result
 * object has the following properties:
 *
 * Prop      | Type         | Description
 * ----------|--------------|------------
 * `result.state`   | `"loading"`, `"success"` or `"failure"`  |
 * `result.loading` | boolean      | Convenient shortcut for `state === "loading"`
 * `result.data`    | inferred     | Only available if the request finished and was successful
 * `result.error`   | inferred     | Only available if the request finished and was **not** successful
 * `result.refetch` | `() => void` | Performs the request again with the same props (only available if the request finished, regardless of outcome)
 */
export default function Request<F>(props: ComponentProps<F>) {
  return (
    <Subscribe to={[ApiClient]}>
      {(api: ApiClient) => <ApiRequest {...props} api={api} />}
    </Subscribe>
  );
}

/* Before we get to the definition of ApiRequest, a disclaimer:
 *
 * This component's clean interface comes at the cost of some some TypeScript
 * magic. Get ready to be horrified and/or amused. :)
 *
 * First, let's define the possible states for this component.
 */
type ComponentState<F> = ILoading<F> | ISuccess<F> | IFailure;

/* We'll re-use these states as a basis for the RenderPropArgs type. The
 * generic <Result> type will be inferred in the ComponentProps type. The
 * `state` property is a string literal to make ComponentState a discriminated
 * union.
 */
interface ILoading<F> {
  state: 'loading';
  request: Promise<ResultType<F>> | undefined;
  data: undefined;
  error: undefined;
}
interface ISuccess<F> {
  state: 'success';
  request: undefined;
  data: ResultType<F>; // <-- generic <Result> which we'll infer from props in a moment
  error: undefined;
}
interface IFailure {
  state: 'failure';
  request: undefined;
  data: undefined;
  error: Error;
}

/* Next up: props. This component takes a `for` API function and a render prop
 * as `children`. Any other props which will be passed on to the API request.
 *
 * This type has two generic parts:
 * - `RequestProps` is the subset of props that will be used for the API request
 * - `Result` is the type of the eventual response to the API request
 *
 * The types of both generic parts are inferred from the signature of `for`.
 */
type ComponentProps<F> = RequestPropsType<F> & {
  /** A function that takes an ApiClient and a props object */
  for: F;
  /** The render prop */
  children: ((props: RenderPropArgs<F>) => JSX.Element);
};

/* This is the magic that allows us to infer these types. If you want to know
 * more about how this works, look up "conditional types". This feature was
 * introduced in TypeScript 2.8.
 */
type RequestPropsType<F> = F extends (api: ApiClient, props: infer RequestProps) => any
  ? RequestProps
  : never;

type ResultType<F> = F extends (...props: any[]) => Promise<infer Result> ? Result : never;

/* Finally, the render prop's arguments. These will be passed to the render
 * prop callback.
 */
type RenderPropArgs<F> = ILoadingArgs | ISuccessArgs<F> | IFailureArgs;

/* These reflect the ComponentState and, like before, the generic <Result> type
 * is the one we inferred in ComponentProps.
 */
interface ILoadingArgs {
  state: 'loading';
  loading: true;
  data?: void;
  error?: void;
}
interface ISuccessArgs<F> {
  state: 'success';
  loading: false;
  data: ResultType<F>;
  error?: void;
  refetch: () => void;
}
interface IFailureArgs {
  state: 'failure';
  loading: false;
  data?: void;
  error: Error;
  refetch: () => void;
}

export class ApiRequest<F> extends React.Component<
  ComponentProps<F> & {api: ApiClient},
  ComponentState<F>
> {
  public state = {
    state: 'loading' as 'loading',
    request: undefined,
    data: undefined,
    error: undefined,
  };

  public getRenderPropArgs = memoize(
    (state: ComponentState<F>): RenderPropArgs<F> => {
      switch (state.state) {
        case 'loading':
          return {state: state.state, loading: true} as ILoadingArgs;
        case 'success':
          return {
            state: state.state,
            loading: false,
            data: state.data,
            refetch: this.performRequest,
          } as ISuccessArgs<F>;
        case 'failure':
          return {
            state: state.state,
            loading: false,
            error: state.error,
            refetch: this.performRequest,
          } as IFailureArgs;
        default:
          return {} as never;
      }
    }
  );

  public performRequest = () => {
    // For some reason, TypeScript can't reconcile the inferred props with the
    // arguments in the API function's type signature. But we inferred the
    // types of the arguments (and the result) from the API function, and we've
    // checked them via the component's interface. We know they're correct, so
    // we can safely cast to `any` and move on with our lives. :)
    const api = this.props.api;
    const func = (this.props.for as any) as (api: ApiClient, args: any) => Promise<ResultType<F>>;
    const props = getRequestProps(this.props) as any;

    // This is what all the fuss is about? The actual request.
    const request = func(api, props);
    this.setState({
      state: 'loading' as 'loading',
      request,
      data: undefined,
      error: undefined,
    });

    request.then(
      data => {
        // only use the result if this request is still the current request
        if (request === this.state.request) {
          this.setState({
            state: 'success' as 'success',
            request: undefined,
            data,
            error: undefined,
          });
        }
      },
      error => {
        // only use the error if this request is still the current request
        if (request === this.state.request) {
          this.setState({
            state: 'failure' as 'failure',
            request: undefined,
            data: undefined,
            error,
          });
        }
      }
    );
  };

  public componentDidMount() {
    this.performRequest();
  }

  public componentDidUpdate(prevProps: ComponentProps<F> & {api: ApiClient}) {
    if (!isPropsEqual(this.props, prevProps)) {
      if (this.state.state !== 'loading') {
        this.performRequest();
      }
    }
  }

  public render() {
    return this.props.children(this.getRenderPropArgs(this.state));
  }
}

/**
 * Tests whether the given sets of props are sufficiently equal to represent
 * the same API request.
 */
function isPropsEqual<F>(
  a: Readonly<ComponentProps<F> & {api: ApiClient}>,
  b: Readonly<ComponentProps<F> & {api: ApiClient}>
) {
  return a.api === b.api && a.for === b.for && deepEqual(getRequestProps(a), getRequestProps(b));
}

/**
 * Extract the dynamic `RequestProps` part from a readonly set of full props,
 * like `this.props`.
 */
function getRequestProps<F>(props: Readonly<ComponentProps<F>>): RequestPropsType<F> {
  // This works, but is a bit messy. It can be cleaned up once this is fixed: https://github.com/Microsoft/TypeScript/issues/10727
  const {client: a, children: b, for: c, ...rest} = props as any;
  return rest as RequestPropsType<F>;
}
