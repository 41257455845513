import * as React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipFormatter,
} from 'recharts';

import {reportColors as COLORS} from '../../../data/colors';

interface IProps {
  data: any[];
  xProperty: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  bars: Array<{dataKey: string; color?: string; label: string}>;
  xAxisLabelFormat?: (value: string | number) => string;
  yAxisLabelFormat?: (value: string | number) => string;
  tooltipValueFormat?: TooltipFormatter;
}

export default class BarChartCmp extends React.Component<IProps, {}> {
  public render() {
    const {
      data,
      xProperty,
      bars,
      xAxisLabelFormat,
      yAxisLabelFormat,
      tooltipValueFormat,
    } = this.props;

    return (
      <div style={{width: '100%', height: '100%'}}>
        <ResponsiveContainer>
          <BarChart
            style={{overflow: 'hidden', fontSize: '11px'}}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke={'#E0E0E0'} strokeDasharray="0 0" vertical={false} />

            {/* X Axis definition */}
            <XAxis
              dataKey={xProperty}
              tickFormatter={xAxisLabelFormat ? xAxisLabelFormat : undefined}
            />

            {/* Y Axis definition */}
            <YAxis tickFormatter={yAxisLabelFormat ? yAxisLabelFormat : undefined} />

            {/* Show tooltip on hover */}
            <Tooltip
              labelFormatter={xAxisLabelFormat ? xAxisLabelFormat : undefined}
              formatter={tooltipValueFormat ? tooltipValueFormat : undefined}
            />

            {/* Show legends */}
            <Legend />

            {/* Drawing bars */}
            {bars.map((l, i) => (
              <Bar
                key={i}
                dataKey={l.dataKey}
                name={l.label}
                fill={l.color || COLORS[i] ? COLORS[i] : COLORS[0]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
