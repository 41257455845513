import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography/Typography';
import {I18n} from 'react-i18next';
import {differenceInSeconds} from 'date-fns';

import {createStyled} from '../../style/';

import {
  ApiClient,
  IChart,
  IChartData,
  findChartById,
  updateChart,
  refreshChart,
  ChartType,
  createWidget,
} from '../../api';

import TableChart from './resultRenderers/TableChart';
import ChartFooter from './ChartFooter';
import ChartActions from './ChartActions';
import ProgressOverlay, {OverlayStatus} from '../ProgressOverlay';
import RevenueFull, {RevenueFullResult} from './defaultCharts/RevenueFull';
import RevenueGrouped, {RevenueGroupedResult} from './defaultCharts/RevenueGrouped';
import PaymentTypes, {PaymentTypesResult} from './defaultCharts/PaymentTypes';
import {isTableViewChart} from '../../lib/chartUtils';
import ChartViewRenderer from './ChartViewRenderer';
import {InjectedCancelSourceProps} from '../hoc/WithCancelToken';
import GoogleAnalytics from '../../GoogleAnalytics';
import {trailingChartResultsTtl} from '../../config';

const Styled = createStyled(theme => {
  return {
    chartWrapper: {
      marginBottom: 4 * theme.spacing.unit,
      width: '100%',
      margin: 'auto',
      overflow: 'hidden',
    },
    chartWrapperFullscreen: {
      marginBottom: 0,
      width: '100%',
      margin: 'auto',
      overflow: 'hidden',
      'box-shadow': '0px 0px 0px 0px',
    },
    chartTitle: {
      padding: `${theme.spacing.unit * 2.5}px ${theme.spacing.unit * 3}px 0px ${theme.spacing.unit *
        3}px`,
      textAlign: 'left',
    },
    chartActions: {
      textAlign: 'right',
    },
    resultsWrapper: {
      width: '100%',
      minHeight: 10 * theme.spacing.unit,
      maxHeight: 70 * theme.spacing.unit,
      height: '100%',
      position: 'relative',
      overflow: 'auto',
    },
    resultsChartWrapper: {
      width: '100%',
      // We need a bigger min-height for the charts
      minHeight: 50 * theme.spacing.unit,
      maxHeight: 70 * theme.spacing.unit,
      height: '100%',
      position: 'relative',
      overflow: 'auto',
    },
    resultsContainer: {
      width: '100%',
      height: '100%',
    },
    chartFooter: {
      backgroundColor: '#f4f5f7',
      minHeight: 5 * theme.spacing.unit,
      overflow: 'hidden',
      padding: 0.4 * theme.spacing.unit,
    },
    emptyResults: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  };
});

export type JobState = 'idle' | 'queued' | 'processing';
export type ChartStatus = 'inProgress' | 'ready' | 'warning' | 'error' | undefined;
export type ViewMode = 'group' | 'flat' | 'plot2D';

interface IProps extends InjectedCancelSourceProps {
  chart: IChart;
  api: ApiClient;
  ga: GoogleAnalytics;
  isDefaultReport: boolean;
  onDelete: (chartId: number) => void;
  onReplaceChart?: (chartId: number, newChartData: IChart) => void;
  fullscreen?: boolean;
  chartOnly?: boolean;
}
interface IChartState {
  updatingResults: boolean;
  deletePhase: number;
  viewMode: ViewMode;
}

export default class Chart extends React.PureComponent<IProps, IChartState> {
  private intervalIds: NodeJS.Timer[] = [];

  constructor(props: IProps) {
    super(props);

    this.state = {
      updatingResults: false,
      deletePhase: 0,
      // On fullscreen mode, the option buttons on the chart are not available,
      // So it should use 'flat' view mode in that case
      viewMode: props.fullscreen ? 'flat' : 'group',
    };
  }

  public async componentDidMount() {
    const {chart, api} = this.props;
    const {updatingResults} = this.state;

    await api.loadPermissions();

    if (!chart.results) {
      this.fetchResult();

      return;
    }

    // if results are older than trailingChartResultsTtl, we consider them as "old/expired"
    const resultExpired = chart.jobFinishedAt
      ? differenceInSeconds(new Date(), chart.jobFinishedAt) > trailingChartResultsTtl
      : false;

    // Force a chart refresh if one of the chart's time frame type is different than fixed date
    // and the result is has not expired
    if (
      chart.criteria.timeFrames.some(tf => tf.type !== 'fixed') &&
      !updatingResults &&
      resultExpired
    ) {
      this.setState({updatingResults: true});

      return this.handleChartRefresh();
    }
  }

  public componentWillUnmount() {
    const {source} = this.props;

    if (this.intervalIds.length) {
      this.intervalIds.map(clearInterval);
    }

    source.cancel('Operation canceled.');
  }

  public async componentDidUpdate() {
    const {chart} = this.props;
    const {updatingResults} = this.state;

    if (chart.status === 'inProgress' && !updatingResults) {
      this.fetchResult();
    }
  }

  private getSupportedViewModes(): ViewMode[] {
    const supportedViewModes: ViewMode[] = ['group', 'flat'];

    /*if (chart.criteria.select.length === 2) {
      supportedViewModes.push('plot2D');
    }*/

    return supportedViewModes;
  }

  public isTempChart(chart: IChart): boolean {
    return chart.id <= 0;
  }

  public fetchResult() {
    const {api, chart, onReplaceChart, source} = this.props;

    let updatedChart;
    const fetchInterval = 3000;

    // if chart.id is <= 0, it is a temporary local chart. Used just as a place holder
    if (!this.isTempChart(chart)) {
      this.setState({updatingResults: true});
      let fetchCount = 0;

      const itvl = setInterval(async () => {
        try {
          updatedChart = await findChartById(api, chart.id, {cancelToken: source.token});
          fetchCount++;

          if (updatedChart.status !== 'inProgress') {
            if (this.intervalIds.length) {
              this.intervalIds.map(clearInterval);
            }

            if (onReplaceChart) {
              onReplaceChart(chart.id, {...chart, ...updatedChart});
            }

            this.setState({updatingResults: false});
          }
        } catch (e) {
          clearInterval(itvl);
        }
      }, fetchInterval);

      this.intervalIds.push(itvl);
    }
  }

  public handleTitleUpdate = async (label: string) => {
    const {api, chart, onReplaceChart} = this.props;

    // update the remote chart
    await updateChart(api, chart.id, {name: label});

    if (onReplaceChart) {
      onReplaceChart(chart.id, {...chart, name: label});
    }
  };

  public async handleChartUpdate(chartData: IChartData) {
    const {api, chart, onReplaceChart} = this.props;
    const oldResult = chart.results;

    // update the remote chart
    this.setState({updatingResults: true});
    try {
      await updateChart(api, chart.id, chartData);

      // update local chart
      const updatedChart = await findChartById(api, chart.id);

      if (onReplaceChart) {
        onReplaceChart(chart.id, Object.assign(chart, updatedChart, {result: oldResult}));
      }

      // wait for the result to be ready
      this.fetchResult();
    } catch (e) {
      Object.assign(chart, {status: 'error'});

      if (onReplaceChart) {
        onReplaceChart(chart.id, {...chart, status: 'error'});
      }
      this.setState({updatingResults: false});
    }
  }

  public async handleChartRefresh(forceReload: boolean = false) {
    const {api, chart} = this.props;

    // wait for the result to be ready
    this.fetchResult();

    // update the remote chart
    this.setState({updatingResults: true});
    await refreshChart(api, chart.id, forceReload);
  }

  public handleViewModeClick(viewMode: ViewMode) {
    this.setState({viewMode});
  }

  public handleChartDelete = () => {
    const {onDelete} = this.props;

    if (onDelete) {
      onDelete(this.props.chart.id);
    }
  };

  public handleDelete = () => {
    // trigger delete animation and finally delete the chart
    this.setState({deletePhase: 1});
  };

  public handleAddChartToDashboard = async () => {
    const {api, chart} = this.props;

    await createWidget(api, {
      name: chart.name,
      widgetType: 8, // "external" type
      chart: chart.id,
    });
  };

  private hasResults = (results: any[], chartType: ChartType): boolean => {
    if (
      chartType === 'custom' ||
      chartType === 'machine_alarms' ||
      chartType === 'offline_machines' ||
      chartType === 'machine_issues'
    ) {
      return results.some(result => result.length);
    }

    if (chartType === 'revenue_full') {
      return results.some(result => {
        return ['paymentTx', 'revenue', 'revenueTx'].some(
          property => Object.keys(result[property].currencies).length > 0
        );
      });
    }

    if (chartType === 'payment_types' || chartType === 'revenue_grouped') {
      return results.some(result => {
        return Object.keys(result.currencies).length > 0;
      });
    }

    return true;
  };

  public render() {
    const {updatingResults} = this.state;
    const {chart, isDefaultReport, chartOnly} = this.props;
    const handleChartUpdate = this.handleChartUpdate.bind(this);
    const handleChartDelete = this.handleChartDelete.bind(this);
    const handleChartRefresh = this.handleChartRefresh.bind(this);
    const handleViewModeClick = this.handleViewModeClick.bind(this);
    const handleAddChartToDashboard = this.handleAddChartToDashboard.bind(this);
    const supportedViewModes = this.getSupportedViewModes();
    let overlayStatus: OverlayStatus;

    if (updatingResults || chart.status === 'inProgress') {
      overlayStatus = 'inProgress';
    } else if (!updatingResults && (chart.status === 'warning' || chart.status === 'error')) {
      overlayStatus = chart.status;
    }

    const ChartPaper = () => (
      <Styled>
        {({classes}) => (
          <Paper
            className={`${
              chartOnly ? classes.chartWrapperFullscreen : classes.chartWrapper
            } pageBreakOnPrint`}
          >
            <Grid container={true}>
              {/* Omit chart Header if fullscreen */}
              {!chartOnly &&
                chart.type !== 'revenue_full' && (
                  <React.Fragment>
                    {/* Chart title */}
                    <Grid className={classes.chartTitle} item={true} xs={8}>
                      {<Typography variant="h6">{chart.name}</Typography>}
                      {/* <EditableLabel label={chart.name} onUpdate={this.handleTitleUpdate} /> */}
                    </Grid>

                    {/* Chart actions */}
                    <Grid item={true} className={classes.chartActions} xs={4}>
                      <ChartActions
                        chart={chart}
                        disabled={updatingResults}
                        onChartUpdate={handleChartUpdate}
                        isDefaultReport={isDefaultReport}
                        onChartDelete={() => {
                          // trigger delete animation and finally delete the chart
                          this.setState({deletePhase: 1});
                        }}
                        onAddChartToDashboard={handleAddChartToDashboard}
                      />
                    </Grid>
                  </React.Fragment>
                )}

              {/* Chart results container */}
              <Grid item={true} xs={12}>
                <Grid
                  className={`
                    fullHeightOnPrint
                    ${
                      chart.type === 'custom' &&
                      (chart.view === 'bars' || chart.view === 'lines' || chart.view === 'pie')
                        ? classes.resultsChartWrapper
                        : classes.resultsWrapper
                    }
                  `}
                  container={true}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  {/* Chart results */}
                  {chart.results &&
                    this.hasResults(chart.results, chart.type) && (
                      <Grid item={true} className={classes.resultsContainer}>
                        {isTableViewChart(chart.type, chart.view) && (
                          <TableChart
                            chart={chart}
                            viewMode={
                              chart.criteria.timeFrames.length === 1 ? this.state.viewMode : 'flat'
                            }
                          />
                        )}
                        {chart.type === 'custom' &&
                          (chart.view === 'bars' ||
                            chart.view === 'lines' ||
                            chart.view === 'pie') && <ChartViewRenderer chart={chart} />}

                        {chart.type === 'revenue_full' && (
                          <RevenueFull chart={chart} results={chart.results as RevenueFullResult} />
                        )}
                        {chart.type === 'revenue_grouped' && (
                          <RevenueGrouped
                            chart={chart}
                            results={chart.results as RevenueGroupedResult}
                          />
                        )}
                        {chart.type === 'payment_types' && (
                          <PaymentTypes
                            chart={chart}
                            results={chart.results as PaymentTypesResult}
                          />
                        )}
                      </Grid>
                    )}

                  {/* Chart overlay */}
                  <ProgressOverlay status={overlayStatus} />

                  {/* Empty results */}
                  {!updatingResults &&
                    chart.status !== 'inProgress' &&
                    chart.results &&
                    !this.hasResults(chart.results, chart.type) && (
                      <Grid
                        className={classes.emptyResults}
                        container={true}
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item={true}>
                          <I18n>{t => <Typography>{t('chart.no_results')}</Typography>}</I18n>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
              {/* Status */}
              {/* Omit chart Footer if fullscreen */}
              {!chartOnly && (
                <Grid item={true} xs={12} className={classes.chartFooter}>
                  <ChartFooter
                    chart={chart}
                    supportedViewModes={supportedViewModes}
                    viewMode={chart.criteria.timeFrames.length === 1 ? this.state.viewMode : 'flat'}
                    onRefreshChart={handleChartRefresh}
                    onViewModeClick={handleViewModeClick}
                  />
                </Grid>
              )}
            </Grid>

            {/* Blocking overlay for temporary charts */}
            {this.isTempChart(chart) && <ProgressOverlay status={'none'} />}
          </Paper>
        )}
      </Styled>
    );

    // We'll render the <Collapse> Element only during deletion. Otherwise we are adding
    // unnecessary elements to all the charts in the DOM
    if (this.state.deletePhase === 0) {
      return <ChartPaper />;
    }

    // We need to change the state here because transition elements have to be Rendered once in order to trigger
    if (this.state.deletePhase === 1) {
      setTimeout(() => {
        this.setState({deletePhase: 2});
      }, 0);
    }

    return (
      <Collapse in={this.state.deletePhase < 2} timeout={500} onExited={handleChartDelete}>
        <ChartPaper />
      </Collapse>
    );
  }
}
