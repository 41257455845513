import * as React from 'react';
import {CSVLink} from 'react-csv';
import {I18n} from 'react-i18next';
import {Subscribe} from 'unstated';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import DownloadIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';
import BarChartIcon from '@material-ui/icons/BarChart';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Grid from '@material-ui/core/Grid/Grid';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import ListItemText from '@material-ui/core/ListItemText';

import {createStyled} from '../../style/';
import {formatChartWhereCriteria} from '../../lib/chartUtils';
import {toFileName} from '../../lib/fileUtils';
import {ApiClient, IChart, IChartData} from '../../api';
import ChartDialog from '../ChartDialog';
import ConfirmDialog from '../ConfirmDialog';
import Notification from '../Notification';
import LoadingBar from '../LoadingBar';
import GoogleAnalytics from '../../GoogleAnalytics';

const PivottableDialog = React.lazy(() => import('../PivottableDialog'));

const Styled = createStyled(theme => {
  return {
    actionsContainer: {
      paddingTop: theme.spacing.unit,
      paddingRight: '5px',
    },
    actionIcon: {
      marginTop: 0.8 * theme.spacing.unit,
      marginBottom: 0.8 * theme.spacing.unit,
      marginLeft: 0.4 * theme.spacing.unit,
      marginRight: 0.4 * theme.spacing.unit,
      padding: 0.8 * theme.spacing.unit,
    },
    noDecoration: {
      textDecoration: 'none',
    },
  };
});

interface IState {
  configDialogOpen: boolean;
  deleteConfirmationDialogOpen: boolean;
  hasError: boolean;
  pivottableDialogOpen: boolean;
  actionsMenuAnchorEl?: HTMLElement;
  dashboardWidgetDialogOpen: boolean;
  cancelConfirmationDialogOpen: boolean;
}

interface IProps {
  chart: IChart;
  disabled: boolean;
  isDefaultReport: boolean;
  onChartUpdate: (chartData: IChartData) => void;
  onChartDelete: () => void;
  onAddChartToDashboard: () => void;
}

export default class ChartActions extends React.Component<IProps, IState> {
  // TODO: move to state or props!
  public where: Array<{
    column: string;
    operator: string;
    value?: string | {id: number} | Array<{id: number; name?: string}>;
  }> = [];

  constructor(props: IProps) {
    super(props);

    this.state = {
      configDialogOpen: false,
      deleteConfirmationDialogOpen: false,
      pivottableDialogOpen: false,
      hasError: false,
      dashboardWidgetDialogOpen: false,
      cancelConfirmationDialogOpen: false,
    };
  }

  public getExportHeaders(): string[] {
    const {chart} = this.props;

    if (chart.criteria.select.length) {
      return chart.criteria.select.map(column =>
        (column.alias ? column.alias : column.column).toLocaleLowerCase()
      );
    }

    return [];
  }

  public handeChartConfigClick = (ga: GoogleAnalytics) => {
    ga.trackEvent({
      category: 'New Reports',
      action: 'Chart Settings',
      label: 'Edit report',
    });

    this.setState({configDialogOpen: true, actionsMenuAnchorEl: undefined});
  };

  public handleAddToDashboardDialogClick = (ga: GoogleAnalytics) => {
    ga.trackEvent({
      category: 'New Reports',
      action: 'Chart Settings',
      label: 'Add to my Dashboard',
    });

    this.setState({dashboardWidgetDialogOpen: true, actionsMenuAnchorEl: undefined});
  };

  public handleAddToDashboardCancel = () => {
    this.setState({dashboardWidgetDialogOpen: false});
  };

  public handleDownloadCsv = (ga: GoogleAnalytics) => {
    ga.trackEvent({
      category: 'New Reports',
      action: 'Chart Settings',
      label: 'Download export CSV',
    });

    this.handleMenuClose();
  };
  public handleAddToDashboardOk = () => {
    const {onAddChartToDashboard} = this.props;

    this.setState({dashboardWidgetDialogOpen: false});
    onAddChartToDashboard();
  };

  public closeNotification = () => {
    this.setState({hasError: false});
  };

  public handleConfigSubmit = (ga: GoogleAnalytics, chartData: IChartData) => {
    ga.trackEvent({
      category: 'New Reports',
      action: 'Form Submit',
      label: 'Update Report',
    });

    try {
      this.setState({configDialogOpen: false});
      this.props.onChartUpdate(chartData);
    } catch (e) {
      this.setState({hasError: true});
    }
  };

  public handleConfigCancel = (): void => {
    this.setState({cancelConfirmationDialogOpen: true});
  };

  public handleCancelOk = (): void => {
    this.setState({configDialogOpen: false, cancelConfirmationDialogOpen: false});
  };

  public handleCancelConfirmation = (): void => {
    this.setState({cancelConfirmationDialogOpen: false});
  };

  public handleDeleteClick = (): void => {
    this.setState({deleteConfirmationDialogOpen: true, actionsMenuAnchorEl: undefined});
  };

  public handleDeleteCancel = () => {
    this.setState({deleteConfirmationDialogOpen: false});
  };

  public handleDeleteOk = () => {
    this.setState({deleteConfirmationDialogOpen: false});
    this.props.onChartDelete();
  };

  public handlePivottableDialogClick = (ga: GoogleAnalytics) => {
    ga.trackEvent({
      category: 'New Reports',
      action: 'Chart Settings',
      label: 'Open in pivottable',
    });

    this.setState({pivottableDialogOpen: true, actionsMenuAnchorEl: undefined});
  };

  public handlePivottableDialogClose = () => {
    this.setState({pivottableDialogOpen: false});
  };

  public handleActionsClick = (event: any) => {
    this.setState({actionsMenuAnchorEl: event.currentTarget || undefined});
  };

  public handleMenuClose = () => {
    this.setState({actionsMenuAnchorEl: undefined});
  };

  public render() {
    const {chart, disabled} = this.props;
    const {
      hasError,
      pivottableDialogOpen,
      configDialogOpen,
      deleteConfirmationDialogOpen,
      actionsMenuAnchorEl,
      dashboardWidgetDialogOpen,
      cancelConfirmationDialogOpen,
    } = this.state;
    // TODO: it should not be needed to convert the where criteria
    const chartWhere = formatChartWhereCriteria(chart.criteria.where);
    const fileNameToDownload = toFileName(chart.name, 'csv');

    return (
      <Subscribe to={[ApiClient, GoogleAnalytics]}>
        {(api: ApiClient, ga: GoogleAnalytics) => (
          <Styled>
            {({classes}) => (
              <I18n>
                {t => (
                  <Grid
                    container={true}
                    justify="flex-end"
                    spacing={0}
                    className={`${classes.actionsContainer} hideOnPrint`}
                  >
                    <IconButton
                      aria-label="More"
                      aria-owns={open ? 'long-menu' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleActionsClick}
                      disabled={disabled}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    {
                      <React.Fragment>
                        {/* Context Menu */}
                        <Menu
                          id="menu-report-actions"
                          anchorEl={actionsMenuAnchorEl}
                          getContentAnchorEl={undefined}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(actionsMenuAnchorEl)}
                          onClose={this.handleMenuClose}
                        >
                          {/* Pivot table */}
                          {chart.results &&
                            chart.results.length === 1 &&
                            chart.type === 'custom' && (
                              <MenuItem onClick={() => this.handlePivottableDialogClick(ga)}>
                                <ListItemText>{t('chart.actions.open_pivottable')}</ListItemText>
                              </MenuItem>
                            )}

                          {/* Download CSV */}
                          {chart.results &&
                            chart.results.length === 1 &&
                            chart.type === 'custom' && (
                              <CSVLink
                                data={chart.results[0]}
                                headers={this.getExportHeaders()}
                                filename={fileNameToDownload}
                                target="_blank"
                                className={classes.noDecoration}
                              >
                                <MenuItem onClick={() => this.handleDownloadCsv(ga)}>
                                  <ListItemText>{t('chart.actions.export')}</ListItemText>
                                </MenuItem>
                              </CSVLink>
                            )}

                          {/* Add to dashboard Button */}
                          {chart.type === 'custom' && (
                            <MenuItem onClick={() => this.handleAddToDashboardDialogClick(ga)}>
                              <ListItemText>{t('chart.actions.add_to_dashboard')}</ListItemText>
                            </MenuItem>
                          )}

                          {/* Edit chart */}
                          {chart.type === 'custom' && (
                            <MenuItem onClick={() => this.handeChartConfigClick(ga)}>
                              <ListItemText>{t('chart.actions.edit_bt')}</ListItemText>
                            </MenuItem>
                          )}

                          {/* Delete chart */}
                          <MenuItem onClick={this.handleDeleteClick}>
                            <ListItemText>{t('chart.actions.delete_bt')}</ListItemText>
                          </MenuItem>
                        </Menu>

                        {pivottableDialogOpen &&
                        chart.results &&
                        chart.results.length === 1 &&
                        chart.type === 'custom' ? (
                          <React.Suspense fallback={<LoadingBar />}>
                            <PivottableDialog
                              title={chart.name}
                              data={chart.results[0]}
                              onCloseClick={this.handlePivottableDialogClose}
                            />
                          </React.Suspense>
                        ) : null}

                        {configDialogOpen ? (
                          <ChartDialog
                            onSubmit={c => this.handleConfigSubmit(ga, c)}
                            onCancel={this.handleConfigCancel}
                            initialValue={{
                              ...chart.criteria,
                              type: chart.type,
                              view: chart.view,
                              name: chart.name,
                              where: chartWhere,
                            }}
                            isAdmin={api.isAdmin()}
                          />
                        ) : null}

                        <ConfirmDialog
                          title={t('chart.actions.config_cancel_confirm_title')}
                          text={t('chart.actions.config_cancel_confirm_text')}
                          open={cancelConfirmationDialogOpen}
                          onOkClick={this.handleCancelOk}
                          onCancelClick={this.handleCancelConfirmation}
                        />

                        {/* Dialog add to dashboard confirmation*/}
                        <ConfirmDialog
                          title={t('chart.actions.add_to_dashboard_confirm_title')}
                          text={t('chart.actions.add_to_dashboard_confirm_text')}
                          open={dashboardWidgetDialogOpen}
                          onOkClick={this.handleAddToDashboardOk.bind(this, api)}
                          onCancelClick={this.handleAddToDashboardCancel}
                        />

                        <ConfirmDialog
                          title={t('chart.actions.delete_confirm_title')}
                          text={t('chart.actions.delete_confirm_text')}
                          open={deleteConfirmationDialogOpen}
                          onOkClick={this.handleDeleteOk}
                          onCancelClick={this.handleDeleteCancel}
                        />
                      </React.Fragment>
                    }

                    {hasError ? (
                      <Notification
                        message={t('chart.something_went_wrong')}
                        type="error"
                        onClose={this.closeNotification}
                      />
                    ) : null}
                  </Grid>
                )}
              </I18n>
            )}
          </Styled>
        )}
      </Subscribe>
    );
  }
}
