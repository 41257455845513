import * as React from 'react';
import classNames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Chip from '@material-ui/core/Chip';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

import {createStyled} from '../../../style/index';
import {
  properties,
  getSelectKey,
  isDimension,
  isMetric,
} from '../../../components/ChartDialog/properties';
import {formatter} from '../../../formatters';
import {IChart} from '../../../api';
import {ISelect} from '../../ChartDialog/ChartDialog';
import {
  orderColumns,
  createTreeList,
  flattenTreeList,
  getColumnName,
  isGroupColumn,
  IFlatTreeListItem,
} from '../../../lib/chartUtils';

import {shiftItemRight, shiftItemLeft} from '../../../lib/listUtils';
import {ViewMode} from '../Chart';

interface IProps {
  chart: IChart;
  viewMode: ViewMode;
}

interface IState {
  chartFinishedAt: Date | null;
  columns: ISelect[];
  treeList: Array<{}>;
  flatTreeList: IFlatTreeListItem[];
  groupColumnsMaxIndex: number;
  columnsByName: {[columnName: string]: ISelect};
  chartResults: any[];
}

const Styled = createStyled(theme => {
  return {
    chart: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      overflowY: 'auto',
    },
    headerCell: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff',
      paddingTop: theme.spacing.unit / 2,
      paddingRight: theme.spacing.unit * 3,
      paddingBottom: theme.spacing.unit / 2,
      paddingLeft: theme.spacing.unit * 0,
    },
    headerCellFirst: {
      paddingLeft: '24px',
    },
    colFuncionCont: {
      height: theme.spacing.unit * 2,
    },
    colFuncion: {
      marginLeft: theme.spacing.unit,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
    },
    arrowIcons: {
      padding: 0,
      opacity: 0.4,
      '&:hover': {
        opacity: 1,
      },
    },
    metricCell: {
      textAlign: 'right',
    },
  };
});
const StyledChip = withStyles({
  root: {
    height: 16,
    padding: '0 8px 0 8px',
  },
  label: {
    textTransform: 'capitalize',
    padding: '0',
    fontSize: 10,
  },
})(Chip);

export default class TableChart extends React.Component<IProps, IState> {
  public state = {
    columns: [],
    treeList: [],
    flatTreeList: [],
    groupColumnsMaxIndex: -1,
    columnsByName: {},
    chartFinishedAt: null,
    chartResults: this.props.chart.results || [],
  };

  public static getDerivedStateFromProps(props: IProps, currentState: IState) {
    const {chart} = props;

    if (currentState.chartFinishedAt === chart.jobFinishedAt) {
      return null;
    }

    if (!chart.results) {
      return;
    }

    const {results, newSelects} = mergeTimeFramesResults(
      chart.results || [],
      chart.criteria.select
    );
    // Recreate structures only when the chart results have been updated
    const columns = orderColumns(newSelects); // select/group_by columns first
    const columnNames: string[] = columns.map((column: ISelect) => getSelectKey(column));
    const tableProperties: Array<{column: string}> = properties[chart.criteria.from];
    const columnsByName = tableProperties.reduce((acc: {}, column) => {
      if (columnNames.indexOf(getSelectKey(column)) > -1) {
        acc[getSelectKey(column)] = column;
      }

      return acc;
    }, {});

    const groupViewData = generateGroupViewData(columns, results || []);

    return {
      columns,
      ...groupViewData,
      columnsByName,
      chartFinishedAt: chart.jobFinishedAt,
      chartResults: results,
    };
  }

  public handleExpandClick(row: IFlatTreeListItem) {
    if (row && row.refItem) {
      row.refItem.isCollapsed = !row.refItem.isCollapsed;
    }

    this.setState({flatTreeList: flattenTreeList(this.state.treeList)});
  }

  public handleOrderArrowClick(direction: 'left' | 'right', index: number) {
    const {chartResults} = this.state;

    if (!chartResults) {
      return;
    }

    const {columns} = this.state;
    const reorderedColumns =
      direction === 'left' ? shiftItemLeft(columns, index) : shiftItemRight(columns, index);

    const groupViewData = generateGroupViewData(reorderedColumns, chartResults || []);

    this.setState({
      columns: reorderedColumns,
      ...groupViewData,
    });
  }

  public render() {
    const {viewMode, chart} = this.props;
    const {columns, columnsByName, flatTreeList, chartResults} = this.state;
    const rows = viewMode === 'group' ? flatTreeList : chartResults;

    return (
      <Styled>
        {({classes}) => (
          <React.Fragment>
            <div className={classes.chart}>
              <Table>
                {/* Table rows */}
                <TableBody>
                  {rows &&
                    rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {columns.map((column, cellIndex) =>
                          TableChartBodyCell({
                            key: cellIndex,
                            row,
                            column,
                            columnsByName,
                            onExpandClick: r => this.handleExpandClick(r),
                          })
                        )}
                      </TableRow>
                    ))}
                </TableBody>
                {/* Table columns (after body because its column cells are sticky) */}
                <TableHead>
                  <TableRow>
                    {columns.map((col: ISelect, colIndex) => (
                      <TableChartHeaderCell
                        key={colIndex}
                        col={col}
                        isFirstCol={colIndex === 0}
                        isLastCol={colIndex === columns.length - 1}
                        isOrderable={Boolean(chart.results && chart.results.length === 1)}
                        onLeftArrowClick={this.handleOrderArrowClick.bind(this, 'left', colIndex)}
                        onRightArrowClick={this.handleOrderArrowClick.bind(this, 'right', colIndex)}
                      />
                    ))}
                  </TableRow>
                </TableHead>
              </Table>
            </div>
          </React.Fragment>
        )}
      </Styled>
    );
  }
}

function TableChartBodyCell(props: {
  key: number;
  row: IFlatTreeListItem;
  column: ISelect;
  columnsByName: {};
  onExpandClick: (row: IFlatTreeListItem) => void;
}) {
  const {key, row, column, columnsByName, onExpandClick} = props;
  const columnName = getSelectKey(column);
  const cellValue =
    columnsByName[columnName] && columnsByName[columnName].formatters
      ? formatter(
          columnsByName[columnName].formatters,
          row[column.alias ? column.alias.toLowerCase() : columnName],
          column
        )
      : row[column.alias ? column.alias.toLowerCase() : columnName];
  const isGrouppable =
    cellValue !== undefined && isGroupColumn(column) && row.isGroup && row.refItem;
  const showExpandIcon = isGrouppable && row.isCollapsed;
  const showCollapseIcon = isGrouppable && !row.isCollapsed;

  return (
    <Styled key={key}>
      {({classes}) => (
        <TableCell
          onClick={onExpandClick.bind(null, row)}
          className={isMetric(column) ? classes.metricCell : ''}
        >
          {/* Expand Icon */}
          {showExpandIcon && (
            <IconButton color="primary">
              <KeyboardArrowRightIcon />
            </IconButton>
          )}
          {/* Collapse Icon */}
          {showCollapseIcon && (
            <IconButton color="primary">
              <KeyboardArrowDownIcon />
            </IconButton>
          )}
          {cellValue}
        </TableCell>
      )}
    </Styled>
  );
}

function TableChartHeaderCell(props: {
  col: ISelect;
  isFirstCol: boolean;
  isLastCol: boolean;
  isOrderable: boolean;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}) {
  const {col, isFirstCol, isLastCol, isOrderable, onLeftArrowClick, onRightArrowClick} = props;

  return (
    <Styled>
      {({classes}) => (
        <TableCell
          className={classNames(classes.headerCell, isFirstCol ? classes.headerCellFirst : {})}
        >
          <Grid
            container={true}
            direction="row"
            alignItems="center"
            justify={isMetric(col) ? 'flex-end' : 'flex-start'}
          >
            {/* Group columns */}
            {isGroupColumn(col) && <Grid item={true}>{getColumnName(col)}</Grid>}
            {/* Function/aggregate columns */}
            {!isGroupColumn(col) && (
              <React.Fragment>
                <Grid item={true}>{getColumnName(col)}</Grid>
                <Grid item={true}>
                  <StyledChip className={classes.colFuncion} label={col.function} />
                </Grid>
              </React.Fragment>
            )}
            {/* Left arrow icon */}
            {!isFirstCol &&
              isOrderable && (
                <Grid item={true}>
                  <IconButton className={classes.arrowIcons} onClick={onLeftArrowClick}>
                    <ArrowLeftIcon />
                  </IconButton>
                </Grid>
              )}
            {/* Right arrow icon */}
            <Grid item={true}>
              {!isLastCol &&
                isOrderable && (
                  <IconButton className={classes.arrowIcons} onClick={onRightArrowClick}>
                    <ArrowRightIcon />
                  </IconButton>
                )}
            </Grid>
          </Grid>
        </TableCell>
      )}
    </Styled>
  );
}

function generateGroupViewData(columns: ISelect[], chartResult: Array<{}>) {
  const treeList = createTreeList(columns, chartResult);
  const flatTreeList = flattenTreeList(treeList);
  const groupColumnsMaxIndex = columns.findIndex(col => Boolean(col.function)) - 1;

  return {
    treeList,
    flatTreeList,
    groupColumnsMaxIndex,
  };
}

/**
 * Merge the results of multiple time frames into one single array
 * based on the selected dimension columns, just so the metrics of both time frames
 * will be shown next to each other along the dimenson columns
 */
function mergeTimeFramesResults(results: any[][], selects: ISelect[]) {
  if (results.length === 1) {
    return {results: results[0], newSelects: selects};
  }

  const newSelects: ISelect[] = [];
  const defaultValue: {dimensions: string[]; metrics: string[]} = {
    dimensions: [],
    metrics: [],
  };
  const {dimensions, metrics} = selects.reduce((reducedData, select) => {
    const value = (select.alias || select.column).toLowerCase();

    if (isDimension(select)) {
      reducedData.dimensions.push(value);
      newSelects.push({...select});
    }

    if (isMetric(select)) {
      reducedData.metrics.push(value);

      for (let index = 0; index < results.length; index++) {
        const newSelect = {
          alias: `${select.alias ? select.alias : select.column}${index}`,
          label: select.alias ? select.alias : select.column,
        };
        // As we want to show metrics from both time frames in a single row
        // We need to add extra column to `selects` (metrics from the second time frame)
        newSelects.push({...select, ...newSelect});
      }
    }

    return reducedData;
  }, defaultValue);

  const processedData = results.map((timeFrameResults, timeFrameIndex) => {
    return timeFrameResults.map(result => {
      const row: object = {...result};

      Object.keys(result).forEach(key => {
        if (metrics.indexOf(key) > -1) {
          row[`${key}${timeFrameIndex}`] = result[key];
        }
      });

      return row;
    });
  });

  let mergedResults: any[] = [];

  processedData.forEach((timeFrameData: any, i: number) => {
    if (i === 0) {
      mergedResults = timeFrameData.map((d: any) => d);

      return;
    }

    timeFrameData.map((d: any) => {
      const itemIndex = mergedResults.findIndex(md => {
        return dimensions.every(dimension => {
          return md[dimension] === d[dimension];
        });
      });

      if (itemIndex === -1) {
        mergedResults.push(d);
      } else {
        mergedResults[itemIndex] = Object.assign(mergedResults[itemIndex], d);
      }
    });
  });

  return {results: mergedResults, newSelects};
}
