import * as React from 'react';
import moment from 'moment-timezone';
import {I18n} from 'react-i18next';
import green from '@material-ui/core/colors/green';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {differenceInSeconds} from 'date-fns';

import {format} from '../../lib/dateUtils';
import {createStyled} from '../../style/';
import {IChart, ChartType} from '../../api';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const Styled = createStyled(themeStyle => {
  return {
    extra: {
      color: '#a3a6a8',
    },
  };
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: {
    useNextVariants: true,
  },
});

interface IChartTimeAgoProps {
  chart: IChart;
}

export default class ChartGenerationTime extends React.Component<IChartTimeAgoProps, {}> {
  private intervalId: number = -1;

  public componentDidMount() {
    // update every 60 seconds
    this.intervalId = window.setInterval(() => {
      this.forceUpdate();
    }, 60000);
  }

  public componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  public render() {
    const {chart} = this.props;
    const processingTime =
      chart.jobStartedAt &&
      chart.jobFinishedAt &&
      differenceInSeconds(chart.jobFinishedAt, chart.jobStartedAt);

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <MuiThemeProvider theme={theme}>
                {chart.criteria.timeFrames.some(timeFrame => timeFrame.type !== 'fixed') && (
                  <>
                    {chart.jobFinishedAt && (
                      <Tooltip
                        title={`${t('chart.footer.timestamp_text', {
                          date: format(chart.jobFinishedAt),
                        })} (${t('chart.footer.process_time', {
                          time: processingTime
                            ? t('chart.footer.process_time_seconds', {seconds: processingTime})
                            : t('chart.footer.process_time_second'),
                        })})`}
                      >
                        <span className={classes.extra}>
                          {t('chart.footer.process_time_ago', {
                            timeAgo: moment(chart.jobFinishedAt).fromNow(),
                          })}
                        </span>
                      </Tooltip>
                    )}
                  </>
                )}
              </MuiThemeProvider>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
