import * as React from 'react';
import {I18n} from 'react-i18next';

import {createStyled} from '../../../style/index';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {DefaultChartType} from '../../../api';
import {List, ListItem, ListItemText, Divider} from '@material-ui/core';

interface IProps {
  onChange: (value: DefaultChartType) => void;
  showAsList?: boolean;
  initialValue?: DefaultChartType;
}

const Styled = createStyled({
  form: {
    display: 'flex',
    marginBottom: '10px',
  },
  type: {
    flexBasis: '100%',
  },
});

const values: DefaultChartType[] = [
  'revenue_grouped',
  'payment_types',
  'machine_issues',
  'offline_machines',
  'machine_alarms',
];
export default class ChartTypeStep extends React.Component<IProps, {}> {
  public handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const {onChange} = this.props;

    onChange(event.target.value as DefaultChartType);
  };

  public handleListOnClick = (value: DefaultChartType): void => {
    const {onChange} = this.props;

    onChange(value);
  };

  public render() {
    const {initialValue, showAsList} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <>
                {showAsList ? (
                  <List>
                    {values.map(option => (
                      <React.Fragment key={option}>
                        <ListItem button={true} onClick={() => this.handleListOnClick(option)}>
                          <ListItemText
                            primary={t(`chart_dialog.steps.chart_type.types.${option}`)}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <form className={classes.form}>
                    <FormControl className={classes.type}>
                      <InputLabel htmlFor="charttype">
                        {t('chart_dialog.steps.chart_type.chart_type_label')}
                      </InputLabel>
                      <Select
                        id="charttype"
                        value={initialValue || ''}
                        onChange={this.handleOnChange}
                      >
                        {values.map(option => (
                          <MenuItem key={option} value={option}>
                            {t(`chart_dialog.steps.chart_type.types.${option}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </form>
                )}
              </>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
