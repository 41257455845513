import * as React from 'react';
import {I18n} from 'react-i18next';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import ChartTypeStep from './steps/ChartTypeStep';
import {DefaultChartType} from '../../api';

interface IProps {
  onSubmit: (type: DefaultChartType) => void;
  onCancel: () => void;
  showDialog: boolean;
}

export default class DefaultChartDialog extends React.Component<IProps, {}> {
  public handleCancel = async () => {
    const {onCancel} = this.props;

    onCancel();
  };

  public handleSubmit = (type: DefaultChartType) => {
    this.props.onSubmit(type);
  };

  public render() {
    const {showDialog} = this.props;

    return (
      <I18n>
        {t => (
          <Dialog
            open={showDialog}
            aria-labelledby="new-default-chart-confirmation-title"
            aria-describedby="new-default-chart-confirmation-description"
          >
            {/* Dialog title */}
            <DialogTitle id="new-default-chart-confirmation-title">
              {t('create_chart_dialog.title_default')}
            </DialogTitle>
            {/* Dialog content */}
            <DialogContent>
              <ChartTypeStep showAsList={true} onChange={this.handleSubmit} />
            </DialogContent>
            {/* Dialog actions */}
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                {t('report.actions.cancel_bt')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </I18n>
    );
  }
}
