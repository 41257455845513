import * as React from 'react';
import {I18n} from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {createStyled} from '../style';
import logoSrc from '../images/logo.svg';

const Styled = createStyled(theme => ({
  logo: {textAlign: 'center'},
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: theme.mixins.gutters({
    marginTop: theme.spacing.unit * 3,
  }),
}));

interface IProps {
  isLoading: boolean;
  onSubmit: (values: {username: string; password: string}) => void;
}

interface IState {
  username: string;
  password: string;
}

export default class LoginForm extends React.Component<IProps, IState> {
  public state = {username: '', password: ''};

  public handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({username: event.target.value});
  };

  public handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({password: event.target.value});
  };

  public handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.onSubmit(this.state);
  };

  public render() {
    const {isLoading} = this.props;
    const {username, password} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <React.Fragment>
                <div className={classes.logo}>
                  <img src={logoSrc} width={64} height={64} />
                </div>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <TextField
                    id="username-input"
                    label={t('login_form.username_label')}
                    margin="dense"
                    value={username}
                    onChange={this.handleUsernameChange}
                  />
                  <TextField
                    id="password-input"
                    label={t('login_form.password_label')}
                    type="password"
                    autoComplete="current-password"
                    margin="dense"
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isLoading}
                  >
                    {t('login_form.login_bt')}
                  </Button>
                </form>
              </React.Fragment>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
