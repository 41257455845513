import * as React from 'react';
import {I18n} from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import {createStyled} from '../../style';
import {getAutoCompleteOptions} from '../../api';
import Request from '../Request';
import Error from '../Error';

interface IProps<T> {
  itemProps: any;
  search: string;
  resource:
    | 'machine'
    | 'location'
    | 'errorIdentity'
    | 'currency'
    | 'machinegroup'
    | 'locationgroup'
    | 'manufacturer'
    | 'machinemodel'
    | 'product'
    | 'machinetype'
    | 'debtor';
  property: string;
  formatLabel: (value: T) => string;
}

const Styled = createStyled((theme: any) => ({
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  loading: {},
  noResults: {},
}));

export default class AutocompleteOptions<T> extends React.Component<IProps<T>, {}> {
  public render() {
    const {itemProps, search, formatLabel, resource, property} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <Request
                for={getAutoCompleteOptions}
                resource={resource}
                property={property}
                search={search}
              >
                {result => (
                  <Paper className={classes.paper} square={true}>
                    {result.data && !result.data.length ? (
                      <MenuItem disabled={true} className={classes.noResults} component="div">
                        {t('autocomplete.no_results')}
                      </MenuItem>
                    ) : result.data && result.data.length ? (
                      result.data.map((option, index) => (
                        <MenuItem {...itemProps({item: option})} key={index} component="div">
                          {formatLabel(option)}
                        </MenuItem>
                      ))
                    ) : result.loading ? (
                      <MenuItem disabled={true} className={classes.loading} component="div">
                        {t('autocomplete.loading')}
                      </MenuItem>
                    ) : (
                      <Error>${t('autocomplete.something_went_wrong')}</Error>
                    )}
                  </Paper>
                )}
              </Request>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
