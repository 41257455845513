import * as React from 'react';
import {Subscribe} from 'unstated';
import {Redirect, RouteComponentProps} from 'react-router';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import {ApiClient} from '../api';
import {createStyled} from '../style';
import LoginForm from '../components/LoginForm';

const Styled = createStyled(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
  }),
}));

export default function Login(props: RouteComponentProps<any>) {
  return (
    <Subscribe to={[ApiClient]}>
      {({isAuthenticated, isLoggingIn, login}: ApiClient) => {
        if (isAuthenticated()) {
          return <Redirect to={{pathname: '/'}} />;
        }

        return (
          <Styled>
            {({classes}) => (
              <div className={classes.root}>
                {isLoggingIn() && <LinearProgress className={classes.progress} />}
                <Paper className={classes.paper}>
                  <LoginForm isLoading={isLoggingIn()} onSubmit={login} />
                </Paper>
              </div>
            )}
          </Styled>
        );
      }}
    </Subscribe>
  );
}
