import * as React from 'react';
import {I18n} from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {createStyled} from '../../../style/index';

import {Table} from '../ChartDialog';

interface IProps {
  values: Table[];
  onChange: (value: Table) => void;
  initialValue?: Table;
}

const Styled = createStyled(theme => ({
  form: {
    display: 'flex',
    marginBottom: '10px',
  },
  resource: {
    flexBasis: '100%',
  },
  radioBtn: {
    paddingTop: 0,
    marginTop: -theme.spacing.unit * 2,
  },
  radioBtnHelp: {
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit * 2,
  },
}));

export default class ResourceStep extends React.Component<IProps, {}> {
  public handleOnChange = (event: React.ChangeEvent<{}>, value: string): void => {
    const {onChange} = this.props;

    onChange(value as Table);
  };

  public render() {
    const {initialValue, values} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <form className={classes.form}>
                <FormControl className={classes.resource}>
                  <RadioGroup
                    aria-label="resource"
                    name="resource"
                    value={initialValue || ''}
                    onChange={this.handleOnChange}
                  >
                    {values.map(option => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio color="primary" className={classes.radioBtn} />}
                        label={
                          <div>
                            <div>{t(`chart_dialog.steps.resource.resources.${option}`)}</div>
                            <FormHelperText className={classes.radioBtnHelp}>
                              {t(`chart_dialog.steps.resource.resources.${option} description`)}
                            </FormHelperText>
                          </div>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </form>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
