import * as React from 'react';
import {I18n} from 'react-i18next';

import {createStyled} from '../../style/index';
import {SingleAutocomplete, MultiAutocomplete} from '../Autocomplete/index';
import Error from '../Error';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {Subscribe} from 'unstated';
import {ApiClient} from '../../api/index';

interface IProps {
  isDisabled?: boolean;
  columnType: 'number' | 'enum' | 'boolean' | 'string';
  operator: string | null;
  initialValue?: string | {id: number} | Array<{id: number}>;
  values?: Array<string | {name: string; value: string}>;
  resource?:
    | 'machine'
    | 'location'
    | 'errorIdentity'
    | 'currency'
    | 'machinegroup'
    | 'locationgroup'
    | 'manufacturer'
    | 'machinemodel'
    | 'product'
    | 'machinetype'
    | 'debtor';
  searchProperty?: string;
  onChange: (value: string | {id: number} | Array<{id: number}>) => void;
}

type ITranslation = (str: string) => '';

const Styled = createStyled({
  formControl: {
    flexBasis: '100%',
  },
});

// fuzzy search fields for resource based columns
const openInputOperatorTypes = ['contains', 'begins with', 'ends with'];

export default class Value extends React.Component<IProps> {
  public handleOnChangeEvent = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const {onChange} = this.props;

    onChange(event.target.value);
  };

  public formatLabel = (value: {}): string => {
    const {searchProperty} = this.props;

    if (!searchProperty || !value) {
      return '';
    }

    return value[searchProperty] || '';
  };

  public compareValue = (a: {id: number}, b: {id: number}): boolean => {
    return a.id === b.id;
  };

  public renderField(classes: {formControl: string}, t: ITranslation): JSX.Element {
    const {columnType} = this.props;

    switch (columnType) {
      case 'string':
        return this.stringField(classes, t);
      case 'number':
        return this.numberField(classes, t);
      case 'enum':
        return this.enumField(classes, t);
      case 'boolean':
        return this.booleanField(classes, t);
      default:
        return <TextField label="Value" className={classes.formControl} disabled={true} />;
    }
  }

  public booleanField(classes: {formControl: string}, t: ITranslation): JSX.Element {
    const {initialValue} = this.props;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="value">{t('filter.value_label')}</InputLabel>
        <Select
          id="value"
          value={String(initialValue)}
          onChange={this.handleOnChangeEvent}
          className={classes.formControl}
        >
          <MenuItem value="0">{t('filter.boolean.no')}</MenuItem>
          <MenuItem value="1">{t('filter.boolean.yes')}</MenuItem>
        </Select>
      </FormControl>
    );
  }

  public numberField(classes: {formControl: string}, t: ITranslation): JSX.Element {
    const {initialValue} = this.props;

    return (
      <TextField
        label={t('filter.value_label')}
        type="number"
        value={String(initialValue)}
        className={classes.formControl}
        onChange={this.handleOnChangeEvent}
      />
    );
  }

  public enumField(classes: {formControl: string}, t: ITranslation): JSX.Element {
    const {initialValue, values} = this.props;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="value">{t('filter.value_label')}</InputLabel>
        <Select
          id="value"
          value={String(initialValue)}
          onChange={this.handleOnChangeEvent}
          className={classes.formControl}
        >
          {(values || []).map(option => (
            <MenuItem key={getEnumValue(option, 'name')} value={getEnumValue(option, 'value')}>
              {getEnumValue(option, 'name')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  public stringField(classes: {formControl: string}, t: ITranslation): JSX.Element {
    const {initialValue, resource, operator, searchProperty, onChange} = this.props;

    if (!resource || (operator && openInputOperatorTypes.indexOf(operator) > -1)) {
      return (
        <TextField
          label={t('filter.value_label')}
          className={classes.formControl}
          value={String(initialValue ? initialValue : '')}
          onChange={this.handleOnChangeEvent}
        />
      );
    }

    if (!resource) {
      return <Error>{t('filter.error.missing_resource')}</Error>;
    }

    if ((operator === 'equals' || operator === 'not equals') && !Array.isArray(initialValue)) {
      let initialItemId: number;

      if (typeof initialValue === 'object' && initialValue.id) {
        initialItemId = initialValue.id;
      } else if (typeof initialValue === 'number') {
        initialItemId = initialValue;
      }

      return (
        <Subscribe to={[ApiClient]}>
          {(api: ApiClient) => (
            <SingleAutocomplete
              label={t('filter.value_label')}
              placeholder={t('filter.search_placeholder')}
              className={classes.formControl}
              onChange={onChange}
              formatLabel={this.formatLabel}
              property={searchProperty || 'name'}
              resource={resource}
              id={initialItemId}
              api={api}
            />
          )}
        </Subscribe>
      );
    }

    if (operator === 'in' || operator === 'not') {
      return (
        <Subscribe to={[ApiClient]}>
          {(api: ApiClient) => (
            <MultiAutocomplete
              label="Value"
              placeholder={t('filter.search_placeholder')}
              className={classes.formControl}
              onChange={onChange}
              formatLabel={this.formatLabel}
              compareValue={this.compareValue}
              property={searchProperty || 'name'}
              resource={resource}
              initialValues={Array.isArray(initialValue) ? initialValue : []}
              api={api}
            />
          )}
        </Subscribe>
      );
    }

    return <Error>{t('filter.error.invalid_operator_string')}</Error>;
  }

  public render() {
    const {operator, isDisabled} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t =>
              !operator || isDisabled ? (
                <TextField
                  label={t('filter.value_label')}
                  className={classes.formControl}
                  value=""
                  disabled={true}
                />
              ) : (
                this.renderField(classes, t)
              )
            }
          </I18n>
        )}
      </Styled>
    );
  }
}

function getEnumValue(
  value: string | {name: string; value: string},
  type: 'name' | 'value'
): string {
  if (typeof value === 'string') {
    return value;
  }

  return type === 'name' ? value.name : value.value;
}
