import * as React from 'react';
import Reorder from 'react-reorder';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {createStyled} from '../../../style';

interface IProps {
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
  listItems?: IListItem[];
  addItems?: Array<{
    title: string | JSX.Element;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  }>;
  onReorder: (oldIndex: number, newIndex: number) => void;
}

interface IListItem {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  error?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMenuClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Styled = createStyled(theme => ({
  list: {
    width: '100%',
  },
  light: {
    color: theme.palette.grey[500],
  },
  draggable: {
    cursor: 'grab',
  },
  listItem: {
    backgroundColor: theme.palette.grey[100],
    // Use a margin, not a border, as a (white) border would show during dragging
    margin: `0 0 ${theme.spacing.unit}px`,
    '&.dragging': {
      cursor: 'grabbing',
      zIndex: 99,
    },
  },
  placeholder: {
    display: 'block',
    height: '51px',
  },
  menuIcon: {
    cursor: 'pointer',
    float: 'right',
  },
  addItem: {
    color: theme.palette.primary.main,
  },
  error: {
    borderBottom: '1px solid red',
  },
}));

/**
 * A list that can be ordered using drag & drop.
 */
export default class ListManager extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public handleListItemMenuClick = (event: React.MouseEvent<HTMLElement>, item: IListItem) => {
    if (item.onMenuClick) {
      item.onMenuClick(event);
    }
  };

  public handleOnReorder = (event: Event, oldIndex: number, newIndex: number) => {
    this.props.onReorder(oldIndex, newIndex);
  };

  /**
   * Prevent dragging/reordering on parts of the list that are not around the drag icon.
   *
   * See https://github.com/JakeSidSmith/react-reorder/issues/99
   */
  public preventReorder = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  public render() {
    const {title, subtitle, listItems, addItems} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <Grid container={true} spacing={40}>
            <Grid item={true} xs={12}>
              <Grid container={true} alignItems={'center'} spacing={32}>
                <Grid item={true} xs={12}>
                  <Typography variant="subtitle1">
                    {title} <span className={classes.light}>{subtitle}</span>
                  </Typography>

                  <List className={classes.list} component="nav">
                    <Reorder
                      reorderId={title}
                      draggedClassName="dragging"
                      // Huge lag/freeze when autoScroll is at its default setting
                      autoScroll={false}
                      lock="horizontal"
                      onReorder={this.handleOnReorder}
                      placeholder={
                        <ListItem className={`${classes.placeholder} ${classes.listItem}`} />
                      }
                    >
                      {listItems &&
                        listItems.map((item, i) => (
                          <ListItem
                            key={i}
                            button={true}
                            className={`${classes.listItem} ${classes.draggable}`}
                          >
                            <ListItemIcon>
                              <DragHandleIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={item.title}
                              className={item.error ? classes.error : ''}
                            />
                            {item.onMenuClick && (
                              <div
                                onMouseDown={this.preventReorder}
                                onClick={e => this.handleListItemMenuClick(e, item)}
                              >
                                <MoreVertIcon className={classes.menuIcon} />
                              </div>
                            )}
                          </ListItem>
                        ))}
                    </Reorder>
                    {addItems &&
                      addItems.map((addItem, i) => (
                        <ListItem
                          key={i}
                          button={true}
                          className={classes.listItem}
                          onClick={addItem.onClick}
                        >
                          <ListItemIcon>
                            <AddCircleIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            classes={{primary: classes.addItem}}
                            primary={addItem.title}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Styled>
    );
  }
}
