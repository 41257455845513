import * as React from 'react';
import {I18n} from 'react-i18next';
import memoize from 'memoize-one';
// import Drawer from '@material-ui/core/Drawer';
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import Hidden from '@material-ui/core/Hidden';

import {createStyled, theme as mainTheme} from '../style';
import Topbar from './Topbar';
// import Sidebar from './Sidebar';
import Error from './Error';

const memoizedCreateStyled = memoize(sidebarWidth =>
  createStyled(theme => ({
    sidebar: {
      // width: sidebarWidth,
      // maxWidth: '90vw',
    },
    content: {
      // [theme.breakpoints.up('md')]: {
      //   marginLeft: sidebarWidth,
      //   width: `calc(100% - ${
      //     typeof sidebarWidth === 'number' ? `${sidebarWidth}px` : sidebarWidth
      //   })`,
      // },
    },
    wrapper: {
      width: '95%',
      margin: 'auto',
      paddingTop: 2 * theme.spacing.unit,
    },
  }))
);

interface IProps {
  title: string | JSX.Element;
  sidebarWidth?: number | string;
}

interface IState {
  sidebarOpen: boolean;
  hasError: boolean;
}

/**
 * The `PageWrapper` provides the topbar and sidebar for a page. On wide
 * screens, the sidebar is docked and always visible. On narrow screens, the
 * sidebar is hidden and swipable.
 *
 * This component acts as an [error
 * boundary](https://reactjs.org/docs/error-boundaries.html) to its children,
 * rendering an `Error` in place of the body. To make sure all errors are
 * caught, keep the component rendering the `PageWrapper` as simple as
 * possible, ideally just a thin wrapper.
 */
export default class PageWrapper extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {sidebarWidth: 32 * mainTheme.spacing.unit};

  public state = {sidebarOpen: false, hasError: false};

  public handleSidebarOpen = () => this.setState({sidebarOpen: true});

  public handleSidebarClose = () => this.setState({sidebarOpen: false});

  public componentDidCatch() {
    this.setState({hasError: true});
  }

  public render() {
    const Styled = memoizedCreateStyled(this.props.sidebarWidth);
    const {title} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <React.Fragment>
            {/* App Topbar */}
            <Topbar
              title={title}
              // sidebarWidth={sidebarWidth}
              onSidebarOpen={this.handleSidebarOpen}
            />

            {/*

              Uncomment to enable sidemenu again, no point in having a sidebar with only 1 item

            */}
            {/* Swipeable App Sidebar and menu button on narrow screens */}
            {/* <Hidden mdUp={true}>
              <SwipeableDrawer
                open={this.state.sidebarOpen}
                onOpen={this.handleSidebarOpen}
                onClose={this.handleSidebarClose}
                onClick={this.handleSidebarClose}
              >
                <Sidebar className={classes.sidebar} />
              </SwipeableDrawer>
            </Hidden> */}

            {/* Fixed App Sidebar on wide screens */}
            {/* <Hidden smDown={true} implementation="css">
              <Drawer variant="permanent" open={true}>
                <Sidebar className={classes.sidebar} />
              </Drawer>
            </Hidden> */}

            <div className={classes.content}>
              <div className={classes.wrapper}>
                {this.state.hasError ? (
                  <I18n>{t => <Error>{t('error.loading_page')}</Error>}</I18n>
                ) : (
                  this.props.children
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </Styled>
    );
  }
}
