import * as React from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipFormatter,
  Label,
} from 'recharts';

import {createStyled} from '../../../style';
import {colors as COLORS} from '../../../data/colors';

interface IState {
  data: any[];
}

interface IProps {
  data: any[];
  xProperty: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  lines: Array<{dataKey: string; color?: string; label: string}>;
  xAxisLabelFormat?: (value: string | number) => string;
  yAxisLabelFormat?: (value: string | number) => string;
  tooltipValueFormat?: TooltipFormatter;
  tooltipLabelFormat?: (value: string | number) => string;
}

const Styled = createStyled(theme => ({
  defaultText: {
    fontFamily: theme.typography.fontFamily,
  },
}));

export default class LineChartCmp extends React.Component<IProps, IState> {
  public render() {
    const {
      xProperty,
      data,
      lines,
      xAxisLabelFormat,
      yAxisLabelFormat,
      tooltipValueFormat,
      tooltipLabelFormat,
      yAxisLabel,
      xAxisLabel,
    } = this.props;

    return (
      <div style={{width: '100%', height: '100%'}}>
        <Styled>
          {({classes}) => (
            <ResponsiveContainer>
              <LineChart
                data={data}
                style={{overflow: 'hidden', fontSize: '11px'}}
                margin={{
                  top: 5,
                  right: 10,
                  left: yAxisLabel ? 30 : 15,
                  bottom: xAxisLabel ? 40 : 5,
                }}
              >
                <CartesianGrid stroke={'#E0E0E0'} strokeDasharray="0 0" vertical={false} />

                {/* X Axis definition */}
                <XAxis
                  dataKey={xProperty}
                  tickFormatter={xAxisLabelFormat ? xAxisLabelFormat : undefined}
                >
                  <Label
                    value={xAxisLabel}
                    offset={-20}
                    position="insideBottom"
                    style={{textAnchor: 'middle'}}
                    className={classes.defaultText}
                  />
                </XAxis>

                {/* Y Axis definition */}
                <YAxis
                  yAxisId="left"
                  tickFormatter={yAxisLabelFormat ? yAxisLabelFormat : undefined}
                >
                  {yAxisLabel && (
                    <Label
                      value={yAxisLabel}
                      position="left"
                      angle={-90}
                      style={{textAnchor: 'middle'}}
                      className={classes.defaultText}
                    />
                  )}
                </YAxis>

                {/* Show tooltip on hover */}
                <Tooltip
                  labelFormatter={tooltipLabelFormat || xAxisLabelFormat || undefined}
                  formatter={tooltipValueFormat ? tooltipValueFormat : undefined}
                />
                {/* Drawing lines */}
                {lines.map((l, i) => (
                  <Line
                    key={i}
                    connectNulls={true}
                    yAxisId="left"
                    type="linear"
                    dataKey={l.dataKey}
                    name={l.label}
                    stroke={l.color || COLORS[i] ? COLORS[i] : COLORS[0]}
                    strokeWidth={3 - i}
                    dot={false}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          )}
        </Styled>
      </div>
    );
  }
}
