import * as React from 'react';
import {Textfit} from 'react-textfit';

import {createStyled} from '../style';

import Grid from '@material-ui/core/Grid';

const Styled = createStyled(theme => ({
  numberBox: {
    '-webkit-box-shadow': 'inset 0px 0px 0px 1px rgb(163, 166, 168, 0.3)',
    '-moz-box-shadow': 'inset 0px 0px 0px 1px rgb(163, 166, 168, 0.3)',
    boxShadow: 'inset 0px 0px 0px 1px rgb(163, 166, 168, 0.3)',
    padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 2}px`,
    borderRadius: '2px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      minHeight: theme.spacing.unit * 8.5,
    },
  },
  pointerCursor: {
    cursor: 'pointer',
    '-webkit-box-shadow': 'inset 0px 0px 0px 1px rgb(163, 166, 168)',
    '-moz-box-shadow': 'inset 0px 0px 0px 1px rgb(163, 166, 168)',
    boxShadow: 'inset 0px 0px 0px 1px rgb(163, 166, 168)',
    backgroundColor: 'rgb(163, 166, 168, 0.1)',
  },
  activeBox: {
    color: '#338357',
    '-webkit-box-shadow': 'inset 0px 0px 0px 2px #338357',
    '-moz-box-shadow': 'inset 0px 0px 0px 2px #338357',
    boxShadow: 'inset 0px 0px 0px 2px #338357',
  },
  titleBlock: {
    marginBottom: theme.spacing.unit / 2,
  },
  captionBlock: {
    marginTop: theme.spacing.unit / 2,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal',
    },
  },
  value: {
    fontSize: '20px',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal',
      fontSize: '14px',
    },
  },
  captionText: {
    fontSize: '10px',
    color: '#a3a6a8',
  },
}));

interface IProps {
  title: string;
  value: string | number | JSX.Element;
  isActive?: boolean;
  captionText?: string | JSX.Element;
  onClick?: () => void;
}

interface IState {
  isHover: boolean;
}

export default class ValueBox extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  public handleMouseHover = () => {
    this.setState({isHover: true});
  };

  public handleMouseLeave = () => {
    this.setState({isHover: false});
  };

  public render() {
    const {isHover} = this.state;
    const {title, value, onClick, isActive, captionText} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <Grid
            container={true}
            className={`${classes.numberBox} ${isHover &&
              onClick &&
              !isActive &&
              classes.pointerCursor} ${isActive && classes.activeBox}`}
            onClick={onClick}
            onMouseOver={this.handleMouseHover}
            onMouseLeave={this.handleMouseLeave}
          >
            {/* Title of the box */}
            <Grid xs={12} item={true} className={classes.titleBlock}>
              <span className={classes.title}>
                <Textfit mode="single" forceSingleModeWidth={false} max={13}>
                  {title}
                </Textfit>
              </span>
            </Grid>
            {/* Value of the box */}
            <Grid xs={12} item={true}>
              <span className={classes.value}>
                <Textfit mode="single" forceSingleModeWidth={false} max={20}>
                  {value}
                </Textfit>
              </span>
            </Grid>
            {/* Description of the box */}
            {captionText && (
              <Grid xs={12} item={true} className={classes.captionBlock}>
                <span className={classes.captionText}>
                  <Textfit mode="single" forceSingleModeWidth={false} max={12}>
                    {captionText}
                  </Textfit>
                </span>
              </Grid>
            )}
          </Grid>
        )}
      </Styled>
    );
  }
}
