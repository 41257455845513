import * as React from 'react';
import {Switch, Route, Redirect, RouteProps, RouteComponentProps} from 'react-router-dom';
import memoize from 'memoize-one';
import {Subscribe} from 'unstated';

import {ApiClient} from './api';
import GoogleAnalytics from './GoogleAnalytics';
import UpdateNotification from './components/UpdateNotification';
import Login from './pages/Login';
import Report from './pages/Report';
import Chart from './pages/Chart';
import NotFound from './pages/NotFound';
import WithCancelToken from './components/hoc/WithCancelToken';

const ReportWithCancelToken = WithCancelToken(Report);
const ChartWithCancelToken = WithCancelToken(Chart);

export default function App(props: {ga: GoogleAnalytics}) {
  const {ga} = props;

  ga.initialize();

  return (
    <React.Fragment>
      <Subscribe to={[ApiClient]}>
        {(api: ApiClient) => {
          return (
            <Switch>
              <Route path="/login" component={Login} />
              <AuthenticatedRoute
                path="/"
                exact={true}
                component={ReportWithCancelToken}
                api={api}
                ga={ga}
              />
              <AuthenticatedRoute
                path="/reports"
                exact={true}
                component={ReportWithCancelToken}
                api={api}
                ga={ga}
              />
              <AuthenticatedRoute
                path="/reports/:id"
                exact={true}
                component={ReportWithCancelToken}
                api={api}
                ga={ga}
              />
              <AuthenticatedRoute
                path="/chart/:id"
                exact={true}
                component={ChartWithCancelToken}
                api={api}
                ga={ga}
              />
              <AuthenticatedRoute path="/404" component={NotFound} api={api} ga={ga} />
              <AuthenticatedRoute component={NotFound} api={api} ga={ga} />
            </Switch>
          );
        }}
      </Subscribe>
      <UpdateNotification />
    </React.Fragment>
  );
}

/**
 * Route component that behaves exactly like a normal Route if the user is
 * authenticated, and redirects to Login if the user is *not* authenticated.
 */
class AuthenticatedRoute extends React.Component<
  RouteProps & {api?: ApiClient; ga: GoogleAnalytics}
> {
  /**
   * Create a component that, based on auth state, either renders the given
   * component or redirects to "/login".
   *
   * This function is memoized, so as long as the `component` and/or `render`
   * props stay the same this function only has to run once. Note: each
   * AuthenticatedRoute has its own memoized version of this function.
   */
  public createRenderOrRedirect = memoize((Component, originalRender) => {
    // When rendering, <Route component> takes precedence over <Route render>
    const render = Component
      ? (props: RouteComponentProps<any>) => (
          <Component
            {...props}
            api={this.props.api ? this.props.api : undefined}
            ga={this.props.ga}
          />
        )
      : originalRender || (() => null);

    // When redirecting to Login, we include the location to redirect back to.
    const redirect = ({location}: RouteComponentProps<any>) => (
      <Redirect to={{pathname: '/login', state: {redirectTo: location}}} />
    );

    return function RenderOrRedirect(props: RouteComponentProps<any>) {
      return (
        <Subscribe to={[ApiClient]}>
          {({isAuthenticated}: ApiClient) => (isAuthenticated() ? render(props) : redirect(props))}
        </Subscribe>
      );
    };
  });

  public render() {
    const {component, render, ...props} = this.props;
    const RenderOrRedirect = this.createRenderOrRedirect(component, render);

    return <Route {...props} component={RenderOrRedirect} />;
  }
}
