import * as React from 'react';
import {PieChart, Pie, Cell, Tooltip, ResponsiveContainer, TooltipFormatter} from 'recharts';

type LabelFormat = (label: string | number, payload?: any) => string;
type ValueFormat = (label: string | number) => string;

import {createStyled} from '../../../style';
import generateRandomColor from '../../../utils/generateRandomColor';
import {colors as COLORS} from '../../../data/colors';

const Styled = createStyled(theme => ({
  defaultText: {
    fontFamily: theme.typography.fontFamily,
  },
}));
interface IProps {
  data: any[];
  nameProperty: string;
  metricProperty: string;
  useIncrementalIndex?: boolean;
  labelFormat?: LabelFormat;
  valueFormat?: ValueFormat;
  tooltipValueFormat?: TooltipFormatter;
}

const pieThickness = 30;
const spaceInBetween = 5;
const defaultOutterRadius = 100;

export default class PieChartCmp extends React.Component<IProps, {}> {
  // Whether the component should use the array index as suffix for the metricProperty
  // Is `true` by default
  private useIncrementalIndex: boolean;

  constructor(props: IProps) {
    super(props);

    const {useIncrementalIndex} = props;

    this.useIncrementalIndex = useIncrementalIndex !== undefined ? useIncrementalIndex : true;
  }

  public render() {
    const {
      data,
      nameProperty,
      metricProperty,
      labelFormat,
      valueFormat,
      tooltipValueFormat,
    } = this.props;

    return (
      <div style={{width: '100%', height: '100%'}}>
        <ResponsiveContainer>
          <PieChart style={{overflow: 'hidden'}}>
            {data.map((d, i) => (
              <Pie
                key={i}
                data={d}
                innerRadius={calculateInnerRadius(i)}
                outerRadius={calculateOuterRadius(i)}
                fill="#8884d8"
                paddingAngle={0}
                dataKey={`${metricProperty}${this.useIncrementalIndex ? i : ''}`}
                nameKey={nameProperty}
                label={
                  data.length === 1
                    ? (v: any) => labelFormatter(v, labelFormat, valueFormat)
                    : false
                }
              >
                {/* Drawing cells of the pie */}
                {d.map((entry: any, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.color ? entry.color : COLORS[index] || generateRandomColor()}
                  />
                ))}
              </Pie>
            ))}

            {data.length !== 1 && (
              <Tooltip
                formatter={(value: any, name: any, payload: any) =>
                  labelFormatter({value, name, payload}, labelFormat, valueFormat)
                }
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

function labelFormatter(
  {value, name, payload}: {value: number; name: string; payload?: any[]},
  labelFormat?: LabelFormat,
  valueFormat?: ValueFormat
) {
  const key = labelFormat ? labelFormat(name, payload) : name;
  const labelText = `${key}: ${valueFormat ? valueFormat(value) : value}`;

  return labelText;
}

function calculateInnerRadius(index: number): number {
  if (index === 0) {
    return defaultOutterRadius - pieThickness;
  }

  return defaultOutterRadius - index * pieThickness - spaceInBetween - pieThickness;
}

function calculateOuterRadius(index: number): number {
  if (index === 0) {
    return defaultOutterRadius;
  }

  return defaultOutterRadius - index * pieThickness - spaceInBetween;
}
