import * as React from 'react';
import {I18n} from 'react-i18next';

import StepperDialog from '../StepperDialog';
import FilterStep from '../Filter/index';

import {
  properties,
  translateProperty,
  getTimeFrameColumn,
  IDimension,
  IMetric,
  Resource,
  dimensions,
  metrics,
  defaultDimensionsAndMetrics,
  isDimension,
  isMetric,
  columnToSelect,
} from './properties';

import NameStep from './steps/NameStep';
import ResourceStep from './steps/ResourceStep';
import OrderByStep from './steps/OrderByStep';
import TimeframeStep from './steps/TimeframeStep';
import {IChartData, ChartView, ChartType, chartViews, singleTimeFrameChartType} from '../../api';
import PresentationStep from './steps/PresentationStep';

/**
 * The virtual reporting tables.
 *
 * Since August 2019, "paymenttransaction" is the combined revenue and payment transaction data,
 * while "revenuetransaction" is obsolete (and only kept here for existing reports). See {@link
 * resourceTables}.
 */
export type Table = 'log' | 'paymenttransaction' | 'revenuetransaction' | 'alert' | 'logstatus';

export type TimeFrame =
  | {
      type: 'fixed';
      startAt: string;
      endAt: string;
      column?: string;
      tz?: string;
    }
  | {
      type: 'trailing';
      tz: string;
      hoursToSub: number;
      column?: string;
    }
  | {
      type: 'descriptive';
      tz: string;
      description: string;
      column?: string;
      referenceDate?: string;
    };

/**
 * A user-selectable database column (to which an aggregate function might still be applied) or
 * computed column (which already has a fixed, immutable function defined, like for "day of week").
 */
export interface IColumn {
  name?: string;
  column: string;
  function?: string;
  functionParams?: string[];
  type?: string;
  joinType?: 'left' | 'right';
}

interface IFilterRow {
  column: string;
  operator: string;
  value?: string | {id: number} | Array<{id: number; name?: string}>;
}

/**
 * A database column or computed column (such as "day of week", "duration" or "priceCash") along
 * with specific user settings (such as the display name, or an aggregate function like "sum") as
 * used in a report query.
 */
export interface ISelect {
  // The column name, like `location` for an association's id, or `location.name` for a value.
  column: string;
  // The user-selected or fixed function to apply to the column, either plain 'select', or group-by
  // aggregates such as 'count', 'avg', 'min', 'max' or 'sum', or `date_part` which cannot be
  // changed by the user.
  function?: string;
  // Parameters, like for the `date_part` function, to select e.g. hour, weekday or day
  functionParams?: string[];
  // The label as shown in the report
  // it is also used as property name in the result object
  alias?: string;
  joinType?: 'left' | 'right';
  // The column label (mostly used twhen merging multiple time frames into one single result
  // in the custom TableChart)
  label?: string;
}

interface IOrderColumn {
  column: string;
  function?: string;
  name?: string;
}

interface ITableColumn {
  name?: string;
  column: string;
  resource?: Resource;
  type?: 'number' | 'enum' | 'boolean' | 'string';
  options?: string[];
  // Suppress admin-only fields without fetching permissions through the API
  adminOnly?: boolean;
}

export interface IOrderBy {
  column: string;
  direction: 'ASC' | 'DESC';
  function?: string;
}

interface IProps {
  onSubmit: (result: IChartData) => void;
  onCancel: () => void;
  initialValue?: IState;
  initialStep?: number;
  isAdmin: boolean;
}

export interface IState {
  from: Table | null;
  where: IFilterRow[];
  select: ISelect[];
  timeFrames: TimeFrame[];
  type: ChartType;
  view: ChartView;
  orderBy: IOrderBy[];
  name: string;
  disableComparison?: boolean;
}

/**
 * The user-selectable resources for new reports.
 *
 * Since August 2019, this no longer includes "revenuetransaction", which is still in {@link Table}.
 */
const resourceTables: Table[] = ['paymenttransaction', 'log', 'logstatus', 'alert'];

export default class ChartDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {initialValue} = props;

    this.state = {
      from: initialValue ? initialValue.from : null,
      where: initialValue ? initialValue.where : [],
      select: initialValue ? initialValue.select : [],
      orderBy: initialValue ? initialValue.orderBy : [],
      timeFrames: initialValue ? initialValue.timeFrames : [],
      type: initialValue ? initialValue.type : 'custom',
      view: initialValue ? initialValue.view : chartViews[0],
      name: initialValue ? initialValue.name : '',
      disableComparison: initialValue
        ? singleTimeFrameChartType.indexOf(initialValue.view) > -1
        : false,
    };
  }

  public sortedProperties = {};

  public getSortedProperties(): ITableColumn[] {
    const {from} = this.state;

    if (from) {
      // sort translated properties and cache them
      if (!this.sortedProperties[from]) {
        let tableProperties: Array<{
          name?: string;
          column: string;
          type?: string;
        }> = properties[from];

        tableProperties = tableProperties.sort(
          (a: {name?: string; column: string}, b: {name?: string; column: string}) => {
            return translateProperty(a) > translateProperty(b) ? 1 : -1;
          }
        );

        this.sortedProperties[from] = tableProperties;
      }

      return this.removeForbiddenColumns(this.sortedProperties[from]);
    }

    return [];
  }

  /**
   * Suppress admin-only fields (without fetching permissions through the API).
   */
  private removeForbiddenColumns(tableColumns: ITableColumn[]): ITableColumn[] {
    if (this.props.isAdmin) {
      return tableColumns;
    }
    // Temporary quick fix to suppress admin-only fields
    return tableColumns.filter(c => !c.adminOnly);
  }

  public handleSubmit = () => {
    const {where, type, view, select, from, timeFrames, orderBy, name} = this.state as IState;
    const hydratedFilters: Array<{
      column: string;
      operator: string;
      value: string | number | number[];
    }> = [];

    // hydrate filter so values contains either a string, number or number[]
    for (const filter of where) {
      if (Array.isArray(filter.value)) {
        hydratedFilters.push({...filter, value: filter.value.map(v => Number(v.id))});
      } else if (typeof filter.value === 'object') {
        hydratedFilters.push({...filter, value: Number(filter.value.id)});
      } else if (typeof filter.value === 'number' || filter.value) {
        hydratedFilters.push({...filter, value: filter.value});
      }
    }

    // minimal validation before submission
    if (select && from) {
      this.props.onSubmit({
        name,
        type,
        view,
        position: 0,
        criteria: {select, from, where: hydratedFilters, timeFrames, orderBy},
      });
    }
  };

  public handleCancel = (): void => {
    this.props.onCancel();
  };

  private getDefaultDimensionsAndMetricsForResource = (from: Table): ISelect[] => {
    const defaults = defaultDimensionsAndMetrics[from];
    if (!defaults) {
      return [];
    }

    const dimensionDefaults = this.mapDimensionsOrMetricsDefaults(defaults.dimensions, dimensions);
    const metricDefaults = this.mapDimensionsOrMetricsDefaults(defaults.metrics, metrics);
    return dimensionDefaults.concat(metricDefaults);
  };

  /**
   * Map the array of defaults, referring to a `name` or `column` in the given source, preserving
   * the suggested order of the defaults.
   */
  private mapDimensionsOrMetricsDefaults(
    defaults: string[] | undefined,
    source: {[key: string]: Array<IDimension | IMetric>}
  ): ISelect[] {
    return (defaults || []).reduce(
      (acc, name) => {
        for (const key of Object.keys(source)) {
          const item = source[key].find(d => d.name === name || d.column === name);
          if (item) {
            acc.push(columnToSelect(item, true));
          }
        }
        return acc;
      },
      [] as ISelect[]
    );
  }

  public handleResourceChange = (value: Table): void => {
    this.setState({
      from: value,
      where: [],
      select: this.getDefaultDimensionsAndMetricsForResource(value),
      orderBy: [],
    });

    if (this.state.timeFrames) {
      const timeFrameColumn = getTimeFrameColumn(value, this.state.type);
      const newTimeFrames = this.state.timeFrames.map(timeFrame => ({
        ...timeFrame,
        column: timeFrameColumn,
      }));

      this.setState({timeFrames: newTimeFrames});
    }
  };

  public handleFilterChanged = (filters: IFilterRow[]): void => {
    this.setState({
      where: filters.filter(filter => {
        if (Array.isArray(filter.value)) {
          return filter.column && filter.operator && filter.value.length;
        }

        return (
          filter.column && filter.operator && (typeof filter.value === 'number' || filter.value)
        );
      }),
    });
  };

  public handleSelectedColumnChanged = (columns: ISelect[]): void => {
    this.setState(state => {
      return {
        select: columns.map(column => {
          if (column.function && column.function === 'select') {
            const newColumn = {...column};
            delete newColumn.function;

            return newColumn;
          }

          return column;
        }),
        // Also need to remove the orderBy columns that are not selected anymore (orderBy columns contain alias)
        orderBy: state.orderBy.filter(o => columns.some(c => c.alias === o.column)),
      };
    });
  };

  public handleOrderByChange = (orderBy: IOrderBy[]): void => {
    this.setState({orderBy: orderBy.filter(v => v.column)});
  };

  public handleNameChange = (name: string): void => {
    this.setState({name});
  };

  public handleTypeChange = (type: ChartType): void => {
    this.setState({type});
  };

  public handleTimeFrameChanged = (timeFrames: TimeFrame[]): void => {
    this.setState({
      timeFrames: timeFrames.map(timeframe => ({
        ...timeframe,
        column: getTimeFrameColumn(this.state.from, this.state.type),
      })),
    });
  };

  public handleChartViewChanged = (chartView: ChartView): void => {
    this.setState({view: chartView});
  };

  public allowedToGoToNextStep = (currentStep: number): boolean => {
    const {from, select, where, timeFrames, type, view, name} = this.state;

    switch (currentStep) {
      case 0: // name
        return Boolean(name.length);
      case 1: // resource and a chart type
        return Boolean(from) && Boolean(type);
      case 2: // select columns
        const uniqueAliases = {};
        for (const v of select) {
          uniqueAliases[(v.alias || '').toLowerCase()] = true;
        }

        return (
          Boolean(select.length) &&
          select.every(v => Boolean(v.column && v.alias)) &&
          // A table could be rendered with either dimension(s) or metric(s), but we require at
          // least one dimension. Graphs always need both.
          select.some(v => isDimension(v)) &&
          (view === 'table' || select.some(v => isMetric(v))) &&
          Object.keys(uniqueAliases).length === select.length // check for duplicates
        );
      case 3: // filter
        return true; // optional field
      case 4: // order by columns
        return true; // optional field
      case 5: // group by columns
        return true; // optional field
      case 6: // timeFrame
        if (!timeFrames || !timeFrames.length) {
          return false;
        }

        return timeFrames.some((timeFrame: TimeFrame) => {
          if (!timeFrame) {
            return false;
          }

          const fixedTimeFrameCheck = Boolean(
            timeFrame.type && timeFrame.type === 'fixed' && timeFrame.startAt && timeFrame.endAt
          );
          const trailingTimeframeCheck = Boolean(
            timeFrame.type && timeFrame.type === 'trailing' && timeFrame.hoursToSub
          );
          const descriptiveTimeframeCheck = Boolean(
            timeFrame.type &&
              timeFrame.type !== 'trailing' &&
              timeFrame.type !== 'fixed' &&
              timeFrame.description
          );

          return fixedTimeFrameCheck || trailingTimeframeCheck || descriptiveTimeframeCheck;
        });
      default:
        return false;
    }
  };

  public handleSetDisableComparison = (disabled: boolean) => {
    this.setState((prevState: IState) => {
      const newState: IState = {...prevState, disableComparison: disabled};

      // When timeFrame comparison is disabled, we should make sure that only one timeFrame is being set
      if (disabled) {
        newState.timeFrames = [prevState.timeFrames[0]];
      }

      return newState;
    });
  };

  public render() {
    const {
      from,
      where,
      select,
      orderBy,
      timeFrames,
      type,
      view,
      name,
      disableComparison,
    } = this.state;
    // Properties of the currently selected resource, that can all be used for filtering, and can
    // partially be used in the output
    const tableProperties = this.getSortedProperties();
    const orderByColumnAvailable = getOrderableColumns(select);
    // Properties that can be used in the report's output
    const propertiesWithoutCollections = tableProperties.filter(
      v => v.column !== 'locationgroup' && v.column !== 'machinegroup'
    );

    return (
      <I18n>
        {t => (
          <StepperDialog
            title={
              name
                ? `${t('chart_dialog.report_header')} - ${name}`
                : t('chart_dialog.report_header')
            }
            initialStep={this.props.initialStep ? this.props.initialStep : undefined}
            steps={[
              {
                title: t('chart_dialog.steps.name.step_title'),
                content: (
                  <React.Fragment>
                    <NameStep onChange={this.handleNameChange} initialValue={{name, type}} />
                  </React.Fragment>
                ),
                completed: this.allowedToGoToNextStep(0),
              },
              {
                title: t('chart_dialog.steps.resource.step_title'),
                content: (
                  <React.Fragment>
                    <ResourceStep
                      onChange={this.handleResourceChange}
                      initialValue={from || undefined}
                      values={resourceTables}
                    />
                    {/*<ChartTypeStep
                      initialValue={type}
                      values={chartTypes}
                      onChange={this.handleChartTypeChanged}
                    />*/}
                  </React.Fragment>
                ),
                completed: this.allowedToGoToNextStep(1),
              },
              {
                title: t('chart_dialog.steps.presentation.step_title'),
                content: (
                  <React.Fragment>
                    <PresentationStep
                      // Both dimensions and metrics, for both "Table" and "Chart" types
                      onSelectedColumnsChange={this.handleSelectedColumnChanged}
                      onChartViewChange={this.handleChartViewChanged}
                      initialValues={select}
                      columns={propertiesWithoutCollections}
                      view={view}
                      onSetDisableComparison={this.handleSetDisableComparison}
                    />
                  </React.Fragment>
                ),
                completed: this.allowedToGoToNextStep(2),
              },
              {
                title: t('chart_dialog.steps.filter.step_title'),
                content: (
                  <FilterStep
                    onChange={this.handleFilterChanged}
                    initialFilters={where}
                    columns={tableProperties}
                  />
                ),
                completed: this.allowedToGoToNextStep(2),
              },
              {
                title: t('chart_dialog.steps.order_by.step_title'),
                content: (
                  <OrderByStep
                    onChange={this.handleOrderByChange}
                    initialValues={orderBy}
                    columns={orderByColumnAvailable}
                  />
                ),
                completed: this.allowedToGoToNextStep(4),
              },
              {
                title: t('chart_dialog.steps.timeframe.step_title'),
                content: (
                  <TimeframeStep
                    initialValue={timeFrames || []}
                    onChange={this.handleTimeFrameChanged}
                    disableComparison={disableComparison}
                  />
                ),
                completed: this.allowedToGoToNextStep(6),
              },
            ]}
            goToNextStep={this.allowedToGoToNextStep}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        )}
      </I18n>
    );
  }
}

function getOrderableColumns(select: ISelect[]): IOrderColumn[] {
  const initialReduce: Array<{column: string; function?: string; name?: string}> = [];

  return select.reduce((columnsSelected, s) => {
    const orderByProperty: {column: string; function?: string; name?: string} = {
      // For backward compatibility. We do not select '*' anymore for counts
      column: s.column === '*' ? 'id' : s.column,
      name: s.alias || s.column,
    };

    if (s.function && s.function !== 'select') {
      orderByProperty.function = s.function;
      orderByProperty.name = s.alias;
    }

    columnsSelected.push(orderByProperty);

    return columnsSelected;
  }, initialReduce);
}
