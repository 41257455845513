import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'unstated';
import {BrowserRouter} from 'react-router-dom';

import GoogleAnalytics from './GoogleAnalytics';
import UpdateManager from './UpdateManager';
import {ApiClient} from './api';
import {apiUrl} from './config';
import {ThemeProvider} from './style';
import {I18nProvider} from './i18n';
import App from './App';

const api = new ApiClient({apiUrl});
const ga = new GoogleAnalytics({api});
const updateManager = new UpdateManager();
const rootElement = document.getElementById('root') as HTMLElement;

export function renderApp() {
  ReactDOM.render(
    <Provider inject={[api, updateManager, ga]}>
      <BrowserRouter>
        <ThemeProvider>
          <I18nProvider>
            <App ga={ga} />
          </I18nProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
    rootElement
  );

  updateManager.registerServiceWorker();
}
