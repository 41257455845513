/* tslint:disable:no-console */

import {Container} from 'unstated';

type State = {
  state: undefined | 'available' | 'dismissed';
};

/**
 * The UpdateManager manages the service worker registration. After
 * `registerServiceWorker` is called, the manager keeps track of whether or not
 * an update is available. It also offers `apply` and `dismiss` functions for
 * use in an "update available" notification.
 */
export default class UpdateManager extends Container<State> {
  private registration: undefined | ServiceWorkerRegistration = undefined;

  public state: State = {state: undefined};

  public get updateAvailable(): boolean {
    return this.state.state === 'available';
  }

  public get updateDismissed(): boolean {
    return this.state.state === 'dismissed';
  }

  public registerServiceWorker = () => {
    registerServiceWorker(
      registration => {
        this.registration = registration;
      },
      () => {
        this.setState({state: 'available'});
      }
    );
  };

  public apply = () => {
    if (this.registration && this.registration.waiting) {
      this.registration.waiting.postMessage('skipWaiting');
      this.setState({state: undefined});
    }
  };

  public dismiss = () => {
    this.setState({state: 'dismissed'});
  };
}

/**
 * Await window.onload and then register `/service-worker.js`.
 *
 * Takes two callbacks; the first is called as soon as the registration is
 * complete (and gets passed a reference to the registration), the second is
 * called when an update is available.
 */
async function registerServiceWorker(
  handleRegistration: (registration: ServiceWorkerRegistration) => void,
  handleUpdateAvailable: () => void
): Promise<void> {
  if (process.env.NODE_ENV !== 'production' || !('serviceWorker' in navigator)) {
    return;
  }
  try {
    await new Promise(resolve => window.addEventListener('load', resolve));
    const registration = await navigator.serviceWorker.register('/service-worker.js');
    refreshOnControllerChange();
    handleRegistration(registration);
    onUpdateAvailable(registration, handleUpdateAvailable);
  } catch (error) {
    console.warn('Failed to register service worker:', error);
  }
}

/**
 * Make sure the page is refreshed when a new service worker becomes active.
 *
 * Only refreshes pages that are already controlled by a service worker. On
 * uncontrolled pages, the new service worker will activate immediately and a
 * refresh is not necessary.
 */
function refreshOnControllerChange(): void {
  if (!navigator.serviceWorker.controller) {
    return;
  }

  let reloading = false;
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (reloading) {
      return; // prevent refresh loop when using Chrome's "update on reload"
    }
    reloading = true;
    window.location.reload();
  });
}

/**
 * Listen for an update to the current service worker, or the registration of a
 * new one, and call back to the update handler.
 */
function onUpdateAvailable(
  registration: ServiceWorkerRegistration,
  handleUpdateAvailable: () => void
) {
  if (!navigator.serviceWorker.controller) {
    // uncontrolled page, so new service worker will activate immediately
    return;
  }

  if (registration.waiting) {
    // there's a already an update waiting
    handleUpdateAvailable();
    return;
  }

  if (registration.installing) {
    // wait for the current service worker's state to change
    onServiceWorkerChange();
  } else {
    // wait for a new service worker to be registered
    registration.addEventListener('updatefound', onServiceWorkerChange);
  }

  function onServiceWorkerChange() {
    const installing = registration.installing;
    if (!installing) {
      return;
    }
    installing.addEventListener('statechange', () => {
      if (installing.state === 'installed') {
        handleUpdateAvailable();
      }
    });
  }
}
