import {i18n} from '../i18n';
import {IColumn} from './';

export default function translateEnum(value: number | string, column: IColumn): string | number {
  if (column.function && column.function !== 'select') {
    return value;
  }

  return i18n.t(`filter.enum.${value}`);
}
