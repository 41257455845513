/* tslint:disable:no-console */

const development = process.env.NODE_ENV! === 'development';
const production = process.env.NODE_ENV! === 'production';
const test = process.env.NODE_ENV! === 'test';
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:1337';
const googleAnalyticsTrackerId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID; // || 'UA-00000-1';
const trailingChartResultsTtl = process.env.REACT_APP_TRAILING_CHART_RESULTS_TTL || 600; // expiration time of trailing charts results (in seconds)

if (!process.env.REACT_APP_API_URL) {
  console.warn('Missing API URL, defaulting to http://localhost:1337');
}
if (!process.env.GOOGLE_ANALYTICS_TRACKER_ID) {
  console.warn('GA tracker id is missing');
}
if (!development && !production && !test) {
  throw new Error(`Invalid env "${process.env.NODE_ENV}"`);
}

export {development, production, test, apiUrl, googleAnalyticsTrackerId, trailingChartResultsTtl};
