import * as React from 'react';

import {createStyled} from '../style';

import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const Styled = createStyled(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface IProps {
  message: string;
  onClose?: () => void;
  duration?: number; // in ms
  type: 'success' | 'warning' | 'info' | 'error';
}

export default class Notification extends React.Component<IProps> {
  public handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public render() {
    const {type, message, duration} = this.props;

    const Icon = variantIcon[type];

    return (
      <Styled>
        {({classes}) => (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={true}
            autoHideDuration={duration || 2500}
            transitionDuration={300}
            onClose={this.handleClose}
          >
            <SnackbarContent
              className={classes[type]}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <Icon className={`${classes.icon} ${classes.iconVariant}`} />
                  {message}
                </span>
              }
            />
          </Snackbar>
        )}
      </Styled>
    );
  }
}
