import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import FilterRow from './FilterRow';
import {createStyled} from '../../style/index';

interface IProps {
  columns: Array<{
    name?: string;
    column: string;
    resource?:
      | 'machine'
      | 'location'
      | 'errorIdentity'
      | 'currency'
      | 'machinegroup'
      | 'locationgroup'
      | 'manufacturer'
      | 'machinemodel'
      | 'product'
      | 'machinetype'
      | 'debtor';
    type?: 'number' | 'enum' | 'boolean' | 'string';
    options?: Array<string | {name: string; value: string}>;
  }>;
  onChange: (filters: IFilter[]) => void;
  initialFilters?: IFilter[];
}

interface IState {
  filters: IFilter[];
}

interface IFilter {
  column: string;
  operator: string;
  value?: string | {id: number} | Array<{id: number}>;
}

const Styled = createStyled({
  addFilter: {
    display: 'flex',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterRow: {
    marginBottom: '10px',
  },
});

export default class Filter extends React.Component<IProps, IState> {
  public state = {
    filters:
      this.props.initialFilters && this.props.initialFilters.length
        ? this.props.initialFilters
        : [{column: '', operator: ''}],
  };

  private getFilterKey = (index: number, filter: IFilter): string => {
    return `${index}-${filter.column}-${filter.operator}`;
  };

  public handleRowAdd = (): void => {
    const {filters} = this.state;
    const {onChange} = this.props;

    const newFilters = [...filters, {column: '', operator: ''}];

    this.setState({filters: newFilters});
    onChange(newFilters);
  };

  public handleRowRemove = (index: number) => (): void => {
    const {onChange} = this.props;
    const {filters} = this.state;

    const newFilters = [...filters];
    newFilters.splice(index, 1);

    // When there is one single filter and it is being removed
    // We do not want to remove the row, but just clear it
    this.setState({
      filters: index === 0 && filters.length === 1 ? [{column: '', operator: ''}] : newFilters,
    });
    onChange(newFilters);
  };

  public handleRowChange = (index: number) => (value: IFilter): void => {
    const {onChange} = this.props;
    const {filters} = this.state;

    const newFilters = [...filters];
    newFilters[index] = value;

    this.setState({filters: newFilters});
    onChange(newFilters);
  };

  public render() {
    const {columns} = this.props;
    const {filters} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <React.Fragment>
            {filters.map((filter, index) => (
              <div className={classes.filterRow} key={this.getFilterKey(index, filter)}>
                <FilterRow
                  columns={columns}
                  value={filter}
                  rowIndex={index}
                  onRowRemove={this.handleRowRemove}
                  onChange={this.handleRowChange(index)}
                />
              </div>
            ))}

            <div className={classes.btnContainer}>
              <IconButton
                className={classes.addFilter}
                onClick={this.handleRowAdd}
                aria-label="Add row"
                color="primary"
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </React.Fragment>
        )}
      </Styled>
    );
  }
}
