import * as React from 'react';
import {I18n} from 'react-i18next';
import Reorder, {reorder} from 'react-reorder';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ReorderIcon from '@material-ui/icons/Reorder';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {createStyled, WithWidth} from '../style';

type IProps<T> = {
  listName?: string;
  listGroupName?: string;
  onSave: (orderdList: T[]) => void;
  onCancel: () => void;
  list: T[];
};

type IState<T> = {
  list: T[];
  requestInFlight: boolean;
};

const Styled = createStyled(theme => ({
  container: {
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
  },

  icon: {
    float: 'left',
    marginRight: theme.spacing.unit,
    color: theme.palette.text.primary,
  },

  item: {
    cursor: 'grab',
    lineHeight: `${theme.spacing.unit * 3}px`,
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
    width: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    [theme.breakpoints.up('md')]: {
      width: '550px',
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    '&.dragging': {
      cursor: 'grabbing',
    },
  },

  placeholder: {
    margin: `${theme.spacing.unit}px 0`,
    display: 'block',
    height: '42px',
    background: theme.palette.grey[100],
  },

  noResult: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
  },
}));

export default class OrderListDialog<T> extends React.Component<IProps<T>, IState<T>> {
  public state = {
    list: this.props.list,
    requestInFlight: false,
  };

  public static defaultProps = {
    listName: 'list1',
    listGroupName: 'group1',
  };

  public getProperty(obj: {}, property: string) {
    return obj[property] ? obj[property] : '';
  }

  public handleOnOrder = () => (event: Event, previousIndex: number, nextIndex: number) => {
    const {list} = this.state;

    this.setState({
      list: reorder(list, previousIndex, nextIndex),
    });
  };

  public handleOnSave = () => {
    const {onSave} = this.props;
    const {list} = this.state;

    onSave(list);
  };

  public render() {
    const {list} = this.state;
    const {listName, listGroupName, onCancel} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <WithWidth>
            {({width}: {width: string}) => (
              <I18n>
                {t => (
                  <Dialog open={true} fullScreen={width === 'xs'}>
                    <DialogTitle>{t('report.actions.order_charts_title')}</DialogTitle>
                    <DialogContent>
                      <div className={classes.container}>
                        {!list.length ? (
                          <div className={classes.noResult}>
                            {t('report.actions.order_dialog_no_reports_text')}
                          </div>
                        ) : null}

                        <Reorder
                          reorderId={listName}
                          reorderGroup={listGroupName}
                          draggedClassName="dragging"
                          lock="horizontal"
                          component="ul"
                          onReorder={this.handleOnOrder()}
                          placeholder={<li className={classes.placeholder} />}
                        >
                          {list.map(item => (
                            <li className={classes.item} key={this.getProperty(item, 'id')}>
                              <ReorderIcon className={classes.icon} />{' '}
                              {this.getProperty(item, 'name')}
                            </li>
                          ))}
                        </Reorder>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onCancel} color="primary">
                        {list.length ? t('report.actions.cancel_bt') : t('report.actions.ok_bt')}
                      </Button>

                      {list.length && (
                        <Button onClick={this.handleOnSave} color="primary">
                          {t('report.actions.save_btn')}
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                )}
              </I18n>
            )}
          </WithWidth>
        )}
      </Styled>
    );
  }
}
