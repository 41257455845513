import * as React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {ButtonProps} from '@material-ui/core/Button';

import PageWrapper from '../components/PageWrapper';
import {createStyled} from '../style';
import imgSrc from '../images/not-found.svg';
import {ApiClient} from '../api';
import GoogleAnalytics from '../GoogleAnalytics';

const Styled = createStyled(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 2 * theme.spacing.unit,
  },
  image: {
    flex: 1,
    padding: 2 * theme.spacing.unit,
    minWidth: 20 * theme.spacing.unit,
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      maxWidth: 24 * theme.spacing.unit,
    },
  },
  content: {
    flex: 1.61,
    padding: 2 * theme.spacing.unit,
    minWidth: 32 * theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
}));

export default function NotFound(
  props: RouteComponentProps<any> & {api: ApiClient; ga: GoogleAnalytics}
) {
  return props.api.isFullscreenMode() ? (
    contentRenderer()
  ) : (
    <PageWrapper title="Not Found">{contentRenderer()}</PageWrapper>
  );
}

function contentRenderer() {
  return (
    <Styled>
      {({classes}) => (
        <div className={classes.root}>
          <div className={classes.image}>
            <img src={imgSrc} />
          </div>
          <div className={classes.content}>
            <Typography variant="h6" gutterBottom={true}>
              Uh-oh!
            </Typography>
            <Typography gutterBottom={true}>
              We couldn't find this page. If you got here by clicking a link, please let us know.
            </Typography>
            <div>
              <Button
                component={DashboardLink}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Dashboard
              </Button>
              <Button
                href="mailto:service@connectedmolecules.com"
                variant="contained"
                className={classes.button}
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>
      )}
    </Styled>
  );
}

function DashboardLink({innerRef, ...props}: ButtonProps) {
  return <Link {...props} to="/" />;
}
