import * as React from 'react';

import TimeFrameSelector, {generateTimeframeData} from '../../TimeFrameSelector';
import {TimeFrame} from '../ChartDialog';

interface IProps {
  onChange: (value: TimeFrame[]) => void;
  initialValue: TimeFrame[];
  disableComparison?: boolean;
}

interface IState {
  timeFrameCount: number;
}

export default class TimeframeStep extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {initialValue} = props;

    this.state = {
      timeFrameCount: initialValue && initialValue.length ? initialValue.length : 1,
    };
  }

  public onDateChange = (values: {
    periodType: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    trailing: number | undefined;
    withComparison: boolean;
    periodTypePeriod2: string | undefined;
    startDatePeriod2: Date | undefined;
    endDatePeriod2: Date | undefined;
    trailingPeriod2: number | undefined;
    referenceDatePeriod2?: string;
  }) => {
    const {onChange} = this.props;
    const {
      periodType,
      startDate,
      endDate,
      trailing,
      withComparison,
      periodTypePeriod2,
      startDatePeriod2,
      endDatePeriod2,
      trailingPeriod2,
      referenceDatePeriod2,
    } = values;
    const timeFrames: TimeFrame[] = [];

    timeFrames.push(
      generateTimeframeData(periodType || 'fixed date', {
        startDate,
        endDate,
        trailing,
      })
    );

    if (withComparison) {
      timeFrames.push(
        generateTimeframeData(periodTypePeriod2 || 'fixed date', {
          startDate: startDatePeriod2,
          endDate: endDatePeriod2,
          trailing: trailingPeriod2,
          period1Type: periodType,
          isPeriod2: true,
          referenceDatePeriod2,
        })
      );
    }

    onChange(timeFrames);
  };

  public render() {
    const {initialValue, disableComparison} = this.props;

    return (
      <TimeFrameSelector
        onChange={this.onDateChange}
        timeFrames={initialValue}
        disableComparison={Boolean(disableComparison)}
      />
    );
  }
}
