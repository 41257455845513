import * as React from 'react';
import {I18n} from 'react-i18next';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';

import {createStyled} from '../../style/index';
import {IChartTemplate} from '../../api';

const Styled = createStyled(theme => ({
  dialogContent: {
    minWidth: theme.spacing.unit * 50,
    textAlign: 'center',
  },
  chartTypeList: {
    textAlign: 'left',
    marginTop: theme.spacing.unit * 4,
    marginBottom: 0,
    columns: 2,
    '-webkit-columns': 2,
    '-moz-columns': 2,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: 1.56,
  },
  textDivider: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: 25,
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#a3a6a8',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  textDividerHr: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',
    margin: 0,
  },
  textDividerLabel: {
    position: 'absolute',
    background: 'white',
    left: '50%',
    top: '50%',
    paddingLeft: 10,
    paddingRight: 10,
    '-ms-transform': 'translateX(-50%) translateY(-50%)',
    transform: 'translateX(-50%) translateY(-50%)',
  },
}));

interface IProps {
  dialogOpen: boolean;
  chartTemplates: IChartTemplate[];
  onOk: (chartTemplate?: IChartTemplate) => void;
  onCancel: () => void;
}

interface IState {
  useTemplate: boolean;
  selectedTemplate: IChartTemplate | null;
}

const initialState: IState = {
  useTemplate: false,
  selectedTemplate: null,
};

export default class AddChartDialog extends React.Component<IProps, IState> {
  public state = {...initialState};

  public resetState() {
    this.setState({...initialState});
  }

  public handleTemplateSelection = (chartTemplate: IChartTemplate) => {
    this.props.onOk(chartTemplate);
  };

  public handleCustomChartClick = () => {
    this.props.onOk();
  };

  public handleCancel = async () => {
    this.resetState();
    await this.props.onCancel();
  };

  public render() {
    const {dialogOpen, chartTemplates} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <Dialog
                aria-labelledby="create-report-title"
                open={dialogOpen}
                scroll="paper"
                onClose={this.handleCancel}
              >
                {/* It has chart templates to load */}
                {chartTemplates.length > 0 && (
                  <React.Fragment>
                    {/* Dialog title */}
                    <DialogTitle id="load-report-template-title">
                      {t('create_chart_dialog.title')}
                    </DialogTitle>
                    {/* Dialog content */}
                    <DialogContent className={classes.dialogContent}>
                      {/* Sybtitle */}
                      <Typography variant="subtitle1" align="center">
                        {t('chart_dialog.steps.create_from_template_text')}
                      </Typography>
                      {/* Template list */}
                      <ul className={classes.chartTypeList}>
                        {chartTemplates.map(template => (
                          <li
                            key={template.id}
                            onClick={this.handleTemplateSelection.bind(this, template)}
                          >
                            <a>{template.name}</a>
                          </li>
                        ))}
                      </ul>
                      {/* Divider */}
                      <div className={classes.textDivider}>
                        <hr className={classes.textDividerHr} />
                        <span className={classes.textDividerLabel}> OR </span>
                      </div>
                      {/* Create Custom Chart Button */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleCustomChartClick}
                      >
                        {t('create_chart_dialog.create_from_scratch_bt')}
                      </Button>
                    </DialogContent>
                    {/* Dialog actions */}
                    <DialogActions>
                      <Button color="primary" onClick={this.handleCancel}>
                        {t('create_chart_dialog.cancel_bt')}
                      </Button>
                    </DialogActions>
                  </React.Fragment>
                )}
              </Dialog>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
