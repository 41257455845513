import * as React from 'react';
import {I18n} from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import {createStyled} from '../../../style/index';
import {ChartType} from '../../../api';

interface IProps {
  onChange: (value: string) => void;
  initialValue?: {name: string; type: ChartType};
}

const Styled = createStyled(theme => ({
  formControl: {
    minWidth: theme.spacing.unit * 13,
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing.unit * 1.5,
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'wrap',
      justifyContent: 'center',
    },
  },
}));

export default class NameStep extends React.Component<IProps> {
  public handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {onChange} = this.props;

    onChange(event.target.value);
  };

  public render() {
    const {initialValue} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <div className={classes.container}>
                <div className={classes.row}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label={t('chart_dialog.steps.name.report_name_label')}
                      value={initialValue ? initialValue.name : ''}
                      className={classes.formControl}
                      onChange={this.handleNameChange}
                    />
                  </FormControl>
                </div>
              </div>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
