import polyfill from './polyfill';

polyfill().then(() => {
  const {renderApp} = require('./renderApp');

  renderApp();

  if (module.hot) {
    module.hot.accept('./App', renderApp);
  }
});
