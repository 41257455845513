import {i18n} from '../i18n';
import {IColumn} from './';

export default function logType(value: number | string, column: IColumn): string | number {
  if (column.function && column.function !== 'select') {
    return value;
  }

  return Number(value) === 1 ? i18n.t('filter.enum.occurred') : i18n.t('filter.enum.resolved');
}
