import {ISelect} from '../components/ChartDialog/ChartDialog';
import {isImmutableFunction} from '../components/ChartDialog/properties';
import {ChartType, ChartView} from '../api';

export interface ITreeListItem {
  isGroup: boolean;
  isCollapsed?: boolean;
  columnName: string;
  value: string | number;
  children: any[];
}

export interface IFlatTreeListItem {
  isGroup: boolean;
  isCollapsed?: boolean;
  refItem?: {isCollapsed?: boolean};
}

export function orderColumns(columns: ISelect[]) {
  const groupedColumns: ISelect[] = [];
  const otherColumns: ISelect[] = [];

  columns.forEach(col => {
    if (isGroupColumn(col)) {
      groupedColumns.push(col);
    } else {
      otherColumns.push(col);
    }
  });

  return groupedColumns.concat(otherColumns);
}

export function createTreeList(columns: ISelect[], rows: Array<{}>) {
  const groupColumns: ISelect[] = [];
  const functionColumns: ISelect[] = [];

  columns.forEach(column => {
    if (isGroupColumn(column)) {
      groupColumns.push(column);
    } else {
      functionColumns.push(column);
    }
  });

  return groupItems(groupColumns.concat(functionColumns), functionColumns, 0, rows);
}

export function flattenTreeList(treeList: ITreeListItem[]): IFlatTreeListItem[] {
  let list: IFlatTreeListItem[] = [];

  treeList.forEach(item => {
    if (item.isGroup) {
      const listItem = {
        isGroup: true && item.children.length > 0,
        isCollapsed: item.isCollapsed,
        [item.columnName]: item.value,
        refItem: item,
        ...item,
      };

      list.push(listItem);
      if (!item.isCollapsed) {
        list = list.concat(flattenTreeList(item.children));
      }
    } else {
      list = list.concat(item.children);
    }
  });

  return list;
}

// it will process all the group columns one by one
function groupItems(
  columns: ISelect[],
  functionColumns: ISelect[],
  index: number,
  rows: Array<{}>
): ITreeListItem[] {
  if (index >= columns.length) {
    return [];
  }

  const groupedItems: ITreeListItem[] = [];
  const column: ISelect = columns[index];
  const nextColumn: ISelect = columns[index + 1];
  const isGroupCol = nextColumn && isGroupColumn(nextColumn);
  const groups = {};
  const columnName = getColumnName(column);
  const prevColumns = columns.slice(0, index);

  rows.forEach(row => {
    const cellValue = columnName in row ? row[columnName] : '-';
    let item = groups[cellValue];
    const child = {...row};

    // insert the item (only once)
    if (!item) {
      item = {
        isGroup: isGroupCol,
        children: [],
      };

      // initialize the function totals
      functionColumns.forEach(funcCol => {
        item[getColumnName(funcCol)] = 0;
      });

      if (isGroupCol) {
        item.isCollapsed = true;
        item.columnName = columnName;
        item.value = cellValue;
      }

      groupedItems.push(item);
      groups[cellValue] = item;
    }

    // clean child
    if (isGroupCol) {
      child[columnName] = null;
    } else {
      prevColumns.forEach(prevCol => {
        const prevColName = getColumnName(prevCol);
        delete child[prevColName];
      });
    }

    // insert child
    item.children.push(child);
  });

  if (isGroupCol) {
    groupedItems.forEach(groupedItem => {
      groupedItem.children = groupItems(columns, functionColumns, index + 1, groupedItem.children);
    });
  }

  // sum the totals
  groupedItems.forEach(groupedItem => {
    groupedItem.children.forEach((child, childIndex) => {
      functionColumns.forEach(funcCol => {
        const colName = getColumnName(funcCol);

        if (funcCol.function === 'avg') {
          groupedItem[colName] =
            (groupedItem[colName] * childIndex + child[colName]) / (childIndex + 1);
        } else if (funcCol.function === 'min') {
          groupedItem[colName] = Math.min(groupedItem[colName], child[colName]);
        } else if (funcCol.function === 'max') {
          groupedItem[colName] = Math.max(groupedItem[colName], child[colName]);
        } else {
          groupedItem[colName] += child[colName];
        }
      });
    });
  });

  return groupedItems;
}

export function isGroupColumn(column: ISelect): boolean {
  return !('function' in column) || isImmutableFunction(column.function);
}

export function getColumnName(column: ISelect) {
  return (column.label || column.alias || column.column).toLowerCase();
}

export function formatChartWhereCriteria(
  whereCriteria: Array<{
    column: string;
    operator: string;
    value: string | number | number[];
  }>
): Array<{
  column: string;
  operator: string;
  value?: string | {id: number} | Array<{id: number; name?: string}>;
}> {
  return whereCriteria.map(where => {
    const newWhere: {
      column: string;
      operator: string;
      value?: string | {id: number} | Array<{id: number; name?: string}>;
    } = {column: where.column, operator: where.operator};

    if (typeof where.value === 'string') {
      newWhere.value = where.value;
    }

    if (typeof where.value === 'number') {
      newWhere.value = {id: where.value};
    }

    if (Array.isArray(where.value)) {
      newWhere.value = where.value.map((id: number) => ({id}));
    }

    return newWhere;
  });
}

export function isTableViewChart(type: ChartType, view: ChartView): boolean {
  const tableDefaultCharts = [
    'offline_machines',
    'machine_cleaning',
    'machine_alarms',
    'machine_issues',
  ];

  if (tableDefaultCharts.indexOf(type) > -1) {
    return true;
  }

  if (type === 'custom' && view === 'table') {
    return true;
  }

  return false;
}
