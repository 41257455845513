import * as React from 'react';
import {I18n} from 'react-i18next';
import {Subscribe} from 'unstated';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import {createStyled, theme as mainTheme} from '../../style/index';
import {formatChartWhereCriteria} from '../../lib/chartUtils';
import ChartDialog from '../ChartDialog';
import {IChartCriteria, IChartTemplate, IChartData, DefaultChartType} from '../../api';
import Notification from '../Notification';
import {ApiClient, getChartTemplate, ChartType, ChartView} from '../../api';
import AddChartDialog from './AddChartDialog';
import DefaultChartDialog from '../ChartDialog/DefaultChartDialog';
import ConfirmDialog from '../ConfirmDialog';
import GoogleAnalytics from '../../GoogleAnalytics';

const Styled = createStyled(() => ({
  emphasizedButton: {
    color: 'white',
  },
  templateIcon: {
    backgroundColor: mainTheme.palette.primary.main,
    color: 'white',
  },
}));

interface IProps {
  onSave: (
    data: {name: string; type: ChartType; view: ChartView; criteria: IChartCriteria}
  ) => void;
  onDefaultChartSave: (data: {type: DefaultChartType; name?: string}) => void;
  isDefaultReport: boolean;
  emphasize?: boolean;
  variant?: 'contained' | 'fab';
  className?: string;
  ga: GoogleAnalytics;
}

interface IWeakChartCriteria extends IChartCriteria {
  where: any;
}

interface IChartDefaultValues extends IWeakChartCriteria {
  type: ChartType;
  view: ChartView;
  name: string;
  where: Array<{
    column: string;
    operator: string;
    value?: string | {id: number} | Array<{id: number; name?: string}>;
  }>;
}
interface IState {
  dialogAddChartOpen: boolean;
  dialogAddDefaultChartOpen: boolean;
  dialogChartConfigOpen: boolean;
  hasError: boolean;
  chartTemplates: IChartTemplate[];
  chartConfig?: IChartDefaultValues;
  cancelConfirmationDialogOpen: boolean;
}

export default class AddChartBt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      dialogAddChartOpen: false,
      dialogAddDefaultChartOpen: false,
      dialogChartConfigOpen: false,
      hasError: false,
      chartTemplates: [],
      cancelConfirmationDialogOpen: false,
    };
  }

  public handleSubmit = async (chart: IChartData) => {
    try {
      this.setState({dialogChartConfigOpen: false});
      await this.props.onSave(chart);
    } catch (e) {
      this.setState({hasError: true});
    }
  };

  public handleDefaultChartSubmit = async (type: DefaultChartType) => {
    try {
      this.setState({dialogAddDefaultChartOpen: false});
      await this.props.onDefaultChartSave({type});
    } catch (e) {
      this.setState({hasError: true});
    }
  };

  public handleOk = (chartTemplate?: IChartTemplate): void => {
    if (chartTemplate) {
      const chartConfig = {
        name: chartTemplate.name,
        type: chartTemplate.type,
        view: chartTemplate.view,
        ...chartTemplate.criteria,
        where: formatChartWhereCriteria(chartTemplate.criteria.where),
      };
      this.setState({chartConfig, dialogAddChartOpen: false, dialogChartConfigOpen: true});
    } else {
      this.setState({
        chartConfig: undefined,
        dialogAddChartOpen: false,
        dialogChartConfigOpen: true,
      });
    }
  };

  public handleCancel = (): void => {
    this.setState({dialogAddChartOpen: false, dialogAddDefaultChartOpen: false});
  };

  public handleChartConfigCancel = (): void => {
    this.setState({cancelConfirmationDialogOpen: true});
  };

  public handleCancelOk = (): void => {
    this.setState({cancelConfirmationDialogOpen: false, dialogChartConfigOpen: false});
  };

  public handleCancelConfirmation = (): void => {
    this.setState({cancelConfirmationDialogOpen: false});
  };

  public openAddChartDialog = async (api: ApiClient) => {
    const {isDefaultReport, ga} = this.props;
    const sortedChartTemplates = (await getChartTemplate(api)).sort(
      (a, b) => (a.name > b.name ? 1 : -1)
    );

    ga.trackEvent({
      category: 'New Reports',
      action: 'Add Report',
      label: isDefaultReport ? 'Default' : 'Custom',
    });

    if (isDefaultReport) {
      this.setState({chartTemplates: sortedChartTemplates, dialogAddDefaultChartOpen: true});
    } else {
      this.setState({chartTemplates: sortedChartTemplates, dialogAddChartOpen: true});
    }
  };

  public closeNotification = (): void => {
    this.setState({hasError: false});
  };

  public render() {
    const {
      hasError,
      dialogAddChartOpen,
      dialogAddDefaultChartOpen,
      chartTemplates,
      dialogChartConfigOpen,
      chartConfig,
      cancelConfirmationDialogOpen,
    } = this.state;
    const {emphasize, className, variant, isDefaultReport} = this.props;

    return (
      <Subscribe to={[ApiClient]}>
        {(api: ApiClient) => (
          <Styled>
            {({classes}) => (
              <I18n>
                {t => (
                  <React.Fragment>
                    {hasError ? (
                      <Notification
                        message={t('chart.something_went_wrong')}
                        type="error"
                        onClose={this.closeNotification}
                      />
                    ) : null}

                    {/* Show add chart normal button */}
                    {variant === 'contained' && (
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        aria-label="Add"
                        className={
                          emphasize ? `${className} ${classes.emphasizedButton}` : className
                        }
                        onClick={this.openAddChartDialog.bind(this, api)}
                      >
                        <AddIcon />
                        {isDefaultReport
                          ? t('report.actions.add_chart_bt')
                          : t('report.actions.add_custom_chart_bt')}
                      </Button>
                    )}

                    {/* Show add chart fab button */}
                    {variant === 'fab' && (
                      <Tooltip title={t('report.actions.add_chart_bt')}>
                        <Fab
                          size="medium"
                          color="primary"
                          aria-label="Add"
                          className={
                            emphasize ? `${className} ${classes.emphasizedButton}` : className
                          }
                          onClick={this.openAddChartDialog.bind(this, api)}
                        >
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    )}

                    {/* Show dialog weather to create a chart from a template or not */}
                    {dialogAddChartOpen && (
                      <AddChartDialog
                        dialogOpen={dialogAddChartOpen}
                        chartTemplates={chartTemplates}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                      />
                    )}

                    {dialogAddDefaultChartOpen ? (
                      <DefaultChartDialog
                        onSubmit={this.handleDefaultChartSubmit}
                        onCancel={this.handleCancel}
                        showDialog={dialogAddDefaultChartOpen}
                      />
                    ) : null}

                    {dialogChartConfigOpen ? (
                      <ChartDialog
                        onSubmit={this.handleSubmit}
                        onCancel={this.handleChartConfigCancel}
                        initialValue={chartConfig ? chartConfig : undefined}
                        initialStep={chartConfig ? 2 : undefined}
                        isAdmin={api.isAdmin()}
                      />
                    ) : null}

                    <ConfirmDialog
                      title={t('chart.actions.config_cancel_confirm_title')}
                      text={t('chart.actions.config_cancel_confirm_text')}
                      open={cancelConfirmationDialogOpen}
                      onOkClick={this.handleCancelOk}
                      onCancelClick={this.handleCancelConfirmation}
                    />
                  </React.Fragment>
                )}
              </I18n>
            )}
          </Styled>
        )}
      </Subscribe>
    );
  }
}
