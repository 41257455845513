import * as React from 'react';
import {I18n} from 'react-i18next';

import {createStyled} from '../../style/index';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

interface IProps {
  isDisabled?: boolean;
  columnType?: string | null;
  initialValue?: string;
  onChange: (value: string) => void;
}

const operators = {
  enum: ['equals', 'not equals'],
  boolean: ['equals'],
  string: [
    'contains',
    'does not contain',
    'equals',
    'not equals',
    'begins with',
    'in',
    'ends with',
  ],
  number: [
    'equals',
    'not equals',
    // TODO: Implement between operator
    // 'between',
    'greater than',
    'less than',
    'less or equal than',
    'greater or equal than',
  ],
  resource: ['equals', 'not equals', 'in'], // `not in` is the same as `not equals`
};

const operatorsMapping = {
  'does not contain': 'notContains',
  'not equals': 'not',
  'begins with': 'startsWith',
  'ends with': 'endsWith',
  'greater than': 'greaterThan',
  'less than': 'lessThan',
  'less or equal than': 'lessThanOrEqual',
  'greater or equal than': 'greaterThanOrEqual',
};

const Styled = createStyled({
  formControl: {
    flexBasis: '100%',
    minWidth: '100px',
  },
});

export default class Operator extends React.Component<IProps, {}> {
  public getOperators(): string[] {
    const {columnType} = this.props;

    return operators[columnType || 'string'] || [];
  }

  public handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const {onChange} = this.props;

    onChange(event.target.value);
  };

  public render() {
    const {initialValue, isDisabled} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="operator">{t('filter.operator_label')}</InputLabel>
                <Select
                  id="operator"
                  disabled={isDisabled}
                  value={initialValue}
                  onChange={this.handleOnChange}
                >
                  {this.getOperators().map(operator => (
                    <MenuItem
                      key={operator}
                      value={operatorsMapping[operator] ? operatorsMapping[operator] : operator}
                    >
                      {t(`filter.operator.${operator}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
