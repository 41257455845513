import {
  time,
  price,
  logType,
  weekDay,
  dayHour,
  month,
  quarter,
  toDate,
  translateEnum,
  Formatter,
} from '../../formatters';
import {i18n} from '../../i18n';
import {IColumn, ISelect, Table} from './ChartDialog';
import {ChartType} from '../../api';

export type Resource =
  | 'machine'
  | 'location'
  | 'errorIdentity'
  | 'currency'
  | 'machinegroup'
  | 'locationgroup'
  | 'manufacturer'
  | 'machinemodel'
  | 'product'
  | 'machinetype'
  | 'debtor';

export const immutableFunctions = {
  date_part: 'date_part',
  date_trunc: 'date_trunc',
};

/**
 * Extended properties for the available database columns. See {@link IColumn}.
 */
interface IProperty extends IColumn {
  resource?: Resource;
  adminOnly?: boolean;
  formatters?: Formatter[];
  options?: any[];
}

/**
 * User-selectable database columns and computed columns (such as "day of week").
 *
 * The `column` values refer to {@link properties}, and the actual list as shown to the user will be
 * filtered by the columns that are actually available for some given resource. The optional `name`
 * attribute is used for translations (see {@link translateProperty}) and when matching defaults
 * from {@link defaultDimensionsAndMetrics} when a user selects some specific resource.
 */
export interface IDimension {
  name?: string;
  column: string;
}

/**
 * User-selectable metrics (aggregate functions).
 */
export interface IMetric extends IDimension {
  // Name is not optional in a metric
  name: string;
  function: string;
  functionParams?: string[];
  formatters?: Formatter[];
  joinType?: 'left' | 'right';
}

const machineProperties: IProperty[] = [
  {name: 'machine.id', column: 'machine', resource: 'machine'},
  {
    column: 'machine.active',
    type: 'boolean',
  },
  {
    column: 'machinegroup',
    resource: 'machinegroup' as Resource,
  },
  {column: 'machine.name'},
  {column: 'machine.tag'},
  {column: 'machine.virtualPrices', type: 'boolean'},
  {column: 'machine.notes', adminOnly: true},
];

const locationProperties = (tableName?: string): IProperty[] => [
  {
    name: 'location.id',
    column: tableName ? `${tableName}.location` : 'location',
    resource: 'location' as Resource,
  },
  {column: 'location.address'},
  {column: 'location.city'},
  {column: 'location.country'},
  {
    column: 'locationgroup',
    resource: 'locationgroup' as Resource,
  },
  {column: 'location.name'},
  {column: 'location.postcode'},
];

const machineTypeProperties: IProperty[] = [
  {name: 'machinetype.id', column: 'machine.machineType', resource: 'machinetype' as Resource},
  {column: 'machine.machinetype.name'},
];

const manufacturerTypeProperties: IProperty[] = [
  {name: 'manufacturer.id', column: 'machine.manufacturer', resource: 'manufacturer' as Resource},
  {column: 'machine.manufacturer.name'},
];

const machineModelTypeProperties: IProperty[] = [
  {name: 'machinemodel.id', column: 'machine.machineModel', resource: 'machinemodel' as Resource},
  {column: 'machine.machinemodel.name'},
];

const gatewayProperties: IProperty[] = [
  {name: 'machine.hostname', column: 'machine.gateway.hostname'},
  {
    name: 'machine.networkStatus',
    column: 'machine.gateway.networkStatus',
    type: 'enum',
    options: ['offline', 'online'],
  },
];

const machineDebtorProperties: IProperty[] = [
  {name: 'debtor.id', column: 'machine.debtor', resource: 'debtor' as Resource, adminOnly: true},
  {column: 'machine.debtor.externalId', adminOnly: true},
  {column: 'machine.debtor.name', adminOnly: true},
  {column: 'machine.debtor.description', adminOnly: true},
];

const alertLocationProperties: IProperty[] = [
  {
    name: 'machine.location.id',
    column: 'alert.machine.location',
    resource: 'location' as Resource,
  },
  {column: 'machine.location.name'},
  {column: 'machine.location.city'},
  {column: 'machine.location.country'},
];

const logStatusLocationProperties: IProperty[] = [
  {
    name: 'machine.location.id',
    column: 'logstatus.machine.location',
    resource: 'location' as Resource,
  },
  {column: 'machine.location.name'},
  {column: 'machine.location.city'},
  {column: 'machine.location.country'},
];

const dateProperties = ({column}: {column: string} = {column: 'occurredAt'}): IProperty[] => [
  {
    name: 'dayHour',
    column,
    type: 'number',
    function: immutableFunctions.date_part,
    functionParams: ['hour'],
    formatters: [dayHour],
  },
  {
    name: 'weekDay',
    column,
    function: immutableFunctions.date_part,
    functionParams: ['dayofweek'],
    formatters: [weekDay],
    type: 'enum',
    options: [
      {name: i18n.t('formatter.week_day.0'), value: '0'},
      {name: i18n.t('formatter.week_day.1'), value: '1'},
      {name: i18n.t('formatter.week_day.2'), value: '2'},
      {name: i18n.t('formatter.week_day.3'), value: '3'},
      {name: i18n.t('formatter.week_day.4'), value: '4'},
      {name: i18n.t('formatter.week_day.5'), value: '5'},
      {name: i18n.t('formatter.week_day.6'), value: '6'},
    ],
  },
  {
    name: 'monthDay',
    column,
    type: 'number',
    function: immutableFunctions.date_part,
    functionParams: ['day'],
  },
  {
    name: 'month',
    column,
    function: immutableFunctions.date_part,
    functionParams: ['month'],
    formatters: [month],
    type: 'enum',
    options: [
      {name: i18n.t('formatter.month.1'), value: '1'},
      {name: i18n.t('formatter.month.2'), value: '2'},
      {name: i18n.t('formatter.month.3'), value: '3'},
      {name: i18n.t('formatter.month.4'), value: '4'},
      {name: i18n.t('formatter.month.5'), value: '5'},
      {name: i18n.t('formatter.month.6'), value: '6'},
      {name: i18n.t('formatter.month.7'), value: '7'},
      {name: i18n.t('formatter.month.8'), value: '8'},
      {name: i18n.t('formatter.month.9'), value: '9'},
      {name: i18n.t('formatter.month.10'), value: '10'},
      {name: i18n.t('formatter.month.11'), value: '11'},
      {name: i18n.t('formatter.month.12'), value: '12'},
    ],
  },
  {
    name: 'quarter',
    column,
    function: immutableFunctions.date_part,
    functionParams: ['quarter'],
    formatters: [quarter],
    type: 'enum',
    options: [
      {name: i18n.t('formatter.quarter.1'), value: '1'},
      {name: i18n.t('formatter.quarter.2'), value: '2'},
      {name: i18n.t('formatter.quarter.3'), value: '3'},
      {name: i18n.t('formatter.quarter.4'), value: '4'},
    ],
  },
  {
    name: 'year',
    column,
    type: 'number',
    function: immutableFunctions.date_part,
    functionParams: ['year'],
  },
  {
    name: 'timelineDay',
    column,
    function: immutableFunctions.date_trunc,
    functionParams: ['day'],
    formatters: [toDate],
  },
  {
    name: 'timelineWeek',
    column,
    function: immutableFunctions.date_trunc,
    functionParams: ['week'],
    formatters: [toDate],
  },
  {
    name: 'timelineMonth',
    column,
    function: immutableFunctions.date_trunc,
    functionParams: ['month'],
    formatters: [toDate],
  },
  {
    name: 'timelineYear',
    column,
    function: immutableFunctions.date_trunc,
    functionParams: ['year'],
    formatters: [toDate],
  },
];

/**
 * All user-selectable properties for all known virtual report {@link Table}s.
 *
 * The id columns are required for metrics that do a count.
 */
export const properties: {[key in Table]: IProperty[]} = {
  log: [
    {column: 'log.id'},
    {
      column: 'errorIdentity.error',
    },
    {column: 'log.code'},
    {column: 'log.duration', type: 'number', formatters: [time]},
    {
      name: 'log.type',
      column: 'log.type',
      formatters: [logType],
      type: 'enum',
      options: [
        {name: i18n.t('filter.enum.occurred'), value: '1'},
        {name: i18n.t('filter.enum.resolved'), value: '2'},
      ],
    },
    ...locationProperties(),
    ...machineProperties,
    ...machineTypeProperties,
    ...manufacturerTypeProperties,
    ...machineModelTypeProperties,
    ...gatewayProperties,
    ...machineDebtorProperties,
    ...dateProperties(),
  ],

  // Revenue transaction without much additional details. Instead of this, a user probably wants
  // to use `paymenttransaction` as defined below, though that might show a slightly faulty total
  // amount (when the sum of the payments does not add up to the product price), and cannot get
  // an average sales price.
  //
  // Since August 2019, this resource cannot be used for new reports.
  revenuetransaction: [
    {column: 'revenuetransaction.id'},
    {column: 'revenuetransaction.barcode'},
    {column: 'revenuetransaction.currency'},
    // The following are displayed as "product name", "product price" and "product PLU"
    {column: 'revenuetransaction.name'},
    {
      column: 'revenuetransaction.price',
      type: 'number',
      formatters: [price],
    },
    {column: 'revenuetransaction.product', type: 'number'},
    ...locationProperties(),
    ...machineProperties,
    ...gatewayProperties,
    ...machineDebtorProperties,
    ...dateProperties(),
  ],

  // Payment transaction, right-joined with its parent revenue transaction. (In a database, one can
  // achieve the same using `revenuetransaction left join paymenttransaction`, but the August 2019
  // version of the API does not fully support that, hence the right join.)
  paymenttransaction: [
    {column: 'paymenttransaction.id'},
    {column: 'paymenttransaction.currency'},
    {
      column: 'paymenttransaction.type',
      type: 'enum',
      formatters: [translateEnum],
      options: [
        {name: i18n.t('filter.enum.cash'), value: 'cash'},
        {name: i18n.t('filter.enum.card'), value: 'card'},
        {name: i18n.t('filter.enum.code'), value: 'code'},
        {name: i18n.t('filter.enum.virtual'), value: 'virtual'},
        {name: i18n.t('filter.enum.token'), value: 'token'},
      ],
    },
    {
      column: 'paymenttransaction.price',
      type: 'number',
      formatters: [price],
    },
    {
      column: 'revenuetransaction.price',
      type: 'number',
      formatters: [price],
      joinType: 'right',
    },
    // `priceCash`, `priceCard` and all are computed columns in the API's ChartService
    {
      column: 'paymenttransaction.priceCash',
      type: 'number',
      formatters: [price],
    },
    {
      column: 'paymenttransaction.priceCard',
      type: 'number',
      formatters: [price],
    },
    {
      column: 'paymenttransaction.priceCode',
      type: 'number',
      formatters: [price],
    },
    {
      column: 'paymenttransaction.priceVirtual',
      type: 'number',
      formatters: [price],
    },
    {
      column: 'paymenttransaction.priceToken',
      type: 'number',
      formatters: [price],
    },
    {
      column: 'paymenttransaction.priceOther',
      type: 'number',
      formatters: [price],
    },
    {column: 'revenuetransaction.id', joinType: 'right'},
    {column: 'revenuetransaction.token', joinType: 'right'},
    {column: 'revenuetransaction.barcode', joinType: 'right'},
    {column: 'revenuetransaction.name', joinType: 'right'},
    {column: 'revenuetransaction.product', type: 'number', joinType: 'right'},
    {column: 'paymenttransaction.reportGroup'},
    ...locationProperties('revenuetransaction'),
    ...machineProperties,
    ...machineTypeProperties,
    ...manufacturerTypeProperties,
    ...machineModelTypeProperties,
    ...gatewayProperties,
    ...machineDebtorProperties,
    ...dateProperties({column: 'occurredAt'}),
  ],

  alert: [
    {
      column: 'alert.id',
    },
    {
      column: 'alert.name',
    },
    {column: 'alert.observer'},
    {column: 'alert.type'},
    {column: 'alert.code'},
    {
      column: 'alert.paymentMethod',
      type: 'enum',
      options: [
        {name: i18n.t('filter.enum.cash'), value: 'cash'},
        {name: i18n.t('filter.enum.card'), value: 'card'},
        {name: i18n.t('filter.enum.code'), value: 'code'},
        {name: i18n.t('filter.enum.virtual'), value: 'virtual'},
        {name: i18n.t('filter.enum.token'), value: 'token'},
      ],
    },
    {column: 'alert.openingHours', type: 'boolean'},
    // August 2019: AlertGroup and AlertObserver are not in Redshift, hence no details from those
    ...machineProperties,
    ...alertLocationProperties,
    ...dateProperties({column: 'createdAt'}),
  ],

  logstatus: [
    {
      column: 'logstatus.id',
    },
    {
      column: 'logstatus.code',
    },
    {
      column: 'logstatus.status',
      type: 'enum',
      options: [
        {name: i18n.t('filter.enum.occurred'), value: 'occurred'},
        {name: i18n.t('filter.enum.resolved'), value: 'resolved'},
        {name: i18n.t('filter.enum.unknown'), value: 'unknown'},
      ],
    },
    {
      column: 'logstatus.duration',
      type: 'number',
      formatters: [time],
    },
    ...machineProperties,
    ...logStatusLocationProperties,
    ...dateProperties({column: 'updatedAt'}),
  ],
};

/**
 * Grouped dimensions. See {@link IDimension}.
 *
 * As, e.g., part of the revenue transaction details are categorized as "Product", this cannot
 * simply use the column name prefixes (table names), but uses an explicit mapping.
 *
 * The group keys, such as `alerts` and `logs`, are only used to get a group name (so do not refer
 * to `properties`, and are not used for any filtering given the selected resource).
 */
export const dimensions: {[key: string]: IDimension[]} = {
  alerts: [
    {column: 'alert.name'},
    {column: 'alert.type'},
    {column: 'alert.observer'},
    {column: 'alert.code'},
    {column: 'alert.paymentMethod'},
    {column: 'alert.openingHours'},
  ],
  // Shown as "Machine events"
  logs: [{column: 'log.type'}, {column: 'log.code'}, {column: 'errorIdentity.error'}],
  // Shown as "Current machine resolutions"
  logstatuses: [
    {column: 'logstatus.code'},
    {column: 'logstatus.status'},
    {column: 'logstatus.duration'},
  ],
  paymenttransactions: [
    // `paymenttransaction.type` is excluded by design; users should select a metric instead. Same
    // goes for the payment amounts, like `price` (to be summed for the total), and `priceCash` and
    // similar (computed columns for specific payment types).
    {column: 'paymenttransaction.currency'},
    {column: 'paymenttransaction.reportGroup'},
    {column: 'paymenttransaction.type'},
  ],
  products: [
    {column: 'revenuetransaction.name'},
    {column: 'revenuetransaction.product'},
    // Currency is taken from the payment instead
    {column: 'revenuetransaction.price'},
    {column: 'revenuetransaction.barcode'},
  ],
  locations: [
    {column: 'location.address'},
    {column: 'location.city'},
    {column: 'location.country'},
    {column: 'location.name'},
    {column: 'location.postcode'},
  ],
  machines: [
    {column: 'machine.active'},
    {column: 'machine.name'},
    {column: 'machine.tag'},
    {column: 'machine.virtualPrices'},
    {column: 'machine.notes'},
    // Gateway
    {name: 'machine.hostname', column: 'machine.gateway.hostname'},
    {
      name: 'machine.networkStatus',
      column: 'machine.gateway.networkStatus',
    },
    {column: 'machine.manufacturer.name'},
    {column: 'machine.machinetype.name'},
    {column: 'machine.machinemodel.name'},
  ],
  debtors: [
    {column: 'machine.debtor.externalId'},
    {column: 'machine.debtor.name'},
    {column: 'machine.debtor.description'},
  ],
  times: [
    // These are not prefixed with some specific resource name, hence always refer to the selected
    // resource. Also, as the dimensions are filtered against the columns of the selected resource,
    // this should only match one of the next:
    ...dateProperties(),
    ...dateProperties({column: 'createdAt'}),
    ...dateProperties({column: 'updatedAt'}),
  ],
};

/**
 * Grouped metrics. See comment at {@link IMetric}.
 *
 * The group keys, such as `alerts` and `logs`, are only used to get a group name (so do not refer
 * to `properties`, and are not used for any filtering given the selected resource).
 */
export const metrics: {
  [key: string]: IMetric[];
} = {
  alerts: [
    {
      name: 'alert count',
      column: 'alert.id',
      function: 'countDistinct',
    },
  ],
  logs: [
    {
      name: 'log count',
      column: 'log.id',
      function: 'countDistinct',
    },
    {
      name: 'log duration avg',
      column: 'log.duration',
      function: 'avg',
      formatters: [time],
    },
    {
      name: 'log duration sum',
      column: 'log.duration',
      function: 'sum',
    },
  ],
  logstatuses: [
    {
      name: 'logstatus count',
      column: 'logstatus.id',
      function: 'countDistinct',
    },
    {
      name: 'logstatus duration avg',
      column: 'logstatus.duration',
      function: 'avg',
      formatters: [time],
    },
    {
      name: 'logstatus duration sum',
      column: 'logstatus.duration',
      function: 'sum',
      formatters: [time],
    },
  ],
  paymenttransactionscommon: [
    // Sum of `paymenttransaction.price` might not match the sum of the revenue transaction amounts,
    // but is the best we can do. (Providing a user-selectable computed column to get the summed
    // revenue amounts is hard without predefined queries.) Same goes for the average, which would
    // be the average of payment amounts rather than product prices, hence is not available.
    //
    // This is labeled "Revenue".
    {
      name: 'paymenttransaction price sum',
      column: 'paymenttransaction.price',
      function: 'sum',
      formatters: [price],
    },
    {
      name: 'revenuetransaction count',
      column: 'revenuetransaction.id',
      // A single revenue transaction might have multiple payments, so count the unique values
      function: 'countDistinct',
      joinType: 'right',
    },
    {
      name: 'paymenttransaction count',
      column: 'paymenttransaction.id',
      // In this case, plain `count` should suffice too, as we should not have duplicate payment ids
      function: 'countDistinct',
    },
  ],
  // `priceCash`, `priceCard` and all are computed columns in the API's ChartService
  paymenttransactionscash: [
    {
      name: 'paymenttransaction type cash count',
      column: 'paymenttransaction.priceCash',
      function: 'count',
    },
    {
      name: 'paymenttransaction type cash sum',
      column: 'paymenttransaction.priceCash',
      function: 'sum',
      formatters: [price],
    },
    {
      name: 'paymenttransaction type cash avg',
      column: 'paymenttransaction.priceCash',
      function: 'avg',
      formatters: [price],
    },
  ],
  paymenttransactionscard: [
    {
      name: 'paymenttransaction type card count',
      column: 'paymenttransaction.priceCard',
      function: 'count',
    },
    {
      name: 'paymenttransaction type card sum',
      column: 'paymenttransaction.priceCard',
      function: 'sum',
      formatters: [price],
    },
    {
      name: 'paymenttransaction type card avg',
      column: 'paymenttransaction.priceCard',
      function: 'avg',
      formatters: [price],
    },
  ],
  paymenttransactionscode: [
    {
      name: 'paymenttransaction type code count',
      column: 'paymenttransaction.priceCode',
      function: 'count',
    },
    {
      name: 'paymenttransaction type code sum',
      column: 'paymenttransaction.priceCode',
      function: 'sum',
      formatters: [price],
    },
    {
      name: 'paymenttransaction type code avg',
      column: 'paymenttransaction.priceCode',
      function: 'avg',
      formatters: [price],
    },
  ],
  paymenttransactionsvirtual: [
    {
      name: 'paymenttransaction type virtual count',
      column: 'paymenttransaction.priceVirtual',
      function: 'count',
    },
    {
      name: 'paymenttransaction type virtual sum',
      column: 'paymenttransaction.priceVirtual',
      function: 'sum',
      formatters: [price],
    },
    {
      name: 'paymenttransaction type virtual avg',
      column: 'paymenttransaction.priceVirtual',
      function: 'avg',
      formatters: [price],
    },
  ],
  paymenttransactionsother: [
    {
      name: 'paymenttransaction type other count',
      column: 'paymenttransaction.priceOther',
      function: 'count',
    },
    {
      name: 'paymenttransaction type other sum',
      column: 'paymenttransaction.priceOther',
      function: 'sum',
      formatters: [price],
    },
    {
      name: 'paymenttransaction type other avg',
      column: 'paymenttransaction.priceOther',
      function: 'avg',
      formatters: [price],
    },
  ],
};

/**
 * Mapping of a resource to default dimensions and metrics, if any.
 *
 * The keys refer to a selected resource, and match a key in {@link properties}. The string values
 * are matched against `name` and `column` as used in {@link dimensions} and {@link metrics} above.
 * The order specified here is also the column order as suggested in the default report settings.
 */
export const defaultDimensionsAndMetrics: {
  [key in Table]?: {dimensions?: string[]; metrics?: string[]}
} = {
  alert: {
    metrics: ['alert count'],
  },
  log: {
    metrics: ['log count', 'log duration avg', 'log duration sum'],
  },
  logstatus: {
    metrics: ['logstatus count', 'logstatus duration avg', 'logstatus duration sum'],
  },
  paymenttransaction: {
    metrics: [
      'paymenttransaction price sum',
      'revenuetransaction count',
      'paymenttransaction count',
    ],
  },
};

export function translateProperty(prop: {name?: string; column: string}) {
  // TODO arjan this only replaces THE FIRST dot. Expected?
  return i18n.t(`filter.properties.${(prop.name || prop.column).replace('.', ' ')}`);
}

export function isImmutableFunction(funct?: string) {
  return funct && immutableFunctions[funct];
}

export function getSelectKey(column: ISelect) {
  if (column.function && !(column.function in immutableFunctions)) {
    return column.column;
  }

  if (column.function) {
    if (column.functionParams) {
      return `${column.function}(${column.functionParams.join(',')},${column.column})`;
    }
    return `${column.function}(${column.column})`;
  }

  return column.column;
}

/**
 * Limit the given IColumn to an ISelect, optionally enriching using defaults from {@link properties}.
 */
export function columnToSelect(column: IColumn, enrich = false): ISelect {
  const prop = enrich ? getFullProperty(column) : ({} as any);

  const select: ISelect = {
    column: column.column,
    function: column.function || prop.function,
    functionParams: column.functionParams || prop.functionParams,
    joinType: column.joinType || prop.joinType,
  };

  // TODO arjan this might leave a dot in place; check if it's needed elsewhere
  select.alias = translateProperty(column);

  return select;
}

/**
 * Determine if an item is a dimension, that is: not some aggregate column. So: a definition that
 * has no true function, or has an immutable function for a dummy column such as "day of week".
 * An item that is not a dimension is a metric.
 */
export function isDimension(item: IColumn | ISelect): boolean {
  return !item.function || item.function === 'select' || isImmutableFunction(item.function);
}

export function isMetric(item: IColumn | ISelect): boolean {
  return !isDimension(item);
}

/**
 * Get the full property from any of the resources defined in `properties`.
 *
 * TODO arjan add some memoization
 */
export function getFullProperty(item: ISelect): IProperty | undefined {
  return Object.keys(properties).reduce((acc: IProperty | undefined, key) => {
    return acc || findInList(item, properties[key]);
  }, undefined);
}

/**
 * Get the full property from the given list (which might be a single item), taking any function and
 * parameters into account.
 */
export function findInList<T extends IProperty | ISelect>(
  item: IColumn | ISelect | undefined,
  list: T | T[] | undefined
): T | undefined {
  if (!item || !list) {
    return undefined;
  }
  // A single column might exist multiple times for computed properties such as "day of week"
  return (Array.isArray(list) ? list : [list]).find(
    (c: IProperty) =>
      c.column === item.column &&
      (!c.function ||
        (c.function === item.function &&
          (c.functionParams || []).join() === (item.functionParams || []).join()))
  );
}

export function getTimeFrameColumn(from: string | null, type?: ChartType): string {
  if (type) {
    switch (type) {
      case 'offline_machines':
      case 'machine_cleaning':
      case 'machine_issues':
        return 'updatedAt';
      case 'machine_alarms':
        return 'createdAt';
      case 'payment_types':
      case 'revenue_grouped':
      case 'revenue_full':
        return 'occurredAt';
    }
  }
  if (from === 'alert') {
    return 'createdAt';
  } else if (from === 'logstatus') {
    return 'updatedAt';
  }

  return 'occurredAt';
}
