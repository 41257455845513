/**
 * Load ES5 & ES6 polyfills, if necessary.
 */
export default function polyfill(): Promise<void> {
  const hasES6BuiltIns =
    'startsWith' in String.prototype &&
    'endsWith' in String.prototype &&
    'includes' in Array.prototype &&
    'assign' in Object &&
    'keys' in Object;

  if (hasES6BuiltIns) {
    return Promise.resolve();
  }

  if (typeof Promise === 'undefined') {
    require('promise-polyfill/src/polyfill');
  }

  return import('core-js').then(() => undefined);
}
