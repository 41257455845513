import * as React from 'react';
import i18next from 'i18next';
import {I18nextProvider} from 'react-i18next';
import {development} from '../config';

export const i18n = i18next.init({
  lng: localStorage.getItem('user_language') || 'en',
  fallbackLng: 'en',
  debug: development,
  resources: {
    en: {
      translation: require('./translation/en.json'),
    },
    nl: {
      translation: require('./translation/nl.json'),
    },
  },
});

export function I18nProvider(props: {children: JSX.Element}) {
  return <I18nextProvider i18n={i18n} {...props} />;
}
