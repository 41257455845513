import {i18n} from '../i18n';
import {IColumn} from './';

const second = {
  name: 'second',
  seconds: 1,
  label: 's',
};

const minute = {
  name: 'minute',
  seconds: 60,
  label: 'min',
};

const hour = {
  name: 'hour',
  seconds: minute.seconds * 60,
  label: 'h',
};

const day = {
  name: 'day',
  seconds: hour.seconds * 24,
  label: 'd',
};

const month = {
  name: 'month',
  seconds: day.seconds * 31,
  label: 'm',
};

const year = {
  name: 'year',
  seconds: month.seconds * 12,
  label: 'y',
};

const allDurations: Array<{
  name: string;
  seconds: number;
  label: string;
}> = [year, month, day, hour, minute, second];

export default function time(seconds: number, column: IColumn): string | number {
  if (column.function === 'count') {
    return seconds;
  }

  if (seconds === 0) {
    return `1${i18n.t(`formatter.time.${second.label}`)}`;
  }

  if (!seconds) {
    return '';
  }

  const longest = allDurations.find(duration => duration.seconds <= seconds);

  if (!longest) {
    return seconds.toString();
  }

  let durations: Array<{
    name: string;
    seconds: number;
    label: string;
  }> =
    {
      hour: [hour, minute],
      day: [day, hour],
      month: [month, day],
      year: [year, month],
    }[longest.name] || allDurations;

  durations = durations.filter(duration => duration.seconds <= longest.seconds);

  return durations.reduce(
    (result, duration) => {
      const times = Math.floor(result.left / duration.seconds);

      return {
        string: `${result.string} ${times}${i18n.t(`formatter.time.${duration.label}`)}`,
        left: result.left - times * duration.seconds,
      };
    },
    {left: seconds, string: ''}
  ).string;
}
