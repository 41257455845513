import {Container} from 'unstated';
import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import memoize from 'memoize-one';
import qs from 'qs';

import {AxiosInstance, AxiosRequestConfig, AxiosError} from 'axios';

interface Unauthenticated {
  state: 'unauthenticated';
  tokens: {};
  payloads: {};
  fullscreen?: boolean;
  permissions?: {};
}

interface Authenticating {
  state: 'authenticating';
  tokens: {};
  payloads: {};
  fullscreen?: boolean;
  permissions?: {};
}

interface Authenticated {
  state: 'authenticated';
  tokens: {access_token: string; refresh_token: string};
  payloads: {access_token: any; refresh_token: any};
  fullscreen?: boolean;
  permissions?: Permissions;
}

type ClientState = Unauthenticated | Authenticating | Authenticated;

type ClientOptions = {
  apiUrl?: string;
  initialState?: ClientState;
  paramsSerializer?: (params: any) => string;
};

type RawPermission = {action: string; model: string};
type Permissions = {[key: string]: {[key: string]: boolean}};

const defaultParamsSerializer = (params: any) => qs.stringify(params, {arrayFormat: 'brackets'});

/**
 * The API `Client` manages authentication state, provides authenticated
 * request methods (with the same interface as Axios) and automates token
 * refreshing.
 */
export default class ApiClient extends Container<ClientState> {
  // Internal axios instance
  private axios: AxiosInstance;

  // Public request methods (implement the RequestClient interface)
  public request: AxiosInstance['request'];
  public get: AxiosInstance['get'];
  public delete: AxiosInstance['delete'];
  public head: AxiosInstance['head'];
  public post: AxiosInstance['post'];
  public put: AxiosInstance['put'];
  public patch: AxiosInstance['patch'];

  constructor(options: ClientOptions = {}) {
    super();

    // Normalize options
    const baseURL = options.apiUrl || process.env.REACT_APP_API_URL;
    const initialState = options.initialState || loadState();
    const paramsSerializer = options.paramsSerializer || defaultParamsSerializer;

    if (!baseURL) {
      throw new Error('Missing API URL');
    }

    // Set initial state
    this.state = initialState;

    // Create a managed axios instance
    this.axios = Axios.create({baseURL, paramsSerializer});
    this.axios.interceptors.request.use(this.interceptRequest);
    this.axios.interceptors.response.use(undefined, this.interceptResponseError);

    // Bind the public request methods to the internal axios instance
    this.request = this.axios.request.bind(this.axios);
    this.get = this.axios.get.bind(this.axios);
    this.delete = this.axios.delete.bind(this.axios);
    this.head = this.axios.head.bind(this.axios);
    this.post = this.axios.post.bind(this.axios);
    this.put = this.axios.put.bind(this.axios);
    this.put = this.axios.put.bind(this.axios);
    this.patch = this.axios.patch.bind(this.axios);
  }

  /**
   * Returns `true` if the user is authenticated with the API.
   */
  public isAuthenticated = () => {
    return this.state.state === 'authenticated';
  };

  /**
   * Returns `true` if the application should be displayed in fullscreen mode.
   */
  public isFullscreenMode = () => {
    return Boolean(this.state.fullscreen);
  };

  /**
   * Returns `true` if the user is authenticated with the API, and is an administrator.
   */
  public isAdmin = () =>
    this.state.state === 'authenticated' && this.state.payloads.access_token.admin === true;

  /**
   * Returns `true` if a login request is in progress.
   */
  public isLoggingIn = () => this.state.state === 'authenticating';

  /**
   * Log in to the API. If already logged in, this resets authentication state.
   */
  public login = async (values: {username: string; password: string}) => {
    localStorage.removeItem('auth');
    this.setState({state: 'authenticating', tokens: {}, payloads: {}});

    try {
      const response = await this.axios.post<{
        access_token: string;
        refresh_token: string;
      }>('/auth/login', values);

      const resp = this.authenticate(response.data);

      await this.loadUserPermissions();

      return resp;
    } catch (err) {
      await this.setState({state: 'unauthenticated'});
      throw err;
    }
  };

  /**
   * Log out of the API.
   */
  public logout = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('auth_url');

    return this.setState({state: 'unauthenticated', tokens: {}, payloads: {}});
  };

  public loadPermissions = async (options?: {forceFetch?: boolean}) => {
    const {permissions} = this.state;

    if (!permissions || (options && options.forceFetch)) {
      await this.loadUserPermissions();
    }
  };

  public isAllowed = (resource: string, action: string): boolean => {
    const {permissions} = this.state;

    if (!permissions || !permissions[resource]) {
      return false;
    }

    return Boolean(permissions[resource][action]);
  };

  private loadUserPermissions = async () => {
    const {data} = await this.axios.get<any>('/permission');

    this.setState({permissions: buildPermissions(actionsGroupedByResource(data))});
  };

  private authenticate = (tokens: {access_token: string; refresh_token: string}) => {
    storeTokens(tokens);

    const payloads = {
      access_token: safeJwtDecode(tokens.access_token),
      refresh_token: safeJwtDecode(tokens.refresh_token),
    };

    this.setState({state: 'authenticated', tokens, payloads});
  };

  private interceptRequest = async (config: AxiosRequestConfig) => {
    if (isLogin(config.url)) {
      return config;
    }

    if (this.state.state !== 'authenticated') {
      throw new Error('Not authenticated');
    }

    if (isTokenRefresh(config.url)) {
      return patchRequestWithAuthorization(config, this.state.tokens.access_token);
    }

    if (this.state.state === 'authenticated' && isExpired(this.state.payloads.access_token)) {
      if (isExpired(this.state.payloads.refresh_token)) {
        await this.logout();
        throw new Error('Refresh token expired');
      }
      await this.authenticate(await this.refreshTokens(this.state.tokens.refresh_token));
    }

    return patchRequestWithAuthorization(config, this.state.tokens.access_token);
  };

  private interceptResponseError = async (error: AxiosError) => {
    if (error.config && error.response && error.response.status === 401) {
      if (
        this.state.state === 'authenticated' &&
        !isLogin(error.config.url) &&
        !isTokenRefresh(error.config.url) &&
        !isExpired(this.state.payloads.refresh_token)
      ) {
        // Refresh auth tokens
        await this.authenticate(await this.refreshTokens(this.state.tokens.refresh_token));

        // Retry original request
        return this.axios.request(
          patchRequestWithAuthorization(error.config, this.state.tokens.access_token)
        );
      }

      await this.logout();
    }
    throw error;
  };

  private refreshTokens = memoize(async (refresh_token: string) => {
    const {data} = await this.axios.post<{
      access_token: string;
      refresh_token: string;
    }>('/auth/refresh-token', {refresh_token});

    return data;
  });
}

function loadState(): ClientState {
  try {
    const urlParams = new URLSearchParams(location.search);
    let access_token = urlParams.get('access_token');
    let refresh_token = urlParams.get('refresh_token');
    let fullscreen = urlParams.get('fullscreen') === 'true';
    const userLanguage = urlParams.get('lng');

    localStorage.setItem('user_language', userLanguage ? userLanguage : 'en');

    // If fullscreen mode is asked via the url parameters,
    // We save it in the localStorage in case the page is refreshed
    if (urlParams.get('fullscreen')) {
      localStorage.setItem('fullscreen', JSON.stringify(fullscreen));
    }

    if (userLanguage) {
      localStorage.setItem('user_language', userLanguage);
    }

    if (access_token && refresh_token) {
      storeTokens({access_token, refresh_token}, {fromUrl: true});
      fullscreen = urlParams.get('fullscreen')
        ? urlParams.get('fullscreen') === 'true'
        : localStorage.getItem('fullscreen') === 'true';
    }

    if (!access_token || !refresh_token) {
      const authTokens = JSON.parse(localStorage.getItem('auth') || '{}');
      const authUrlTokens = JSON.parse(localStorage.getItem('auth_url') || '{}');

      if (authTokens.access_token && authTokens.refresh_token) {
        access_token = authTokens.access_token;
        refresh_token = authTokens.refresh_token;
      }

      if (authUrlTokens.access_token && authUrlTokens.refresh_token) {
        access_token = authUrlTokens.access_token;
        refresh_token = authUrlTokens.refresh_token;
        fullscreen = urlParams.get('fullscreen')
          ? urlParams.get('fullscreen') === 'true'
          : localStorage.getItem('fullscreen') === 'true';
      }
    }

    if (!access_token || !refresh_token) {
      throw new Error('Invalid localStorage auth state');
    }

    return {
      state: 'authenticated',
      tokens: {
        access_token,
        refresh_token,
      },
      payloads: {
        access_token: safeJwtDecode(access_token),
        refresh_token: safeJwtDecode(refresh_token),
      },
      fullscreen,
    };
  } catch (err) {
    localStorage.removeItem('auth');
    localStorage.removeItem('auth_url');
  }

  return {
    state: 'unauthenticated',
    tokens: {},
    payloads: {},
  };
}

function storeTokens(
  tokens: {access_token: string; refresh_token: string},
  config?: {fromUrl?: boolean}
) {
  const valuesFromUrlParams = config && config.fromUrl;

  localStorage.setItem(valuesFromUrlParams ? 'auth_url' : 'auth', JSON.stringify(tokens));
}

function safeJwtDecode(token: string): any | {} {
  try {
    return jwtDecode(token);
  } catch (err) {
    return {};
  }
}

function patchRequestWithAuthorization(
  config: AxiosRequestConfig,
  access_token: string
): AxiosRequestConfig {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${access_token}`,
    },
  };
}

function isLogin(url?: string): boolean {
  return typeof url === 'string' && /\/auth\/login$/.test(url);
}

function isTokenRefresh(url?: string): boolean {
  return typeof url === 'string' && /\/auth\/refresh-token/.test(url);
}

function isExpired(tokenPayload: any): boolean {
  return tokenPayload && tokenPayload.exp && tokenPayload.exp <= Date.now() / 1000;
}

function actionsGroupedByResource(permissions: RawPermission[]): {[key: string]: string[]} {
  const grouped = {};

  permissions.forEach(permission => {
    grouped[permission.model] = grouped[permission.model] || [];

    grouped[permission.model].push(permission.action);
  });

  return grouped;
}

function buildPermissions(groupedPermissions: {[key: string]: string[]}): Permissions {
  const permissions = {};

  for (const resource in groupedPermissions) {
    if (!groupedPermissions.hasOwnProperty(resource)) {
      continue;
    }

    const rules = groupedPermissions[resource];

    if (!permissions[resource]) {
      permissions[resource] = {};
    }

    rules.forEach(rule => {
      permissions[resource][rule] = true;
    });
  }

  return permissions;
}
