import withWidth from '@material-ui/core/withWidth';
import {WithWidth as WithWidthProps} from '@material-ui/core/withWidth';

interface IProps {
  children: (props: WithWidthProps) => JSX.Element;
}

/**
 * Render-prop version of widthWidth().
 *
 * Usage example:
 *
 * <WithWidth>{({width}) => <span>{width}</span>}</WithWidth>
 */
export const WithWidth = withWidth()(
  (props: IProps & WithWidthProps): JSX.Element => {
    const {resizeInterval, children, ...rest} = props as any;
    return children(rest as WithWidthProps);
  }
);
