import * as React from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#338357',
    },
  },
  typography: {
    fontSize: 15,
    fontFamily: ['Inter', 'serif'].join(','),
    useNextVariants: true,
    h6: {
      color: '#484d52',
      fontSize: '1.1rem',
      fontWeight: 700,
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        fontSize: '13px',
        letterSpacing: 'normal',
        textTransform: 'capitalize',
      },
      raisedPrimary: {
        background: '#136430',
      },
      raised: {
        background: '#eff0f1',
      },
      contained: {
        color: '#484d52',
      },
    },
    MuiPaper: {
      // default elevation
      elevation2: {
        boxShadow: '0 0 8px 0 rgba(0,0,0,.1)',
      },
      rounded: {
        borderRadius: 2,
      },
    },
  },
});

export function ThemeProvider({children}: React.Props<any>) {
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </React.Fragment>
  );
}
