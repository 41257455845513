import * as React from 'react';
import {I18n} from 'react-i18next';
import Downshift from 'downshift';
import keycode from 'keycode';

import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import {createStyled} from '../../style';
import AutocompleteOptions from './AutocompleteOptions';
import {ApiClient, getItemById} from '../../api';

interface IProps {
  label?: string;
  initialValues?: Array<{id: number}>;
  id?: string;
  placeholder?: string;
  className?: string;
  /** The property to search on */
  property: string;
  resource:
    | 'machine'
    | 'location'
    | 'errorIdentity'
    | 'currency'
    | 'machinegroup'
    | 'locationgroup'
    | 'manufacturer'
    | 'machinemodel'
    | 'product'
    | 'machinetype'
    | 'debtor';
  /** Triggers when adding or removing a value  */
  onChange: (values: Array<{id: number}>) => void;
  formatLabel?: (value: {id: number}) => string;
  compareValue?: (a: {id: number}, b: {id: number}) => boolean;
  api: ApiClient;
}

interface IState {
  values: Array<{id: number}>;
  inputValue: string;
}

const Styled = createStyled((theme: any) => ({
  root: {
    flexGrow: 1,
    display: 'inline-flex',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  input: {
    flexWrap: 'wrap',
  },
  chip: {
    margin: `0 ${theme.spacing.unit / 4}px`,
  },
}));

export default class MultiAutocomplete extends React.Component<IProps, IState> {
  public state = {
    values: this.props.initialValues || [],
    inputValue: '',
  };

  public async componentDidMount() {
    const {api, resource, initialValues, property} = this.props;

    if (initialValues && initialValues.length) {
      try {
        const values = await Promise.all(
          initialValues.map(async ({id}) => {
            const item = await getItemById(api, {resource, id});
            return {id, [property]: item[property]};
          })
        );
        this.setState({values});
      } catch (err) {
        // TODO: add error handling
        // tslint:disable-next-line:no-console
        console.error(err);
      }
    }
  }

  public formatLabel = (value: {id: number}): string => {
    const {formatLabel} = this.props;

    if (formatLabel) {
      return formatLabel(value);
    }

    return typeof value === 'string' ? value : value.toString();
  };

  // set default compareValue prop
  public compareValue = (a: {id: number}, b: {id: number}): boolean => {
    const {compareValue} = this.props;

    return compareValue ? compareValue(a, b) : a === b;
  };

  public getIndex(items: Array<{id: number}>, searchItem: {id: number}): number {
    for (let index = 0; index < items.length; index++) {
      if (this.compareValue(items[index], searchItem)) {
        return index;
      }
    }

    return -1;
  }

  public handleKeyDown = (event: Event): void => {
    const {inputValue, values} = this.state;
    const {onChange} = this.props;

    if (values.length && !inputValue && keycode(event) === 'backspace') {
      const newValues = values.slice(0, values.length - 1);

      this.setState({values: newValues});
      onChange(newValues);
    }
  };

  public handleInputChange = (inputValue: string | undefined): void => {
    this.setState({inputValue: inputValue || ''});
  };

  public handleSelectOption = (item: {id: number}): void => {
    const {onChange} = this.props;
    const {values} = this.state;

    if (item && this.getIndex(values, item) === -1) {
      const newValues = [...values, item];

      this.setState({values: newValues});
      onChange(newValues);
    }

    this.setState({inputValue: ''});
  };

  public handleDelete = (item: {id: number}) => (): void => {
    const {values} = this.state;
    const {onChange} = this.props;
    const index = item && this.getIndex(values, item);

    if (index > -1) {
      const newValues = values.filter((v, i) => {
        return i !== index;
      });

      this.setState({values: newValues});
      onChange(newValues);
    }
  };

  public render() {
    const {placeholder, id, className, label, resource, property} = this.props;
    const {inputValue, values} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <div className={`${classes.root} ${className}`}>
                <Downshift
                  onInputValueChange={this.handleInputChange}
                  onChange={this.handleSelectOption}
                  itemToString={this.formatLabel}
                  selectedItem={values}
                  inputValue={inputValue}
                >
                  {({getInputProps, getItemProps, isOpen}) => (
                    <div className={classes.container}>
                      <TextField
                        fullWidth={true}
                        label={label}
                        className={classes.input}
                        InputProps={{
                          ...getInputProps({placeholder, id}),
                          onKeyDown: this.handleKeyDown,
                          startAdornment: values.map((item, index) => (
                            <Chip
                              key={index}
                              tabIndex={-1}
                              label={this.formatLabel(item)}
                              className={classes.chip}
                              onDelete={this.handleDelete(item)}
                            />
                          )),
                        }}
                      />

                      {inputValue !== '' && isOpen ? (
                        <AutocompleteOptions
                          itemProps={getItemProps}
                          search={inputValue}
                          formatLabel={this.formatLabel}
                          property={property}
                          resource={resource}
                        />
                      ) : null}
                    </div>
                  )}
                </Downshift>
              </div>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
