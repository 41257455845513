import * as React from 'react';
import {I18n} from 'react-i18next';
import {reorder} from 'react-reorder';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import BorderAllIcon from '@material-ui/icons/BorderAll';
import BarChartIcon from '@material-ui/icons/BarChart';

import {
  IDimension,
  IMetric,
  dimensions,
  metrics,
  columnToSelect,
  findInList,
  getFullProperty,
  isDimension,
  isMetric,
  translateProperty,
} from '../properties';

import {ChartView, chartViews, singleTimeFrameChartType} from '../../../api';
import {createStyled} from '../../../style';
import ColumnEditDialog from '../tools/ColumnEditDialog';
import DataSelector, {IGroupedItems} from '../tools/DataSelector';
import ListManager from '../tools/ListManager';
import {IColumn, ISelect} from '../ChartDialog';

interface IProps {
  /**
   * Database columns and dummy columns, used as the source for allowed "dimensions" and "metrics".
   */
  columns: IColumn[];

  onSelectedColumnsChange: (values: ISelect[]) => void;
  onChartViewChange: (value: ChartView) => void;

  /**
   * Optional defaults for the user-selected columns and their settings, including both "dimensions"
   * (database table data, and dummy columns such as "day of week") and "metrics" (calculated values
   * such as "max" or "sum").
   */
  initialValues?: ISelect[];

  view: ChartView;
  onSetDisableComparison: (isDisabled: boolean) => void;
}

type Tab = 'table' | 'graph';

const graphChartViews = chartViews.filter(v => v !== 'table');

interface IState {
  tab: Tab;
  // The chart view as selected on the graph ("Chart") tab, excluding 'table' which has its own tab
  graphChartView: ChartView;

  // When the user switches between the table/graph tabs, preserve both configurations until the
  // user proceeds to the next (or previous) step.
  selectedTableDimensions: ISelect[];
  selectedTableMetrics: ISelect[];
  selectedGraphDimension: ISelect | undefined;
  selectedGraphMetric: ISelect | undefined;

  popoverAnchorEl: HTMLElement | undefined;
  showAddDimension: boolean;
  showAddMetric: boolean;
  editColumn: ISelect | undefined;
  editColumnIndex: number | undefined;
}

const Styled = createStyled(theme => ({
  wrapper: {
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    flexGrow: 1,
  },
  tabs: {
    backgroundColor: '#f4f5f7',
  },
  tabRoot: {
    minHeight: '50px',
    padding: 0,
    textTransform: 'none',
  },
  tabWrapper: {
    flexDirection: 'row',
    width: 'unset',
  },
  tabLabelContainer: {
    padding: '8px',
  },
  subtitle: {
    fontWeight: 600,
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  popoverWrapper: {
    padding: theme.spacing.unit * 2,
    flexGrow: 1,
  },
}));

export default class PresentationStep extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {initialValues, view} = props;
    if (initialValues) {
      initialValues.forEach(this.setDefaultAlias);
    }

    const selectedTableDimensions = initialValues ? initialValues.filter(isDimension) : [];
    const selectedTableMetrics = initialValues ? initialValues.filter(isMetric) : [];
    const isTable = !view || view === 'table';

    this.state = {
      tab: isTable ? 'table' : 'graph',
      graphChartView: isTable ? graphChartViews[0] : view,
      selectedTableDimensions,
      selectedTableMetrics,
      // Do not set any default until the user switches from the table to the graph tab, unless the
      // user already selected a specific graph
      selectedGraphDimension: isTable ? undefined : selectedTableDimensions[0],
      selectedGraphMetric: isTable ? undefined : selectedTableMetrics[0],

      popoverAnchorEl: undefined,
      showAddDimension: false,
      showAddMetric: false,
      editColumn: undefined,
      editColumnIndex: undefined,
    };
  }

  /**
   * Invoke the properties' {@link onChartViewChange} and {@link onSelectedColumnsChange} callbacks.
   *
   * This is even needed when the user only switches tabs, as that implicitly changes the selected
   * Chart View, and as the suggested (possibly empty) defaults might not allow the user to proceed
   * to the next step.
   */
  private notifyOnChange() {
    const {
      tab,
      graphChartView,
      selectedTableDimensions,
      selectedTableMetrics,
      selectedGraphDimension,
      selectedGraphMetric,
    } = this.state;
    const {onChartViewChange, onSelectedColumnsChange} = this.props;

    if (tab === 'table') {
      onChartViewChange('table');
      onSelectedColumnsChange(selectedTableDimensions.concat(selectedTableMetrics));
    } else {
      onChartViewChange(graphChartView);
      onSelectedColumnsChange([selectedGraphDimension, selectedGraphMetric].filter(
        v => v !== undefined
      ) as ISelect[]);
    }
  }

  /**
   * Transform the flat columns into a more descriptive hierarchical list for use in the Data
   * Selector, silently ignoring any column that is not known in the mapping, and disabling items
   * that have already been selected.
   */
  private toGroupedItems = (
    columns: IColumn[],
    groupMapping: {[group: string]: Array<IDimension | IMetric>},
    selectedItems: ISelect | ISelect[] | undefined
  ): IGroupedItems<IColumn> => {
    return Object.keys(groupMapping).reduce((acc, group) => {
      for (const item of groupMapping[group]) {
        // Not all items in the group definition might be visible to the current user.
        const column = findInList(item, columns);

        if (column) {
          const disabled = Boolean(findInList(item, selectedItems));
          const groupName = translateProperty({column: group});
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push({
            name: translateProperty(item),
            disabled,
            item: {
              ...column,
              ...item,
            },
          });
        }
      }
      return acc;
    }, {});
  };

  private getAvailableDimensions(): IGroupedItems<IColumn> {
    const {columns} = this.props;
    const {tab, selectedTableDimensions, selectedGraphDimension} = this.state;
    return this.toGroupedItems(
      columns,
      dimensions,
      tab === 'table' ? selectedTableDimensions : selectedGraphDimension
    );
  }

  private getAvailableMetrics(): IGroupedItems<IColumn> {
    const {columns} = this.props;
    const {tab, selectedTableMetrics, selectedGraphMetric} = this.state;
    return this.toGroupedItems(
      columns,
      // Future: for timeline graphs, one might want to limit to time-related dimensions
      metrics,
      tab === 'table' ? selectedTableMetrics : selectedGraphMetric
    );
  }

  private setDefaultAlias(item: ISelect): ISelect {
    if (!item.alias || !item.alias.trim()) {
      item.alias = this.getDefaultAlias(item);
    } else {
      item.alias = item.alias.trim();
    }
    return item;
  }

  private getDefaultAlias(item: ISelect): string {
    if (isDimension(item)) {
      // For computed columns such as "day of week" we need the column's `name`, if specified
      const prop = getFullProperty(item);
      // The API only allows `^((?!\.).)*$`, hence does not allow for any dot
      return translateProperty(prop || item).replace(/\./g, ' ');
    }

    // For metrics we always need the `name` as defined with the metric, to get a translation
    // specific for its aggregate function, so: find the metric definition.
    const metric = Object.keys(metrics).reduce(
      (acc: IMetric | undefined, key) =>
        acc || metrics[key].find(m => m.column === item.column && m.function === item.function),
      undefined
    );

    // In rare cases the metric might no longer exist, but might still exist in older reports. The
    // fallback will get a translation that does not know about the metric's aggregate function.
    return translateProperty(metric || item).replace(/\./g, ' ');
  }

  public handleTabChange = (event: React.ChangeEvent<{}>, newTab: Tab): void => {
    const {onSetDisableComparison} = this.props;

    this.setState(state => {
      const {selectedTableDimensions, selectedTableMetrics} = state;
      let {selectedGraphDimension, selectedGraphMetric} = state;

      // If the user has not selected anything on the graph tab yet, then copy the first items from
      // the table tab configuration, if any.
      if (newTab === 'graph') {
        selectedGraphDimension = selectedGraphDimension || selectedTableDimensions[0];
        selectedGraphMetric = selectedGraphMetric || selectedTableMetrics[0];
      } else {
        onSetDisableComparison(false);
      }

      return {tab: newTab, selectedGraphDimension, selectedGraphMetric};
    }, this.notifyOnChange);
  };

  public handleClosePopover = () => {
    this.setState({
      showAddDimension: false,
      showAddMetric: false,
      editColumn: undefined,
      editColumnIndex: undefined,
      popoverAnchorEl: undefined,
    });
  };

  public handleAddDimensionClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showAddDimension: true, popoverAnchorEl: event.currentTarget});
  };

  public handleAddMetricClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({showAddMetric: true, popoverAnchorEl: event.currentTarget});
  };

  /**
   * Add a new dimension or metric to the end of the list for table charts, or replace the choice
   * with the single item for bar/pie/timeline charts.
   */
  public handleAddColumn = (column: IColumn) => {
    const select: ISelect = columnToSelect(column);

    // Overwrite the column's alias with whatever is explicitly set for the dimension or metric
    select.alias = this.getDefaultAlias(select);

    this.setState(state => {
      const {tab, selectedTableDimensions, selectedTableMetrics} = state;
      let {selectedGraphDimension, selectedGraphMetric} = state;

      if (tab === 'table') {
        if (isDimension(select)) {
          selectedTableDimensions.push(select);
        } else {
          selectedTableMetrics.push(select);
        }
      } else {
        if (isDimension(select)) {
          selectedGraphDimension = select;
        } else {
          selectedGraphMetric = select;
        }
      }

      return {
        selectedTableDimensions,
        selectedTableMetrics,
        selectedGraphDimension,
        selectedGraphMetric,
        popoverAnchorEl: undefined,
        showAddDimension: false,
        showAddMetric: false,
      };
    }, this.notifyOnChange);
  };

  public handleEditColumnClick = (
    item: ISelect,
    index: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    this.setState({
      editColumn: item,
      editColumnIndex: index,
      popoverAnchorEl: event.currentTarget,
    });
  };

  public handleCloseEditColumn = () => {
    this.setState(state => {
      const {editColumn} = state;
      if (editColumn) {
        // Check if the alias was cleared in the edit, and then force its default. (The edited item
        // might also have been deleted from the selected columns, but that's handled elsewhere.)
        this.setDefaultAlias(editColumn);
      }
      return {
        editColumn: undefined,
        editColumnIndex: undefined,
        popoverAnchorEl: undefined,
      };
    });
  };

  /**
   * Handle changes while the dialog is still open and the user is still making changes.
   */
  private handleColumnEditChange = () => {
    this.notifyOnChange();
  };

  private handleColumnDelete = (item: ISelect) => {
    this.setState(state => {
      const {selectedTableDimensions, selectedTableMetrics, editColumnIndex} = state;
      const source = isDimension(item) ? selectedTableDimensions : selectedTableMetrics;
      source.splice(editColumnIndex || 0, 1);

      return {
        selectedTableDimensions,
        selectedTableMetrics,
        editColumn: undefined,
        editColumnIndex: undefined,
        popoverAnchorEl: undefined,
      };
    }, this.notifyOnChange);
  };

  public handleReorderDimensions = (oldIndex: number, newIndex: number) => {
    this.setState(state => {
      const selectedTableDimensions = reorder(state.selectedTableDimensions, oldIndex, newIndex);
      return {selectedTableDimensions};
    }, this.notifyOnChange);
  };

  public handleReorderMetrics = (oldIndex: number, newIndex: number) => {
    this.setState(state => {
      const selectedTableMetrics = reorder(state.selectedTableMetrics, oldIndex, newIndex);
      return {selectedTableMetrics};
    }, this.notifyOnChange);
  };

  public handleChartViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {onSetDisableComparison} = this.props;

    this.setState({graphChartView: event.target.value as ChartView}, this.notifyOnChange);

    if (singleTimeFrameChartType.indexOf(event.target.value) > -1) {
      onSetDisableComparison(true);
    } else {
      onSetDisableComparison(false);
    }
  };

  private countOccurrences(occurrence: string, words: string[]): number {
    const needle = occurrence.toLowerCase();
    return words.reduce((count, word) => (word.toLowerCase() === needle ? ++count : count), 0);
  }

  public render() {
    const {
      tab,
      selectedTableDimensions,
      selectedTableMetrics,
      graphChartView,
      selectedGraphDimension,
      selectedGraphMetric,
      popoverAnchorEl,
      showAddDimension,
      showAddMetric,
      editColumn,
    } = this.state;

    const aliases = selectedTableDimensions
      .concat(selectedTableMetrics)
      .map(column => column.alias || column.column);

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <React.Fragment>
                <Tabs
                  value={tab}
                  onChange={this.handleTabChange}
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                >
                  <Tab
                    value="table"
                    label={t('chart_dialog.steps.presentation.tabs.table')}
                    icon={<BorderAllIcon />}
                    classes={{
                      root: classes.tabRoot,
                      wrapper: classes.tabWrapper,
                      labelContainer: classes.tabLabelContainer,
                    }}
                  />
                  <Tab
                    value="graph"
                    label={t('chart_dialog.steps.presentation.tabs.graph')}
                    icon={<BarChartIcon />}
                    classes={{
                      root: classes.tabRoot,
                      wrapper: classes.tabWrapper,
                      labelContainer: classes.tabLabelContainer,
                    }}
                  />
                </Tabs>

                <div className={classes.wrapper}>
                  <Grid container={true} spacing={40}>
                    {tab === 'table' && (
                      <Grid item={true} xs={12} sm={7}>
                        <Grid container={true} alignItems={'center'} spacing={32}>
                          <Grid item={true} xs={12}>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                              {t('chart_dialog.steps.presentation.table.title')}
                            </Typography>

                            <ListManager
                              title={t('chart_dialog.steps.presentation.table.dimensions_label')}
                              subtitle={t('chart_dialog.steps.presentation.table.dimensions_text')}
                              listItems={selectedTableDimensions.map((c, i) => ({
                                title: c.alias || this.getDefaultAlias(c),
                                error: this.countOccurrences(c.alias || c.column, aliases) > 1,
                                onMenuClick: this.handleEditColumnClick.bind(this, c, i),
                              }))}
                              addItems={[
                                {
                                  title: t(
                                    'chart_dialog.steps.presentation.table.dimensions_add_bt'
                                  ),
                                  onClick: this.handleAddDimensionClick,
                                },
                              ]}
                              onReorder={this.handleReorderDimensions}
                            />

                            <ListManager
                              title={t('chart_dialog.steps.presentation.table.metrics_label')}
                              subtitle={t('chart_dialog.steps.presentation.table.metrics_text')}
                              listItems={selectedTableMetrics.map((c, i) => ({
                                title: c.alias || this.getDefaultAlias(c),
                                error: this.countOccurrences(c.alias || c.column, aliases) > 1,
                                onMenuClick: this.handleEditColumnClick.bind(this, c, i),
                              }))}
                              addItems={[
                                {
                                  title: t('chart_dialog.steps.presentation.table.metrics_add_bt'),
                                  onClick: this.handleAddMetricClick,
                                },
                              ]}
                              onReorder={this.handleReorderMetrics}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {tab === 'graph' && (
                      <Grid item={true} xs={12} sm={7}>
                        <Grid container={true} alignItems={'center'} spacing={32}>
                          <Grid item={true} xs={12}>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                              {t('chart_dialog.steps.presentation.graph.title')}
                            </Typography>

                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="chart-type">
                                {t(
                                  'chart_dialog.steps.presentation.graph.chart_view.chart_view_label'
                                )}
                              </InputLabel>
                              <Select
                                id="chart-type"
                                value={graphChartView}
                                onChange={this.handleChartViewChange}
                              >
                                {graphChartViews.map((chartView: ChartView, idx: number) => (
                                  <MenuItem key={idx} value={chartView}>
                                    {t(
                                      'chart_dialog.steps.presentation.graph.chart_view.types.' +
                                        chartView
                                    )}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* Fake dropdowns just for the layout, with `open` forced to `false`; clicking shows the DataSelector popover */}
                          <Grid item={true} xs={12}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="chart-dimension">
                                {t('chart_dialog.steps.presentation.graph.dimension_label')}
                              </InputLabel>
                              <Select
                                id="chart-dimension"
                                value={0}
                                onClick={this.handleAddDimensionClick}
                                open={false}
                                onOpen={() => undefined}
                              >
                                {/* Only the current selected item is needed for the fake dropdown */}
                                <MenuItem key={0} value={0}>
                                  {selectedGraphDimension
                                    ? selectedGraphDimension.alias
                                    : t(
                                        'chart_dialog.steps.presentation.graph.dimension_select_label'
                                      )}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item={true} xs={12}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="chart-metric">
                                {t('chart_dialog.steps.presentation.graph.metric_label')}
                              </InputLabel>
                              <Select
                                id="chart-metric"
                                value={0}
                                onClick={this.handleAddMetricClick}
                                open={false}
                                onOpen={() => undefined}
                              >
                                {/* Only the current selected item is needed for the fake dropdown */}
                                <MenuItem key={0} value={0}>
                                  {selectedGraphMetric
                                    ? selectedGraphMetric.alias
                                    : t(
                                        'chart_dialog.steps.presentation.graph.metric_select_label'
                                      )}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {/* Add/select dimension dialog */}
                  {showAddDimension && (
                    <Popover
                      open={showAddDimension}
                      anchorEl={popoverAnchorEl}
                      onClose={this.handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <div className={classes.popoverWrapper}>
                        <DataSelector
                          groupedItems={this.getAvailableDimensions()}
                          onClick={this.handleAddColumn}
                        />
                      </div>
                    </Popover>
                  )}

                  {/* Add/select metric dialog */}
                  {showAddMetric && (
                    <Popover
                      open={true}
                      anchorEl={popoverAnchorEl}
                      onClose={this.handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <div className={classes.popoverWrapper}>
                        <DataSelector
                          groupedItems={this.getAvailableMetrics()}
                          onClick={this.handleAddColumn}
                        />
                      </div>
                    </Popover>
                  )}

                  {/* Edit column attributes dialog */}
                  {editColumn && (
                    <Popover
                      open={true}
                      anchorEl={popoverAnchorEl}
                      onClose={this.handleCloseEditColumn}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <div className={classes.popoverWrapper}>
                        <ColumnEditDialog
                          column={editColumn}
                          defaultAlias={this.getDefaultAlias(editColumn)}
                          aliases={aliases}
                          onChange={this.handleColumnEditChange}
                          onDone={this.handleCloseEditColumn}
                          onDelete={this.handleColumnDelete}
                        />
                      </div>
                    </Popover>
                  )}
                </div>
              </React.Fragment>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
