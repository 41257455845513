import * as React from 'react';
import {I18n} from 'react-i18next';

import {createStyled, WithWidth} from '../style';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

interface IProps {
  title: string | JSX.Element;
  steps: Array<{title: string; content: string | JSX.Element; completed: boolean}>;
  initialStep?: number;
  goToNextStep: (currentStep: number) => boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

interface IState {
  currentStep: number;
  id: string;
}

const Styled = createStyled(theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  showPointerCursor: {
    cursor: 'pointer',
  },
}));

export default class StepperDialog extends React.Component<IProps, IState> {
  public state = {
    currentStep: this.props.initialStep || 0,
    id: 'stepper-dialog-' + Math.round(Math.random() * 1000000),
  };

  public handleNext = () => {
    this.setState(state => ({
      currentStep: state.currentStep + 1,
    }));
  };

  public handlePrev = () => {
    this.setState(state => ({
      currentStep: state.currentStep - 1,
    }));
  };

  public handleStepClick = (index: number, step: {completed: boolean}) => {
    if (this.arePreviousStepsCompleted(index)) {
      this.setState(state => ({
        currentStep: index,
      }));
    }
  };

  public render() {
    const {currentStep, id} = this.state;
    const {steps, onSubmit, onCancel, goToNextStep, title} = this.props;

    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep === steps.length - 1;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <WithWidth>
                {({width}: {width: string}) => (
                  <Dialog
                    fullScreen={width === 'xs'}
                    fullWidth={true}
                    maxWidth={'md'}
                    open={true}
                    aria-labelledby={id}
                  >
                    <DialogTitle id={id}>{title}</DialogTitle>
                    <DialogContent>
                      <Stepper activeStep={currentStep} nonLinear={true} orientation="vertical">
                        {steps.map((step, index) => (
                          <Step key={index} completed={step.completed}>
                            <StepLabel
                              className={
                                this.arePreviousStepsCompleted(index)
                                  ? classes.showPointerCursor
                                  : ''
                              }
                              onClick={this.handleStepClick.bind(this, index, step)}
                            >
                              {step.title}
                            </StepLabel>
                            <StepContent>
                              {step.content}

                              <div className={classes.actionsContainer}>
                                <Button
                                  disabled={isFirstStep}
                                  onClick={this.handlePrev}
                                  className={classes.button}
                                >
                                  {t('stepper_dialog.back_bt')}
                                </Button>

                                {!isLastStep ? (
                                  <Button
                                    disabled={!goToNextStep(currentStep)}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleNext}
                                    className={classes.button}
                                  >
                                    {t('stepper_dialog.next_bt')}
                                  </Button>
                                ) : (
                                  <div />
                                )}
                              </div>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onCancel} color="primary">
                        {t('stepper_dialog.cancel_bt')}
                      </Button>
                      <Button
                        disabled={!this.areAllStepsCompleted()}
                        onClick={onSubmit}
                        color="primary"
                      >
                        {t('stepper_dialog.save_bt')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </WithWidth>
            )}
          </I18n>
        )}
      </Styled>
    );
  }

  private arePreviousStepsCompleted(index: number) {
    const prevSteps = this.props.steps.slice(0, index);

    return prevSteps.every(step => step.completed);
  }

  private areAllStepsCompleted() {
    return this.arePreviousStepsCompleted(this.props.steps.length);
  }
}
