import * as React from 'react';
import {I18n} from 'react-i18next';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import {createStyled} from '../../../style';
import {ISelect} from '../ChartDialog';

interface IProps {
  /**
   * The item to be edited.
   */
  column: ISelect;

  defaultAlias: string;

  /**
   * A list of existing aliases, which will be rejected as a new value for the alias.
   */
  aliases: string[];

  /**
   * Invoked whenever the user changes some value, even when just changing a single letter while not
   * yet closing the dialog.
   */
  onChange: (value: ISelect) => void;

  /**
   * Invoked when the user saves the changes using the keyboard. The user may also click anywhere
   * outside this dialog to achieve the same, which does not invoke this callback.
   */
  onDone: (value: ISelect) => void;

  onDelete: (value: ISelect) => void;
}

interface IState {
  column: ISelect;
  validAlias: boolean;
}

const Styled = createStyled(theme => ({
  formControl: {
    // Allow for long text such as "Number of transactions label"
    minWidth: '275px',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },
  actionsContainer: {
    paddingRight: 0.8 * theme.spacing.unit,
  },
  actionIcon: {
    marginTop: 0.8 * theme.spacing.unit,
    marginBottom: 0.8 * theme.spacing.unit,
    marginLeft: 0.4 * theme.spacing.unit,
    marginRight: 0.4 * theme.spacing.unit,
    padding: 0.8 * theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    flexGrow: 1,
  },
  list: {
    width: '100%',
  },
}));

/**
 * Editor for report column properties, such as alias (label).
 */
export default class ColumnEditDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const {column} = props;
    this.state = {column, validAlias: true};
  }

  public handleAliasKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      const {column} = this.state;
      this.props.onDone(column);
    }
  };

  private handleAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {aliases} = this.props;
    // The API only allows `^((?!\.).)*$`, hence does not allow for any dot
    const value = event.target.value.replace(/\./g, ' ');
    const validAlias = aliases.map(s => s.toLowerCase()).indexOf(value.toLowerCase()) === -1;

    this.setState((state, props) => {
      const {column} = state;
      column.alias = value;
      // Invoke the callback even when not valid, so the duplicate items can be highlighted
      props.onChange(column);
      return {column, validAlias};
    });
  };

  private handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onDelete(this.state.column);
  };

  public render() {
    const {column, validAlias} = this.state;
    const {defaultAlias} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <React.Fragment>
                {/* Alias/label input */}
                <FormControl className={classes.formControl}>
                  <TextField
                    label={`${defaultAlias} ${t('filter.label_suffix')}`}
                    error={!validAlias}
                    className={classes.formControl}
                    autoFocus={true}
                    value={typeof column.alias === 'undefined' ? defaultAlias : column.alias}
                    variant="outlined"
                    onChange={this.handleAliasChange}
                    onKeyDown={this.handleAliasKeyDown}
                  />
                </FormControl>
                <Grid
                  container={true}
                  justify="flex-start"
                  spacing={0}
                  className={classes.actionsContainer}
                >
                  {/* Delete button */}
                  <Grid item={true}>
                    <Tooltip title={t('column_edit_dialog.delete_bt')}>
                      <Button color="primary" onClick={this.handleDeleteClick}>
                        <DeleteIcon />
                        <Typography align="left" variant="caption">
                          {t('column_edit_dialog.delete_bt')}
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
