import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {I18n} from 'react-i18next';

import {createStyled} from '../../../style/';
import {price} from '../../../formatters';
import {ApiClient, IChart} from '../../../api';
import {i18n} from '../../../i18n';
import BarChartCmp from '../resultRenderers/BarChart';
import {TableFooter} from '@material-ui/core';
import {timeFrameColors} from '../../TimeFrameSelector';
import cleanNumber from '../../../formatters/cleanNumber';

const Styled = createStyled(theme => {
  return {
    headerCell: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff',
    },
    footerCell: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff',
    },
    numberBox: {
      border: '2px solid black',
      padding: theme.spacing.unit,
      paddingRight: theme.spacing.unit * 4,
    },
    formControl: {
      minWidth: '100px',
      flexGrow: 1,
    },
    flexSection: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    tableWrapper: {
      maxHeight: 90 * theme.spacing.unit,
    },
    metricCell: {
      textAlign: 'right',
      paddingRight: theme.spacing.unit * 3,
      paddingLeft: '0px',
    },
    chartWrapper: {
      padding: `5px ${theme.spacing.unit * 3}px`,
      paddingBottom: theme.spacing.unit * 2,
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
      overflow: 'hidden',
    },
    tableContainer: {
      flex: 1,
      flexGrow: 10,
      overflowY: 'auto',
      minHeight: '100%',
    },
    chartContainer: {
      flex: 1,
      flexGrow: 7,
      minHeight: 50 * theme.spacing.unit,
      maxHeight: 90 * theme.spacing.unit,
      width: 30 * theme.spacing.unit,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    firstCell: {
      paddingLeft: 0,
    },
  };
});

export type RevenueGroupedResult = Array<{
  xAxis: string;
  yAxis: string[];
  currencies: {
    [key: string]: {
      dataSet: any[];
      totalQuantity: number;
      totalRevenue: number;
    };
  };
}>;

type SelectableMetric = 'quantity' | 'revenue';
interface IProps {
  chart: IChart;
  results: RevenueGroupedResult;
}
interface IState {
  selectedMetric: SelectableMetric;
}

export default class RevenueGrouped extends React.Component<IProps, IState> {
  private tableData: any[];
  private barChartData: any[];
  private totals: any[];
  private xAxis: string;

  constructor(props: IProps) {
    super(props);

    this.tableData = [];
    this.barChartData = [];
    this.totals = [];
    this.xAxis = '';

    this.state = {
      selectedMetric: 'quantity',
    };

    /**
     * Turns the results into a flat array of objects.
     * Also, it adds suffixes to the metric properties,
     * just so we can still show the timeframes separetly
     */
    props.results.forEach((timeFrameResult, timeFrameIndex) => {
      this.xAxis = timeFrameResult.xAxis;
      const timeFrameData: any[] = [];
      const timeFrameTotal: any[] = [];

      Object.keys(timeFrameResult.currencies).forEach(c => {
        // Process data
        timeFrameResult.currencies[c].dataSet.forEach(d => {
          timeFrameData.push({
            [this.xAxis]: d[this.xAxis],
            [`quantity${timeFrameIndex}`]: d.quantity,
            [`revenue${timeFrameIndex}`]: d.revenue,
            currency: c,
          });
        });

        // Process totals
        timeFrameTotal.push({
          [`quantity${timeFrameIndex}`]: timeFrameResult.currencies[c].totalQuantity,
          [`revenue${timeFrameIndex}`]: timeFrameResult.currencies[c].totalRevenue,
          currency: c,
        });
      });

      this.totals.push(timeFrameTotal);
      this.barChartData.push(timeFrameData);
    });

    // Sort data descending
    this.barChartData.forEach(fr => {
      fr.sort(
        (a: {quantity0: number}, b: {quantity0: number}) => (a.quantity0 < b.quantity0 ? 1 : -1)
      );
    });

    let mergedData: any[] = [];
    let mergedTotals: any[] = [];

    // A single product can appear in both timeframes for the same currency,
    // in that case we want to group them into a single row and add up the numbers
    this.barChartData.forEach((timeFrameData: any, i: number) => {
      if (i === 0) {
        mergedData = timeFrameData.map((d: any) => d);

        return;
      }

      timeFrameData.map((d: any) => {
        const itemIndex = mergedData.findIndex(md => {
          return md.productname === d.productname;
        });

        if (itemIndex === -1) {
          mergedData.push(d);
        } else {
          mergedData[itemIndex] = Object.assign(mergedData[itemIndex], d);
        }
      });
    });

    // Adds up the totals from both timeframes grouped by currency
    this.totals.forEach((timeFrameTotal: any, i: number) => {
      if (i === 0) {
        mergedTotals = timeFrameTotal.map((d: any) => d);

        return;
      }

      timeFrameTotal.map((t: any) => {
        const itemIndex = mergedTotals.findIndex(mt => {
          return t.currency === mt.currency;
        });

        if (itemIndex === -1) {
          mergedTotals.push(t);
        } else {
          mergedTotals[itemIndex] = Object.assign(mergedTotals[itemIndex], t);
        }
      });
    });

    this.tableData = [...mergedData];
    this.totals = [...mergedTotals];
  }

  public handleXPropertyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({selectedMetric: event.target.value as SelectableMetric});
  };

  public render() {
    const {results} = this.props;
    const {selectedMetric} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <div className={`${classes.chartWrapper} pageBreakOnPrint`}>
                <div className={`${classes.tableContainer} fullHeightOnPrint`}>
                  <Table className={classes.tableWrapper}>
                    {/* Sticky header */}
                    <TableHead>
                      <TableRow>
                        <TableCell className={`${classes.headerCell} ${classes.firstCell}`}>
                          {t(`chart.default_charts.revenue_grouped.${this.xAxis}`)}
                        </TableCell>
                        {/* Metric headers */}
                        {results.map((r, i) => (
                          <React.Fragment key={i}>
                            <TableCell
                              className={`${classes.headerCell} ${classes.metricCell}`}
                              style={{color: timeFrameColors[i]}}
                            >
                              {t(`chart.default_charts.revenue_grouped.quantity`)}
                            </TableCell>
                            <TableCell
                              className={`${classes.headerCell} ${classes.metricCell}`}
                              style={{color: timeFrameColors[i]}}
                            >
                              {t(`chart.default_charts.revenue_grouped.revenue`)}
                            </TableCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Data rows */}
                    <TableBody>
                      {this.tableData.map((d, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row" className={classes.firstCell}>
                            {d[this.xAxis]}
                          </TableCell>
                          {/* Metric data */}
                          {results.map((r, j) => (
                            <React.Fragment key={j}>
                              <TableCell component="th" scope="row" className={classes.metricCell}>
                                {cleanNumber(d['quantity' + j]) || 0}
                              </TableCell>
                              <TableCell component="th" scope="row" className={classes.metricCell}>
                                {`${price(d['revenue' + j] || 0)} ${d.currency}`}
                              </TableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* Sticky footer */}
                    <TableFooter>
                      <TableRow>
                        <TableCell className={`${classes.footerCell} ${classes.firstCell}`}>
                          {t(`chart.default_charts.revenue_grouped.total`)}
                        </TableCell>

                        {/* Totals metric columns */}
                        {results.map((timeFrame: any, j: number) => (
                          <React.Fragment key={j}>
                            {['quantity', 'revenue'].map((propertyName, i) => (
                              <TableCell
                                key={i}
                                className={`${classes.footerCell} ${classes.metricCell}`}
                              >
                                {this.totals.map((total, k) => (
                                  <Grid
                                    container={true}
                                    key={i + k}
                                    direction={'column'}
                                    spacing={16}
                                  >
                                    <Grid item={true}>
                                      {propertyName === 'revenue'
                                        ? `${price(total[propertyName + j] || 0)}\u00a0${
                                            total.currency
                                          }`
                                        : cleanNumber(total[propertyName + j]) || 0}
                                    </Grid>
                                  </Grid>
                                ))}
                              </TableCell>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
                <div className={`${classes.chartContainer} hideOnPrint`}>
                  <BarChartCmp
                    data={this.tableData}
                    xProperty={this.xAxis}
                    bars={generateBarsData(results.length, selectedMetric)}
                    yAxisLabelFormat={cleanNumber}
                  />
                </div>
              </div>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}

function generateBarsData(numberOfLines: number, propertyName: string) {
  return Array.from({length: numberOfLines}).map((it, i) => {
    return {
      dataKey: `${propertyName}${i}`,
      label: i18n.t('chart.default_charts.revenue_grouped.quantity'),
    };
  });
}
