import * as React from 'react';
import moment from 'moment-timezone';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {I18n} from 'react-i18next';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FilterListIcon from '@material-ui/icons/FilterList';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Search from '@material-ui/icons/Search';

import {readableDateFormatMoment, timeWithSecondFormat} from '../../lib/dateUtils';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  ApiClient,
  getItemsByResource,
  IReportCriteria,
  findUserReportById,
  IReportData,
} from '../../api';
import {createStyled} from '../../style';
import {i18n} from '../../i18n';
import TimeFrameSelector, {
  formatTimeFrameToValues,
  generateTimeframeData,
} from '../TimeFrameSelector';
import {ListSubheader, TextField, InputAdornment} from '@material-ui/core';
import GoogleAnalytics from '../../GoogleAnalytics';
import {TimeFrame} from '../ChartDialog/ChartDialog';

const getItemsByResourceDebounced = AwesomeDebouncePromise(getItemsByResource, 500);
const Styled = createStyled(theme => {
  return {
    popoverWrapper: {
      padding: 3 * theme.spacing.unit,
      flexGrow: 1,
      maxWidth: theme.spacing.unit * 100,
    },
    button: {
      fontSize: '12px',
      letterSpacing: '0.5px',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
        flex: 1,
      },
    },
    leftIcon: {
      marginRight: theme.spacing.unit * 2.5,
      fontSize: '22px',
    },
    noReportWrapper: {
      textAlign: 'center',
    },
    formControl: {
      width: '100%',
    },
    formControlSearch: {
      width: '100%',
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
    filterButton: {
      display: 'inline-flex',
      padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 3}px`,
      marginRight: theme.spacing.unit * 1.5,
      verticalAlign: 'middle',
      minHeight: '59px',
      lineHeight: '1.5',
      minWidth: theme.spacing.unit * 8,
      cursor: 'pointer',
      alignItems: 'center',
      color: '#338357',
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 'auto',
        marginBottom: theme.spacing.unit,
        marginTop: theme.spacing.unit,
      },
    },
    comparisonText: {
      color: '#faaf3b',
    },
    listItems: {
      maxHeight: theme.spacing.unit * 19,
      overflowY: 'scroll',
    },
    extra: {
      color: '#a3a6a8',
      fontSize: '11px',
    },
    neutral: {
      color: '#a3a6a8',
    },
    title: {
      fontSize: '13px',
      paddingBottom: theme.spacing.unit / 4,
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      height: 190,
      overflow: 'auto',
      borderRadius: theme.spacing.unit / 2,
      border: '1px solid #ccced1',
    },
    loaderContainer: {
      width: '100%',
      textAlign: 'center',
    },
    filterGridContainer: {
      // paddingBottom: theme.spacing.unit * 5,
    },
    radio: {
      paddingTop: theme.spacing.unit / 1.5,
      paddingBottom: theme.spacing.unit / 1.5,
    },
    buttonsBlock: {
      marginTop: 2 * theme.spacing.unit,
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'wrap',
      },
    },
    actionsBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'wrap',
      },
    },
    buttons: {
      flexGrow: 1,
    },
    groupedButtons: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
    resourceItemsAction: {
      color: '#338357',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  };
});

const resourceTypes = [
  {
    name: 'machine',
    value: 'machine',
  },
  {
    name: 'location',
    value: 'location',
  },
  {
    name: 'locationgroup',
    value: 'locationgroup',
  },
  {
    name: 'machinegroup',
    value: 'machinegroup',
  },
];

export interface IReport {
  id: number;
  name: string;
  isDraft: boolean;
}
interface IReportFilterState {
  showReportDatePopOver: boolean;
  showReportFilterPopOver: boolean;
  anchorEl: HTMLElement | null;
  resourceType: string;
  items?: any[];
  selectedItems: any[];
  withComparison: boolean;
  startDate?: Date;
  endDate?: Date;
  periodType: string;
  trailing?: number;
  startDatePeriod2?: Date;
  endDatePeriod2?: Date;
  periodTypePeriod2?: string;
  trailingPeriod2?: number;
  referenceDatePeriod2?: string;
  dateTitle: JSX.Element | string;
  filterTitle: JSX.Element | string;
  reportCriteria: IReportCriteria;
  searchValue: string | undefined;
  requestInFlight: boolean;
  selectedOnly: boolean;
}
interface IProps {
  api: ApiClient;
  ga: GoogleAnalytics;
  report?: IReportData | null;
  onSaveFilter: (criteria: IReportCriteria) => void;
}

const WORD_BREAK_LIMIT = 30;
export default class ReportFilter extends React.Component<IProps, IReportFilterState> {
  private _isMounted = false;

  constructor(props: IProps) {
    super(props);

    const {report} = props;

    this.state = {
      showReportDatePopOver: false,
      showReportFilterPopOver: false,
      anchorEl: null,
      resourceType: 'locationgroup',
      selectedItems: [],
      withComparison: false,
      periodType: 'fixed date',
      dateTitle: 'Date',
      filterTitle: this.generateAllDataTitle(),
      reportCriteria: report && report.criteria ? report.criteria : {},
      searchValue: '',
      requestInFlight: false,
      selectedOnly: false,
    };
  }
  // TODO: State design pattern might be used here to reduce the nested if complexity
  public componentDidMount = async () => {
    this._isMounted = true;
    const {report: userReport} = this.props;

    if (userReport && userReport.criteria) {
      if (userReport.criteria.timeFrames) {
        const newState = formatTimeFrameToValues(userReport.criteria.timeFrames);

        if (this._isMounted) {
          this.setState(prevState => {
            const dateTitle = this.generateDateTitle({...prevState, ...newState});

            return {...prevState, ...newState, dateTitle};
          });
        }
      }

      if (userReport.criteria.where) {
        const [where] = userReport.criteria.where;

        if (where && Array.isArray(where.value) && this._isMounted) {
          this.setState({requestInFlight: true});
          const selectedItemIds = where.value;

          const topFiveSelectedItems = await this.fetchTopFiveItems(where.column, selectedItemIds);

          const filterTitle = this.generateFilterTitle({
            resourceType: where.column,
            topFiveSelectedItems,
            selectedItemsCount: selectedItemIds.length,
          });

          this.setState({filterTitle, requestInFlight: false});
        } else {
          const filterTitle = this.generateAllDataTitle();

          if (this._isMounted) {
            this.setState({filterTitle});
          }
        }
      }
    }
  };

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public handleReportDateClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    const {api, report} = this.props;

    this.setState({anchorEl: event.currentTarget});

    if (report) {
      const userReport = await findUserReportById(api, report.id);

      if (userReport && userReport.criteria && userReport.criteria.timeFrames) {
        const newState = formatTimeFrameToValues(userReport.criteria.timeFrames);
        const withComparison = userReport.criteria.timeFrames.length > 1;

        this.setState(prevState => {
          return {
            ...prevState,
            ...newState,
            showReportDatePopOver: true,
            withComparison,
          };
        });
      }
    }
  };

  public handleReportDateClose = () => {
    this.setState({anchorEl: null, showReportDatePopOver: false});
  };

  public handleReportFilterClick = async (
    event: React.MouseEvent<HTMLDivElement>,
    api: ApiClient
  ) => {
    const {report} = this.props;

    // We call setState twice here, because, for some reason,
    // event.currentTarget might be gone after the async call
    this.setState({anchorEl: event.currentTarget, showReportFilterPopOver: true});
    let items;

    if (report) {
      const userReport = await findUserReportById(api, report.id);

      if (userReport && userReport.criteria && userReport.criteria.where) {
        const [where] = userReport.criteria.where;

        if (where && Array.isArray(where.value)) {
          this.setState({
            resourceType: where.column,
            selectedItems: [...where.value].filter(v => typeof v === 'number'),
          });
        } else {
          // Means `where` criteria is empty
          this.setState({
            resourceType: this.state.resourceType,
            selectedItems: [],
          });
        }
      }
    }

    if (this.state.items === undefined) {
      this.setState({requestInFlight: true});

      items = await getItemsByResource(api, {resource: this.state.resourceType});
    }

    this.setState({items: items || this.state.items, requestInFlight: false});
  };

  public handleReportFilterClose = () => {
    this.setState({
      anchorEl: null,
      showReportFilterPopOver: false,
      items: undefined,
      searchValue: '',
    });
  };

  public handleCancelClickCriteria = (criteriaType: 'date' | 'filter') => {
    if (criteriaType === 'date') {
      return this.setState({
        showReportDatePopOver: false,
      });
    }

    this.setState({
      showReportFilterPopOver: false,
      items: undefined,
      searchValue: '',
    });
  };

  public handleResourceTypeChange = async (event: any, api: ApiClient) => {
    this.setState({resourceType: event.target.value, requestInFlight: true});
    const items = await getItemsByResource(api, {resource: event.target.value});

    this.setState({
      items,
      selectedItems: [],
      searchValue: '',
      requestInFlight: false,
      selectedOnly: false,
    });
  };

  public handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const {resourceType} = this.state;
    const {api} = this.props;

    this.setState({searchValue: event.target.value, requestInFlight: true});

    const search = event.target.value.trim();
    const items = await getItemsByResourceDebounced(api, {
      resource: resourceType,
      property: 'name',
      search,
    });

    this.setState({items, requestInFlight: false, selectedOnly: false});
  };

  /**
   * Generates title view filtered by resource type
   *
   * @param filterTitleViewModel
   * @returns {JSX.Element}
   */
  public generateFilterTitle = (filterTitleViewModel: {
    resourceType: string;
    topFiveSelectedItems: any[];
    selectedItemsCount: number;
  }) => {
    const {resourceType, topFiveSelectedItems, selectedItemsCount} = filterTitleViewModel;
    const concatItems = topFiveSelectedItems.map(item => item.name || item).join(', ');

    const itemDescription =
      concatItems.length > WORD_BREAK_LIMIT
        ? `${concatItems.substring(0, WORD_BREAK_LIMIT)}...`
        : concatItems;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <Grid container={true} direction={'column'}>
                <Grid item={true} className={classes.title}>
                  {t('report.filter.filter_by', {
                    count: selectedItemsCount,
                    resource: t(`report.filter.resources.${resourceType}`, {
                      count: selectedItemsCount,
                    }),
                  })}
                </Grid>
                <Grid className={classes.extra} item={true}>
                  {itemDescription}
                </Grid>
              </Grid>
            )}
          </I18n>
        )}
      </Styled>
    );
  };

  /**
   * Generates default title view which is all data
   *
   * @returns {JSX.Element}
   */
  private generateAllDataTitle() {
    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <Grid container={true} direction={'column'}>
                <Grid item={true}>{t('report.filter.all_data')}</Grid>
                <Grid className={classes.extra} item={true}>
                  {t('report.filter.no_filters')}
                </Grid>
              </Grid>
            )}
          </I18n>
        )}
      </Styled>
    );
  }

  public generatePeriodTitle = (
    periodType: string,
    {startDate, endDate, trailing}: {startDate?: Date; endDate?: Date; trailing?: number}
  ) => {
    if (periodType === 'fixed date') {
      return (
        <Styled>
          {({classes}) => (
            <Grid container={true} spacing={8}>
              <Grid item={true}>
                <Grid container={true} direction={'column'}>
                  <Grid item={true} className={classes.title}>
                    {moment(startDate).format(readableDateFormatMoment)}
                  </Grid>
                  <Grid className={classes.extra} item={true}>
                    {moment(startDate).format(timeWithSecondFormat)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} className={classes.title}>
                -
              </Grid>
              <Grid item={true}>
                <Grid container={true} direction={'column'}>
                  <Grid item={true} className={classes.title}>
                    {moment(endDate).format(readableDateFormatMoment)}
                  </Grid>
                  <Grid className={classes.extra} item={true}>
                    {moment(endDate).format(timeWithSecondFormat)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Styled>
      );
    }

    if (periodType === 'trailing') {
      return i18n.t(`chart.footer.trailing_timeframe`, {hours: trailing || 0});
    }

    return i18n.t(`date.period_type.${periodType}`);
  };

  public handleApplyDate = () => {
    const {onSaveFilter, ga} = this.props;
    const {
      periodType,
      reportCriteria,
      startDate,
      endDate,
      trailing,
      periodTypePeriod2,
      startDatePeriod2,
      endDatePeriod2,
      trailingPeriod2,
      referenceDatePeriod2,
    } = this.state;

    const timeFrames: TimeFrame[] = [
      generateTimeframeData(periodType, {startDate, endDate, trailing}),
    ];

    ga.trackEvent({
      category: 'New Reports',
      action: 'Select Date range',
      label: periodType,
    });

    if (this.state.withComparison) {
      timeFrames.push(
        generateTimeframeData(periodTypePeriod2 || 'fixed date', {
          startDate: startDatePeriod2,
          endDate: endDatePeriod2,
          trailing: trailingPeriod2,
          period1Type: periodType,
          isPeriod2: true,
          referenceDatePeriod2,
        })
      );

      ga.trackEvent({
        category: 'New Reports',
        action: 'Compare Date range',
        label: periodTypePeriod2 || 'fixed date',
      });
    }

    const dateTitle = this.generateDateTitle(this.state);
    const newReportCriteria = {...reportCriteria, timeFrames};

    onSaveFilter({timeFrames});

    this.setState({
      dateTitle,
      anchorEl: null,
      showReportDatePopOver: false,
      reportCriteria: newReportCriteria,
    });
  };

  public generateDateTitle = (values: IReportFilterState) => {
    const {
      periodType,
      startDate,
      endDate,
      trailing,
      periodTypePeriod2,
      startDatePeriod2,
      endDatePeriod2,
      trailingPeriod2,
      withComparison,
    } = values;

    const dateTitlePeriod1 = this.generatePeriodTitle(periodType, {startDate, endDate, trailing});

    let dateTitlePeriod2: string | JSX.Element = '';

    if (withComparison) {
      dateTitlePeriod2 = this.generatePeriodTitle(periodTypePeriod2 || 'fixed date', {
        startDate: startDatePeriod2,
        endDate: endDatePeriod2,
        trailing: trailingPeriod2,
      });
    }

    return (
      <Styled>
        {({classes}) => (
          <Grid container={true} spacing={8}>
            <Grid item={true} className={classes.title}>
              {dateTitlePeriod1}
            </Grid>
            {dateTitlePeriod2 && (
              <>
                <Grid className={`${classes.neutral} ${classes.title}`} item={true}>
                  vs
                </Grid>
                <Grid className={`${classes.comparisonText} ${classes.title}`} item={true}>
                  {dateTitlePeriod2}
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Styled>
    );
  };

  public isItemSelected = () => {
    return this.state.selectedItems.length > 0;
  };

  public handleApplyFilter = async () => {
    const {onSaveFilter, ga} = this.props;
    const {reportCriteria, resourceType, selectedItems} = this.state;

    this.setState({requestInFlight: true});

    const selectedItemsCount = selectedItems.length;
    const topFiveSelectedItems = await this.fetchTopFiveItems(resourceType, selectedItems);
    const filterTitle = this.generateFilterTitle({
      resourceType,
      topFiveSelectedItems,
      selectedItemsCount,
    });
    const whereCriteria = [
      {
        column: resourceType,
        operator: 'in',
        value: selectedItems,
      },
    ];

    ga.trackEvent({
      category: 'New Reports',
      action: 'Filter',
      label: `${resourceType} (${selectedItemsCount})`,
    });

    const newReportCriteria = {...reportCriteria, where: whereCriteria};
    onSaveFilter({where: whereCriteria});
    this.setState({
      filterTitle,
      anchorEl: null,
      showReportFilterPopOver: false,
      reportCriteria: newReportCriteria,
      requestInFlight: false,
      items: undefined,
      searchValue: '',
    });
  };

  private fetchTopFiveItems(resourceType: string, selectedItemIds: any[]) {
    return getItemsByResource(this.props.api, {
      resource: resourceType,
      criteria: {
        where: {
          // We just need a few machines,
          // as we will not show the names of all the selected machines
          id: selectedItemIds.slice(0, 5),
        },
      },
    });
  }

  public handleClearFilter = () => {
    const {onSaveFilter, ga} = this.props;
    const {reportCriteria} = this.state;
    const newReportCriteria = {...reportCriteria, where: []};

    ga.trackEvent({
      category: 'New Reports',
      action: 'Filter',
      label: 'No filters',
    });

    onSaveFilter({where: []});
    this.setState({
      filterTitle: this.generateAllDataTitle(),
      anchorEl: null,
      showReportFilterPopOver: false,
      reportCriteria: newReportCriteria,
      items: undefined,
      searchValue: '',
    });
  };

  public handleCheckboxChange = (value: any) => {
    const {selectedItems} = this.state;
    const selectedId = Number(value);
    const currentIndex = selectedItems.indexOf(selectedId);

    const newSelectedItems = [...selectedItems];

    if (currentIndex === -1) {
      newSelectedItems.push(selectedId);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  public onDateChange = (values: {
    periodType: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    trailing: number | undefined;
    withComparison: boolean;
    periodTypePeriod2: string | undefined;
    startDatePeriod2: Date | undefined;
    endDatePeriod2: Date | undefined;
    trailingPeriod2: number | undefined;
  }) => {
    this.setState(values);
  };

  public handleSelectAll = () => {
    const {items} = this.state;

    if (items) {
      this.setState({selectedItems: items.map(i => i.id)});
    }
  };

  public handleUnSelectAll = () => {
    this.setState({selectedItems: [], selectedOnly: false});
  };

  private getResourceItems = () => {
    const {items, selectedOnly, selectedItems} = this.state;

    if (!items) {
      return [];
    }

    if (!selectedOnly) {
      return items;
    }

    return items.filter(i => {
      return selectedItems.indexOf(i.id) > -1;
    });
  };

  public render() {
    const {
      showReportDatePopOver,
      anchorEl,
      showReportFilterPopOver,
      resourceType,
      selectedItems,
      requestInFlight,
    } = this.state;
    const {api, report} = this.props;

    if (!report) {
      return (
        <Styled>
          {({classes}) => (
            <div className={classes.noReportWrapper}>
              <CircularProgress disableShrink={true} />
            </div>
          )}
        </Styled>
      );
    }

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <>
                {/* TimeFrame Button */}
                <Paper onClick={this.handleReportDateClick} className={classes.filterButton}>
                  <CalendarTodayIcon className={classes.leftIcon} />
                  {this.state.dateTitle}
                </Paper>

                {/* TimeFrame Popover */}
                <Popover
                  open={showReportDatePopOver}
                  anchorEl={anchorEl}
                  onClose={this.handleReportDateClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className={classes.popoverWrapper}>
                    <Grid container={true} alignItems={'flex-start'}>
                      {/* Body */}
                      <TimeFrameSelector
                        onChange={this.onDateChange}
                        timeFrames={
                          report.criteria && report.criteria.timeFrames
                            ? report.criteria.timeFrames
                            : []
                        }
                      />
                      {/* Footer */}
                      <Grid
                        className={classes.buttonsBlock}
                        container={true}
                        alignItems="center"
                        justify="flex-end"
                        spacing={40}
                      >
                        <Grid item={true}>
                          <Button
                            className={classes.button}
                            variant="contained"
                            onClick={this.handleCancelClickCriteria.bind(this, 'date')}
                          >
                            {t('report.filter.cancel_bt')}
                          </Button>
                        </Grid>
                        <Grid item={true}>
                          <Button
                            className={classes.button}
                            onClick={this.handleApplyDate}
                            variant="contained"
                            color="primary"
                          >
                            {t('report.filter.apply_bt')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Popover>

                {/* Filter Button */}
                <Paper
                  onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                    this.handleReportFilterClick(e, api)
                  }
                  className={classes.filterButton}
                >
                  <FilterListIcon className={classes.leftIcon} />
                  <span>{this.state.filterTitle}</span>
                </Paper>
                {/* Filter Popover */}
                <Popover
                  open={showReportFilterPopOver}
                  anchorEl={anchorEl}
                  onClose={this.handleReportFilterClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className={classes.popoverWrapper}>
                    <Grid container={true} alignItems={'flex-start'}>
                      <Grid item={true} xs={12} className={classes.title}>
                        <Typography variant="subtitle2">
                          {t('report.filter.filter_by_title')}
                        </Typography>
                      </Grid>
                      {/* Body (column 1) */}
                      <Grid item={true} xs={12} sm={4}>
                        <Grid
                          container={true}
                          alignItems={'flex-start'}
                          spacing={32}
                          className={classes.filterGridContainer}
                        >
                          <Grid item={true} xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <RadioGroup
                                name="resource"
                                value={resourceType}
                                onChange={e => this.handleResourceTypeChange(e, api)}
                              >
                                {resourceTypes.map(r => (
                                  <FormControlLabel
                                    key={r.name}
                                    value={r.value}
                                    control={<Radio color="primary" className={classes.radio} />}
                                    label={t(`report.filter.resources.${r.name}`)}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Body (column 2) */}
                      <Grid item={true} xs={12} sm={8}>
                        <Grid
                          container={true}
                          alignItems={'flex-start'}
                          className={classes.filterGridContainer}
                        >
                          <Grid item={true} xs={12} style={{padding: '0'}}>
                            {
                              <List
                                className={classes.list}
                                disablePadding={true}
                                subheader={
                                  <ListSubheader component="div" id="nested-list-subheader">
                                    <TextField
                                      placeholder={t('filter.search_placeholder')}
                                      className={classes.formControlSearch}
                                      autoFocus={true}
                                      onChange={this.handleSearch}
                                      value={this.state.searchValue}
                                      disabled={
                                        !this.state.searchValue &&
                                        (!this.state.items || !this.state.items.length)
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <Search />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ListSubheader>
                                }
                              >
                                {!requestInFlight &&
                                  this.state.items &&
                                  this.getResourceItems().map(i => (
                                    <ListItem
                                      key={i.id}
                                      button={true}
                                      onClick={() => this.handleCheckboxChange(i.id)}
                                      className={classes.listItem}
                                      disableGutters={true}
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          color="primary"
                                          checked={selectedItems.indexOf(i.id) > -1}
                                          className={classes.radio}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary={i.name} disableTypography={true} />
                                    </ListItem>
                                  ))}
                                {requestInFlight && (
                                  <div className={classes.loaderContainer}>
                                    <CircularProgress disableShrink={true} />
                                  </div>
                                )}
                              </List>
                            }
                          </Grid>
                          <Grid
                            className={classes.actionsBlock}
                            container={true}
                            alignItems="center"
                            justify="flex-end"
                            spacing={40}
                          >
                            <Grid item={true}>
                              <span
                                className={classes.resourceItemsAction}
                                onClick={() => this.setState({selectedOnly: true})}
                              >
                                {t(`report.filter.selected`, {
                                  selection: this.state.selectedItems.length,
                                })}
                              </span>

                              {this.state.selectedOnly && (
                                <>
                                  &nbsp;/&nbsp;
                                  <span
                                    className={classes.resourceItemsAction}
                                    onClick={() => this.setState({selectedOnly: false})}
                                  >
                                    {t(`report.filter.show_all`)}
                                  </span>
                                </>
                              )}
                            </Grid>
                            <Grid item={true}>
                              <span
                                className={classes.resourceItemsAction}
                                onClick={this.handleSelectAll}
                              >
                                {t(`report.filter.select_all`)}
                              </span>{' '}
                              /{' '}
                              <span
                                className={classes.resourceItemsAction}
                                onClick={this.handleUnSelectAll}
                              >
                                {t(`report.filter.unselect_all`)}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Footer */}
                      <Grid
                        className={classes.buttonsBlock}
                        container={true}
                        alignItems="center"
                        justify="flex-end"
                        spacing={40}
                      >
                        <Grid item={true}>
                          <Button
                            className={`${classes.buttons} ${classes.button}`}
                            variant="contained"
                            onClick={this.handleClearFilter}
                          >
                            {t('report.filter.clear_filter')}
                          </Button>
                        </Grid>
                        <Grid item={true}>
                          <Grid container={true} spacing={16}>
                            <Grid item={true}>
                              <Button
                                className={classes.button}
                                variant="contained"
                                onClick={this.handleCancelClickCriteria.bind(this, 'filter')}
                              >
                                {t('report.filter.cancel_bt')}
                              </Button>
                            </Grid>
                            <Grid item={true}>
                              <Button
                                className={classes.button}
                                onClick={this.handleApplyFilter}
                                variant="contained"
                                color="primary"
                                disabled={!this.isItemSelected()}
                              >
                                {t('report.filter.apply_bt')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Popover>
              </>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
