import * as React from 'react';
import {I18n} from 'react-i18next';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {createStyled} from '../../style/index';
import {getSelectKey} from '../ChartDialog/properties';

interface IProps {
  columns: Array<{name?: string; column: string}>;
  initialValue?: string;
  onChange: (value: string) => void;
}

const Styled = createStyled({
  formControl: {
    flexBasis: '100%',
    minWidth: '100px',
  },
});

export default class Field extends React.Component<IProps, {}> {
  public handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {onChange} = this.props;

    onChange(event.target.value);
  };

  public render() {
    const {initialValue, columns} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="field">{t('filter.field_label')}</InputLabel>
                <Select id="field" value={initialValue} onChange={this.handleOnChange}>
                  {columns.map(option => (
                    <MenuItem key={getSelectKey(option)} value={getSelectKey(option)}>
                      {t(`filter.properties.${(option.name || option.column).replace('.', ' ')}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
