import * as React from 'react';
import {I18n} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

interface IProps {
  title: string;
  text: string;
  open: boolean;
  onOkClick?: () => void;
  onCancelClick?: () => void;
}

interface IState {
  dialogOpen: boolean;
}

export default class ConfirmDialog extends React.Component<IProps, IState> {
  public handleCancel = () => {
    const {onCancelClick} = this.props;

    if (onCancelClick) {
      onCancelClick();
    }

    this.setState({dialogOpen: false});
  };

  public handleOk = async () => {
    const {onOkClick} = this.props;

    if (onOkClick) {
      onOkClick();
    }

    this.setState({dialogOpen: false});
  };

  public render() {
    const {open, title, text} = this.props;

    return (
      <I18n>
        {t => (
          <Dialog
            open={open}
            aria-labelledby="confirmation-title"
            aria-describedby="confirmation-description"
          >
            {/* Dialog title */}
            <DialogTitle id="confirmation-title">{title}</DialogTitle>
            {/* Dialog content */}
            <DialogContent>
              <DialogContentText id="confirmation-description">{text}</DialogContentText>
            </DialogContent>
            {/* Dialog actions*/}
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                {t('confirm_dialog.cancel_bt')}
              </Button>
              <Button onClick={this.handleOk} color="primary">
                {t('confirm_dialog.ok_bt')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </I18n>
    );
  }
}
