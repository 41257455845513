import parse from 'relative-datetime-como';
import moment from 'moment-timezone';

interface IRange {
  year: number | null;
  month: number | null;
  day: number | null;
  week: number | null;
  hour?: number;
  minute?: number;
  second?: number;
}

export default function dateRangeToString(
  range?: IRange,
  referenceDate?: Date | string
): Date | string {
  if (!range) {
    return '';
  }

  if (typeof range === 'string') {
    return range;
  }

  if (!referenceDate) {
    referenceDate = moment().format();
  }

  // Remove ISO chars on order to have the local time
  // If we use moment() to parse the time, DST changes will apply (23:59 -> 00:59 next day)
  const parsedDate = parse(range, referenceDate);
  const formattedDate = parsedDate.replace('T', ' ').split('+')[0];

  return formattedDate;
}
