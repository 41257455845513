import * as React from 'react';
import {I18n} from 'react-i18next';
import Downshift from 'downshift';

import TextField from '@material-ui/core/TextField';

import {createStyled} from '../../style';
import AutocompleteOptions from './AutocompleteOptions';
import {ApiClient, getItemById, resourcesType} from '../../api';

interface IProps<T> {
  label?: string;
  initialValue?: string;
  id?: number;
  placeholder?: string;
  className?: string;
  /** The property to search on */
  property: string;
  resource: resourcesType;
  /** Triggers when adding or removing a value  */
  onChange: (values: T[]) => void;
  /** Used for displaying the selected values and the values in the dropdown */
  formatLabel?: (value: T) => string;
  api: ApiClient;
}

interface IState {
  inputValue: string;
}

const Styled = createStyled({
  root: {
    flexGrow: 1,
    display: 'inline-flex',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  input: {
    flexWrap: 'wrap',
  },
});

export default class SingleAutocomplete<T> extends React.Component<IProps<T>, IState> {
  public state = {
    inputValue: this.props.initialValue || '',
  };

  public async componentDidMount() {
    const {api, resource, id, property} = this.props;

    if (id) {
      try {
        const item = await getItemById(api, {resource, id});
        this.setState({inputValue: item[property]});
      } catch (err) {
        // TODO: add error handling
        // tslint:disable-next-line:no-console
        console.error(err);
      }
    }
  }

  // set default formatLabel prop
  public formatLabel = (value: T): string => {
    const {formatLabel} = this.props;

    if (formatLabel) {
      return formatLabel(value);
    }

    return typeof value === 'string' ? value : value.toString();
  };

  public handleInputChange = (inputValue: string): void => {
    this.setState({inputValue});
  };

  public render() {
    const {placeholder, id, className, label, onChange, resource, property} = this.props;
    const {inputValue} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <div className={`${classes.root} ${className}`}>
                <Downshift
                  onInputValueChange={this.handleInputChange}
                  onSelect={onChange}
                  itemToString={this.formatLabel}
                >
                  {({getInputProps, getItemProps, isOpen}) => (
                    <div className={classes.container}>
                      <TextField
                        fullWidth={true}
                        label={label}
                        className={classes.input}
                        InputProps={getInputProps({
                          placeholder,
                          id: String(id),
                          value: inputValue,
                        })}
                      />

                      {inputValue !== '' && isOpen ? (
                        <AutocompleteOptions
                          itemProps={getItemProps}
                          search={inputValue}
                          formatLabel={this.formatLabel}
                          property={property}
                          resource={resource}
                        />
                      ) : null}
                    </div>
                  )}
                </Downshift>
              </div>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
