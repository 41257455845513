import * as React from 'react';
import axios, {CancelTokenSource, CancelTokenStatic} from 'axios';
import {Subtract} from 'utility-types';

export interface InjectedCancelSourceProps {
  source: CancelTokenSource;
}

export default function withCancelSource<P extends InjectedCancelSourceProps>(
  OriginalComponent: React.ComponentType<P>
) {
  class WithCancelSource extends React.Component<Subtract<P, InjectedCancelSourceProps>, {}> {
    public CancelToken: CancelTokenStatic = axios.CancelToken;
    public source: CancelTokenSource = this.CancelToken.source();

    public render() {
      return <OriginalComponent {...this.props as any} source={this.source} />;
    }
  }
  return WithCancelSource;
}
