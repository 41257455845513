import * as React from 'react';
import {I18n} from 'react-i18next';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';

import {createStyled} from '../../../style/index';
import {shiftItemLeft, shiftItemRight} from '../../../lib/listUtils';

interface IOrderBy {
  column: string;
  direction: 'ASC' | 'DESC';
  function?: string;
}

interface IProps {
  columns: Array<{name?: string; column: string; function?: string}>;
  onChange: (values: IOrderBy[]) => void;
  initialValues?: IOrderBy[];
}

interface IState {
  orderByColumns: IOrderBy[];
}

const Styled = createStyled(theme => ({
  formControl: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'wrap',
      justifyContent: 'center',
    },
  },
  columnWidth50: {
    flexBasis: '50%',
  },
  addBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowIconsCont: {
    width: theme.spacing.unit * 3,
  },
  arrowIcons: {
    padding: 0,
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
  arrowIconsPlaceHolder: {
    padding: 0,
    opacity: 0,
  },
}));

export default class OrderByStep extends React.Component<IProps, IState> {
  public state = {
    orderByColumns:
      this.props.initialValues && this.props.initialValues.length
        ? this.props.initialValues
        : [{column: '', direction: 'ASC'} as IOrderBy],
  };

  public hasSelectedOption(value: string): boolean {
    const {orderByColumns} = this.state;

    for (const column of orderByColumns) {
      if (column.column === value) {
        return true;
      }
    }

    return false;
  }

  public handleRowAdd = (): void => {
    const {orderByColumns} = this.state;
    const {onChange} = this.props;

    const newOrderByColumns: IOrderBy[] = [...orderByColumns, {column: '', direction: 'ASC'}];

    this.setState({orderByColumns: newOrderByColumns});
    onChange(newOrderByColumns);
  };

  public handleRowRemove = (index: number) => (): void => {
    const {onChange} = this.props;
    const {orderByColumns} = this.state;

    let newOrderByColumns = [...orderByColumns];

    if (newOrderByColumns.length < 2) {
      newOrderByColumns = [{column: '', direction: 'ASC'}];
    } else {
      newOrderByColumns.splice(index, 1);
    }

    this.setState({orderByColumns: newOrderByColumns});
    onChange(newOrderByColumns);
  };

  public handleChange = (index: number, field: 'column' | 'direction') => (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const {onChange} = this.props;
    const {orderByColumns} = this.state;
    const newOrderByColumns = [...orderByColumns];

    newOrderByColumns[index] = {...orderByColumns[index], [field]: event.target.value};

    this.setState({orderByColumns: newOrderByColumns});
    onChange(newOrderByColumns);
  };

  public handleOrderArrowClick = (direction: 'up' | 'down', index: number) => {
    const {onChange} = this.props;
    const {orderByColumns} = this.state;
    const reorderedColumns =
      direction === 'up'
        ? shiftItemLeft(orderByColumns, index)
        : shiftItemRight(orderByColumns, index);

    this.setState({orderByColumns: reorderedColumns});
    onChange(reorderedColumns);
  };

  public render() {
    const {columns} = this.props;
    const {orderByColumns} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <React.Fragment>
                {orderByColumns.map((orderByColumn, i) => (
                  <div className={classes.row} key={'row' + i}>
                    {/* Order Buttons */}
                    <div className={classes.arrowIconsCont}>
                      <IconButton
                        className={i === 0 ? classes.arrowIconsPlaceHolder : classes.arrowIcons}
                        onClick={this.handleOrderArrowClick.bind(this, 'up', i)}
                      >
                        <ArrowUpIcon />
                      </IconButton>
                      <IconButton
                        className={
                          i === orderByColumns.length - 1
                            ? classes.arrowIconsPlaceHolder
                            : classes.arrowIcons
                        }
                        onClick={this.handleOrderArrowClick.bind(this, 'down', i)}
                      >
                        <ArrowDownIcon />
                      </IconButton>
                    </div>

                    {/* Column */}
                    <FormControl className={classes.formControl + ' ' + classes.columnWidth50}>
                      <InputLabel htmlFor="column">
                        {t('chart_dialog.steps.order_by.column_label')}
                      </InputLabel>
                      <Select
                        id="column"
                        value={orderByColumn.column}
                        onChange={this.handleChange(i, 'column')}
                      >
                        {columns.map((option, index) => (
                          <MenuItem
                            key={index}
                            disabled={this.hasSelectedOption(option.column)}
                            value={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Direction */}
                    <FormControl className={classes.formControl + ' ' + classes.columnWidth50}>
                      <InputLabel htmlFor="direction">
                        {t('chart_dialog.steps.order_by.direction_label')}
                      </InputLabel>
                      <Select
                        id="direction"
                        value={orderByColumn.direction}
                        onChange={this.handleChange(i, 'direction')}
                      >
                        {['ASC', 'DESC'].map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {t(`chart_dialog.steps.order_by.${option}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <IconButton
                      onClick={this.handleRowRemove(i)}
                      aria-label="Delete row"
                      color="secondary"
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  </div>
                ))}

                <div className={classes.addBtnContainer}>
                  <IconButton onClick={this.handleRowAdd} aria-label="Add row" color="primary">
                    <AddCircleIcon />
                  </IconButton>
                </div>
              </React.Fragment>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}
