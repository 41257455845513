import * as React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import {createStyled} from '../style';

const Styled = createStyled({
  progress: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
    zIndex: 1250, // AppBar is at 1100, Drawer at 1200, Modal at 1300
  },
});

/**
 * A LinearProgress at the top of the page, for use as a Suspense fallback.
 */
export default function LoadingBar() {
  return <Styled>{({classes}) => <LinearProgress className={classes.progress} />}</Styled>;
}
