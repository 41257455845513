import * as React from 'react';
import moment from 'moment-timezone';
import {I18n} from 'react-i18next';

import {periods, comparisonRanges, rangeGroups} from '../data/periods';
import dateRangeToString from '../lib/relativeDateParser';
import {dateTimeFormatExt, getClientTimeZone, format, dateTimeFormatTz} from '../lib/dateUtils';

import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {DateTimePicker} from 'material-ui-pickers';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventIcon from '@material-ui/icons/Event';

import {createStyled} from '../style/index';
import {TextField, FormControlLabel, ListSubheader} from '@material-ui/core';
import {TimeFrame} from './ChartDialog/ChartDialog';
import {isNumber} from 'util';
import {singleTimeFrameChartType} from '../api';

const Styled = createStyled(theme => {
  return {
    button: {
      margin: theme.spacing.unit,
    },
    formControl: {
      width: '100%',
    },
    compareToCheckbox: {
      padding: '0px 12px 0px 10px',
    },
    selectGroup: {
      paddingBottom: '0px',
    },
    selectItem: {
      paddingLeft: '25px',
    },
    overlay: {
      display: 'flex',
      color: 'gray',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      alignItems: 'center',
    },
    flexibleIcon: {
      flex: 1,
    },
    flexibleText: {
      flex: 1,
      flexGrow: 5,
    },
  };
});

interface IState {
  withComparison: boolean;
  periodType: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  trailing: number | undefined;
  periodTypePeriod2: string | undefined;
  startDatePeriod2: Date | undefined;
  endDatePeriod2: Date | undefined;
  trailingPeriod2: number | undefined;
  referenceDatePeriod2: string | undefined;
}
interface IProps {
  timeFrames: TimeFrame[];
  initialValues?: {
    periodType: string;
    startDate?: Date;
    endDate?: Date;
    trailing?: number;
    periodTypePeriod2?: string;
    startDatePeriod2?: Date;
    endDatePeriod2?: Date;
    trailingPeriod2?: number;
    withComparison: boolean;
  };
  onChange: (
    values: {
      periodType: string;
      startDate: Date | undefined;
      endDate: Date | undefined;
      trailing: number | undefined;
      withComparison: boolean;
      periodTypePeriod2: string | undefined;
      startDatePeriod2: Date | undefined;
      endDatePeriod2: Date | undefined;
      trailingPeriod2: number | undefined;
      referenceDatePeriod2: string | undefined;
    }
  ) => void;
  disableComparison?: boolean;
}

export const timeFrameColors = ['#338357', '#faaf3b'];

export default class TimeFrameSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {timeFrames, disableComparison} = props;

    this.state = formatTimeFrameToValues(timeFrames, disableComparison);
  }

  public handleCompareToChange = () => {
    const {onChange} = this.props;

    this.setState(state => {
      const newState = {
        withComparison: !state.withComparison,
      };
      onChange({...state, ...newState});

      return newState;
    });
  };

  public handleStartDateChange = (value: Date, isPeriod2?: boolean): void => {
    const {onChange} = this.props;
    let newState = {};

    if (isPeriod2) {
      newState = {startDatePeriod2: value, periodTypePeriod2: 'fixed date'};
    } else {
      newState = {startDate: value, periodType: 'fixed date'};
    }

    this.setState(newState);
    onChange({...this.state, ...newState});
  };

  public handleEndDateChange = (value: Date, isPeriod2?: boolean): void => {
    const {onChange} = this.props;
    let newState = {};

    if (isPeriod2) {
      newState = {endDatePeriod2: value, periodTypePeriod2: 'fixed date'};
    } else {
      newState = {endDate: value, periodType: 'fixed date'};
    }

    this.setState(newState);
    onChange({...this.state, ...newState});
  };

  public handlePeriodTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    isPeriod2?: boolean
  ): void => {
    const {onChange} = this.props;
    const now = moment().format();
    let newValues = {};
    let newState = this.state;

    if (!event.target.value) {
      return;
    }

    if (isPeriod2) {
      if (event.target.value === 'fixed date') {
        newValues = {periodTypePeriod2: event.target.value};
      } else {
        const rangePeriod2 = comparisonRanges[this.state.periodType][event.target.value]();
        const referenceDate = rangePeriod2.referenceDescription
          ? moment(dateRangeToString(rangePeriod2.referenceDescription, now)).format()
          : now;

        const newStartDatePeriod2 = dateRangeToString(rangePeriod2.start, referenceDate);
        const newEndDatePeriod2 = dateRangeToString(rangePeriod2.end, referenceDate);

        newValues = {
          periodTypePeriod2: event.target.value,
          startDatePeriod2: newStartDatePeriod2
            ? moment(newStartDatePeriod2).toDate()
            : this.state.startDatePeriod2,
          endDatePeriod2: newEndDatePeriod2
            ? moment(newEndDatePeriod2).toDate()
            : this.state.endDatePeriod2,
          referenceDatePeriod2: rangePeriod2.referenceDescription ? referenceDate : undefined,
        };
      }

      newState = {...this.state, ...newValues};

      this.setState(newState);
      onChange(newState);

      return;
    }

    if (event.target.value === 'fixed date') {
      const {periodTypePeriod2} = this.state;
      const newPeriodTypePeriod2 =
        periodTypePeriod2 === 'trailing' || periodTypePeriod2 === 'fixed date'
          ? periodTypePeriod2
          : 'fixed date';

      newValues = {periodType: event.target.value, periodTypePeriod2: newPeriodTypePeriod2};
    } else {
      const range = periods[event.target.value]();
      const referenceDate = range.referenceDescription
        ? moment(dateRangeToString(range.referenceDescription, now)).format()
        : now;

      const newStartDate = dateRangeToString(range.start, referenceDate);
      const newEndDate = dateRangeToString(range.end, referenceDate);

      newValues = {
        periodType: event.target.value,
        startDate: newStartDate ? moment(newStartDate).toDate() : this.state.startDate,
        endDate: newEndDate ? moment(newEndDate).toDate() : this.state.endDate,
        periodTypePeriod2: 'fixed date',
        referenceDatePeriod2: range.referenceDescription ? referenceDate : undefined,
      };
    }

    newState = {...this.state, ...newValues};

    this.setState(newState);
    onChange(newState);
  };

  public handleTrailingChange = (event: any, isPeriod2?: boolean) => {
    const {onChange} = this.props;
    let newValues = {};
    let newState = this.state;

    if (isPeriod2) {
      newValues = {
        trailingPeriod2: Number(event.target.value),
      };
    } else {
      newValues = {
        trailing: Number(event.target.value),
      };
    }

    newState = {...this.state, ...newValues};

    this.setState(newState);
    onChange(newState);
  };

  public setToCustomDate = (event: any, isPeriod2?: boolean) => {
    const {periodType, periodTypePeriod2} = this.state;

    if (isPeriod2 && periodTypePeriod2 !== 'fixed date') {
      this.setState({periodTypePeriod2: 'fixed date'});

      return;
    }

    if (!isPeriod2 && periodType !== 'fixed date') {
      this.setState({periodTypePeriod2: 'fixed date', periodType: 'fixed date'});
    }
  };

  private shouldDisableComparison = () => {
    const {disableComparison} = this.props;

    return Boolean(disableComparison);
  };

  public render() {
    const {withComparison, trailing, trailingPeriod2} = this.state;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container={true} spacing={40}>
                    <Grid item={true} xs={12} sm={6}>
                      <Grid container={true} alignItems={'center'} spacing={32}>
                        <Grid item={true} xs={12}>
                          <Typography variant="subtitle2">{t('date.date_range')}</Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="period1">{t('date.period')}</InputLabel>
                            <Select
                              id="period1"
                              value={this.state.periodType || 'today'}
                              onChange={e => this.handlePeriodTypeChange(e)}
                            >
                              {rangeGroups.map(group => [
                                <MenuItem
                                  className={classes.selectGroup}
                                  disabled={true}
                                  key={group.title}
                                >
                                  {t(`date.range_groups.${group.title}`)}
                                </MenuItem>,
                                group.ranges.map(rangeFn => {
                                  const option = rangeFn();

                                  return (
                                    <MenuItem
                                      className={classes.selectItem}
                                      key={`${group.title}-${option.title}`}
                                      value={option.title}
                                    >
                                      {t(`date.period_type.${option.title}`)}
                                    </MenuItem>
                                  );
                                }),
                              ])}
                            </Select>
                          </FormControl>
                        </Grid>

                        {this.state.periodType === 'trailing' && (
                          <Grid item={true} xs={12}>
                            <TextField
                              label={t('chart_dialog.steps.timeframe.subtract_hours_label')}
                              type="text"
                              inputProps={{
                                maxLength: 6,
                              }}
                              value={trailing || 0}
                              placeholder="0"
                              className={classes.formControl}
                              onChange={this.handleTrailingChange}
                            />
                          </Grid>
                        )}
                        {this.state.periodType !== 'trailing' && (
                          <>
                            <Grid item={true} xs={12}>
                              <FormControl className={classes.formControl}>
                                <DateTimePicker
                                  InputProps={{
                                    onClick: e => this.setToCustomDate(e),
                                  }}
                                  label={t('date.from')}
                                  value={this.state.startDate || new Date()}
                                  onChange={this.handleStartDateChange}
                                  maxDate={this.state.endDate}
                                  autoOk={true}
                                  keyboard={true}
                                  showTabs={false}
                                  keyboardIcon={<EventIcon />}
                                  leftArrowIcon={<KeyboardArrowLeftIcon />}
                                  rightArrowIcon={<KeyboardArrowRightIcon />}
                                  dateRangeIcon={<DateRangeIcon />}
                                  timeIcon={<AccessTimeIcon />}
                                  format={dateTimeFormatExt}
                                  ampm={false}
                                  disabled={this.state.periodType !== 'fixed date'}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item={true} xs={12}>
                              <FormControl className={classes.formControl}>
                                <DateTimePicker
                                  InputProps={{
                                    onClick: e => this.setToCustomDate(e),
                                  }}
                                  label={t('date.till')}
                                  value={this.state.endDate || new Date()}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.startDate}
                                  autoOk={true}
                                  keyboard={true}
                                  showTabs={false}
                                  keyboardIcon={<EventIcon />}
                                  leftArrowIcon={<KeyboardArrowLeftIcon />}
                                  rightArrowIcon={<KeyboardArrowRightIcon />}
                                  dateRangeIcon={<DateRangeIcon />}
                                  timeIcon={<AccessTimeIcon />}
                                  format={dateTimeFormatExt}
                                  ampm={false}
                                  disabled={this.state.periodType !== 'fixed date'}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                      {this.shouldDisableComparison() ? (
                        <div className={classes.overlay}>
                          <InfoIcon className={classes.flexibleIcon} />
                          <span className={classes.flexibleText}>
                            {t('date.comparison_disable_text', {
                              types: singleTimeFrameChartType
                                .map(tp =>
                                  t(`chart_dialog.steps.presentation.graph.chart_view.types.${tp}`)
                                )
                                .join(', '),
                            })}
                          </span>
                        </div>
                      ) : (
                        <Grid container={true} alignItems={'center'} spacing={32}>
                          <Grid item={true} xs={12}>
                            <Typography variant="subtitle2">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className={classes.compareToCheckbox}
                                    checked={withComparison}
                                    onChange={this.handleCompareToChange}
                                    disabled={this.shouldDisableComparison()}
                                    color="primary"
                                  />
                                }
                                label={t('date.compare_to')}
                              />
                            </Typography>
                          </Grid>
                          <Grid item={true} xs={12}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="period2">{t('date.period')}</InputLabel>
                              <Select
                                id="period2"
                                disabled={!withComparison}
                                value={this.state.periodTypePeriod2}
                                onChange={e => this.handlePeriodTypeChange(e, true)}
                              >
                                {Object.keys(comparisonRanges[this.state.periodType]).map(
                                  option => (
                                    <MenuItem key={option} value={option}>
                                      {t(`date.period_type.${option}`)}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          {this.state.periodTypePeriod2 === 'trailing' && (
                            <Grid item={true} xs={12}>
                              <TextField
                                label={t('chart_dialog.steps.timeframe.subtract_hours_label')}
                                type="text"
                                inputProps={{
                                  maxLength: 6,
                                }}
                                value={trailingPeriod2 || 0}
                                placeholder="0"
                                className={classes.formControl}
                                onChange={e => this.handleTrailingChange(e, true)}
                                disabled={!withComparison}
                              />
                            </Grid>
                          )}
                          {this.state.periodTypePeriod2 !== 'trailing' && (
                            <>
                              <Grid item={true} xs={12}>
                                <FormControl className={classes.formControl}>
                                  <DateTimePicker
                                    InputProps={{
                                      onClick: e => this.setToCustomDate(e, true),
                                    }}
                                    label={t('date.from')}
                                    value={this.state.startDatePeriod2 || new Date()}
                                    onChange={e => this.handleStartDateChange(e, true)}
                                    disabled={
                                      !withComparison ||
                                      this.state.periodTypePeriod2 !== 'fixed date'
                                    }
                                    maxDate={this.state.endDatePeriod2}
                                    autoOk={true}
                                    keyboard={true}
                                    showTabs={false}
                                    keyboardIcon={<EventIcon />}
                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                    dateRangeIcon={<DateRangeIcon />}
                                    timeIcon={<AccessTimeIcon />}
                                    format={dateTimeFormatExt}
                                    ampm={false}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item={true} xs={12}>
                                <FormControl className={classes.formControl}>
                                  <DateTimePicker
                                    InputProps={{
                                      onClick: e => this.setToCustomDate(e, true),
                                    }}
                                    label={t('date.till')}
                                    value={this.state.endDatePeriod2 || new Date()}
                                    onChange={e => this.handleEndDateChange(e, true)}
                                    disabled={
                                      !withComparison ||
                                      this.state.periodTypePeriod2 !== 'fixed date'
                                    }
                                    minDate={this.state.startDatePeriod2}
                                    autoOk={true}
                                    keyboard={true}
                                    showTabs={false}
                                    keyboardIcon={<EventIcon />}
                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                    dateRangeIcon={<DateRangeIcon />}
                                    timeIcon={<AccessTimeIcon />}
                                    format={dateTimeFormatExt}
                                    ampm={false}
                                  />
                                </FormControl>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}

export function generateTimeframeData(
  periodType: string,
  {
    startDate,
    endDate,
    trailing,
    period1Type,
    isPeriod2,
    referenceDatePeriod2,
  }: {
    startDate?: Date;
    endDate?: Date;
    trailing?: number;
    period1Type?: string;
    isPeriod2?: boolean;
    referenceDatePeriod2?: string;
  }
): TimeFrame {
  const tz = getClientTimeZone();

  if (periodType === 'trailing' && isNumber(trailing)) {
    return {
      type: 'trailing',
      hoursToSub: trailing,
      tz,
    };
  }

  if (
    periodType !== 'trailing' &&
    periodType !== 'fixed date' &&
    ((isPeriod2 && period1Type) || !isPeriod2)
  ) {
    const periodDescription = isPeriod2 && period1Type ? comparisonRanges[period1Type] : periods;

    return {
      type: 'descriptive',
      description: periodDescription[periodType]().value,
      tz,
      referenceDate: referenceDatePeriod2 || undefined,
    };
  }

  if (periodType === 'fixed date') {
    if (!startDate || !endDate) {
      throw new Error(`Invalid dates`);
    }

    return {
      type: 'fixed',
      startAt: format(startDate, dateTimeFormatTz),
      endAt: format(endDate, dateTimeFormatTz),
    };
  }

  throw new Error(`Invalid period type`);
}

export function formatTimeFrameToValues(
  timeFrames: TimeFrame[],
  disableComparison?: boolean
): IState {
  let values: IState = {
    startDate: new Date(),
    endDate: new Date(),
    periodType: 'fixed date',
    trailing: 0,
    withComparison: false,
    startDatePeriod2: new Date(),
    endDatePeriod2: new Date(),
    periodTypePeriod2: 'fixed date',
    trailingPeriod2: 0,
    referenceDatePeriod2: undefined,
  };

  let descriptivePeriod1Value: string = '';
  const now = moment().format();

  timeFrames.forEach((tf, i) => {
    if ((disableComparison !== undefined && disableComparison && i > 0) || !tf) {
      return;
    }

    if (tf.type === 'trailing') {
      const newState: Partial<IState> =
        i === 0
          ? {
              periodType: 'trailing',
              trailing: tf.hoursToSub,
            }
          : {
              periodTypePeriod2: 'trailing',
              trailingPeriod2: tf.hoursToSub,
              withComparison: true,
            };

      values = Object.assign({}, values, newState);
    }

    if (tf.type === 'fixed') {
      const newState: Partial<IState> =
        i === 0
          ? {
              periodType: 'fixed date',
              startDate: moment(tf.startAt).toDate(),
              endDate: moment(tf.endAt).toDate(),
            }
          : {
              periodTypePeriod2: 'fixed date',
              startDatePeriod2: moment(tf.startAt).toDate(),
              endDatePeriod2: moment(tf.endAt).toDate(),
              withComparison: true,
            };

      values = Object.assign({}, values, newState);
    }

    if (tf.type === 'descriptive') {
      if (i === 0) {
        Object.keys(periods).forEach(p => {
          const desc = periods[p]();

          if (desc.value === tf.description) {
            descriptivePeriod1Value = p;

            const referenceDate = desc.referenceDescription
              ? moment(dateRangeToString(desc.referenceDescription, now)).format()
              : now;

            const newStartDatePeriod1 = dateRangeToString(desc.start, referenceDate);
            const newEndDatePeriod1 = dateRangeToString(desc.end, referenceDate);
            const newState: Partial<IState> = {
              periodType: p,
              startDate: moment(newStartDatePeriod1 ? newStartDatePeriod1 : now).toDate(),
              endDate: moment(newEndDatePeriod1 ? newEndDatePeriod1 : now).toDate(),
            };

            values = Object.assign({}, values, newState);
          }
        });
      } else {
        if (!comparisonRanges[descriptivePeriod1Value]) {
          throw new Error(`Unknown period: ${descriptivePeriod1Value}`);
        }

        Object.keys(comparisonRanges[descriptivePeriod1Value]).forEach(p => {
          const desc = comparisonRanges[descriptivePeriod1Value][p]();

          if (desc.value === tf.description) {
            const referenceDate = desc.referenceDescription
              ? moment(dateRangeToString(desc.referenceDescription, now)).format()
              : now;

            const newStartDatePeriod2 = dateRangeToString(desc.start, referenceDate);
            const newEndDatePeriod2 = dateRangeToString(desc.end, referenceDate);
            const newState: Partial<IState> = {
              periodTypePeriod2: p,
              withComparison: true,
              startDatePeriod2: moment(newStartDatePeriod2 ? newStartDatePeriod2 : now).toDate(),
              endDatePeriod2: moment(newEndDatePeriod2 ? newEndDatePeriod2 : now).toDate(),
              referenceDatePeriod2: desc.referenceDescription ? referenceDate : undefined,
            };

            values = Object.assign({}, values, newState);
          }
        });
      }
    }
  });

  return values;
}
