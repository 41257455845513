import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import {createStyled} from '../../style/index';
import {getSelectKey} from '../ChartDialog/properties';
import ColumnField from './ColumnField';
import OperatorField from './OperatorField';
import ValueField from './ValueField';

interface IColumn {
  name?: string;
  column: string;
  resource?:
    | 'machine'
    | 'location'
    | 'errorIdentity'
    | 'currency'
    | 'machinegroup'
    | 'locationgroup'
    | 'manufacturer'
    | 'machinemodel'
    | 'product'
    | 'machinetype'
    | 'debtor';
  type?: 'number' | 'enum' | 'boolean' | 'string';
  options?: Array<string | {name: string; value: string}>;
}

interface IProps {
  columns: IColumn[];
  value: IValue;
  disableRemoveBtn?: boolean;
  rowIndex: number;
  onRowRemove: (index: number) => () => void;
  onChange: (value: IValue) => void;
}

interface IValue {
  column: string;
  operator: string;
  value?: string | {id: number} | Array<{id: number}>;
}

const Styled = createStyled(theme => ({
  form: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'wrap',
      justifyContent: 'center',
    },
  },
}));

export default class FilterRow extends React.Component<IProps, {}> {
  public handleFieldChange = (selectedValue: string): void => {
    const {onChange, value} = this.props;

    onChange({
      ...value,
      column: selectedValue,
      operator: '',
      value: '',
    });
  };

  public handleOperatorChange = (selectedValue: string): void => {
    const {onChange, value} = this.props;

    onChange({...value, operator: selectedValue, value: undefined});
  };

  public handleValueChange = (selectedValue: string | {id: number} | Array<{id: number}>): void => {
    const {onChange, value} = this.props;

    onChange({...value, value: selectedValue});
  };

  public getColumn(columns: IColumn[], value: string): IColumn | undefined {
    for (const column of columns) {
      if (getSelectKey(column) === value) {
        return column;
      }
    }

    return undefined;
  }

  public getSearchProperty(column?: IColumn): string {
    if (!column || !column.column || column.resource) {
      return 'name';
    }

    const properties = column.column.split('.');

    return properties[properties.length - 1];
  }

  public render() {
    const {columns, value, rowIndex, disableRemoveBtn, onRowRemove} = this.props;
    const column = this.getColumn(columns, value.column);
    const options = column && column.options ? column.options : undefined;
    const columnType = (column && column.type) || 'string';

    return (
      <Styled>
        {({classes}) => (
          <form className={classes.form}>
            <ColumnField
              columns={columns}
              initialValue={value.column}
              onChange={this.handleFieldChange}
            />

            <OperatorField
              isDisabled={!value.column}
              initialValue={value.operator}
              onChange={this.handleOperatorChange}
              columnType={column && column.resource ? 'resource' : columnType}
            />

            <ValueField
              isDisabled={!value.column || !value.operator}
              initialValue={value.value}
              columnType={columnType}
              onChange={this.handleValueChange}
              values={options}
              operator={value.operator}
              searchProperty={this.getSearchProperty(column)}
              resource={(column && column.resource) || undefined}
            />

            <IconButton
              disabled={disableRemoveBtn}
              onClick={onRowRemove(rowIndex)}
              aria-label="Delete row"
              color="secondary"
            >
              <RemoveCircleIcon />
            </IconButton>
          </form>
        )}
      </Styled>
    );
  }
}
