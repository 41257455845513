import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {I18n} from 'react-i18next';

import {i18n} from '../../../i18n';
import {reportColors as COLORS} from '../../../data/colors';
import {price, cleanNumber} from '../../../formatters';
import {createStyled} from '../../../style/';
import {IChart} from '../../../api';
import PieChartCmp from '../resultRenderers/PieChart';
import {TableFooter} from '@material-ui/core';
import {timeFrameColors} from '../../TimeFrameSelector';
import generateRandomColor from '../../../utils/generateRandomColor';

const Styled = createStyled(theme => {
  return {
    headerCell: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff',
    },
    footerCell: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff',
    },
    tableWrapper: {
      maxHeight: 90 * theme.spacing.unit,
    },
    metricCell: {
      textAlign: 'right',
      paddingRight: theme.spacing.unit * 3,
      paddingLeft: '0px',
    },
    chartWrapper: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
    },
    tableContainer: {
      flex: 1,
      flexGrow: 5,
      overflowY: 'auto',
      minHeight: '100%',
    },
    chartContainer: {
      flex: 1,
      flexGrow: 3,
      minHeight: 50 * theme.spacing.unit,
      maxHeight: 90 * theme.spacing.unit,
      width: 30 * theme.spacing.unit,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    firstCell: {
      paddingLeft: 0,
    },
  };
});

export type PaymentTypesResult = Array<{
  xAxis: string;
  yAxis: string[];
  currencies: {
    [key: string]: {
      dataSet: any[];
      totalQuantity: number;
      totalRevenue: number;
    };
  };
}>;

interface IProps {
  chart: IChart;
  results: PaymentTypesResult;
}

export default class PaymentTypes extends React.Component<IProps, {}> {
  private xAxis: string;
  private data: any[];
  private tableData: any[];
  private totals: any[];

  constructor(props: IProps) {
    super(props);

    this.xAxis = 'type';
    this.data = [];
    this.tableData = [];
    this.totals = [];

    /**
     *
     * Original results:
     * [
     *   // Each item of the array is a timeframe
     *   {
     *     currencies: {
     *       // Results grouped by currency
     *       EUR: {
     *         dataSet: [{type: "code", reportgroup: "A", revenue: 5, avgrevenue: 5, quantity: 1}],
     *         totalRevenue: 10,
     *         totalQuantity: 20,
     *       },
     *       xAxis: 'type',
     *       yAxis: ['revenue', 'avgrevenue', 'quantity'],
     *     },
     *   },
     * ];
     *
     * Turns the results into a flat array of objects.
     * Also, it adds suffixes to the metric properties,
     * just so we can still show the timeframes separetly
     */
    props.results.forEach((timeFrameResult, timeFrameIndex) => {
      this.xAxis = timeFrameResult.xAxis;
      const timeFrameData: any[] = [];
      const timeFrameTotal: any[] = [];

      let colorIndex = -1;

      Object.keys(timeFrameResult.currencies).forEach(c => {
        // Process data
        timeFrameResult.currencies[c].dataSet.forEach(d => {
          colorIndex++;
          timeFrameData.push({
            [this.xAxis]: d[this.xAxis],
            reportgroup: d.reportgroup,
            [`quantity${timeFrameIndex}`]: d.quantity,
            [`revenue${timeFrameIndex}`]: d.revenue,
            [`avgrevenue${timeFrameIndex}`]: d.avgrevenue,
            currency: c,
            color: COLORS[colorIndex] ? COLORS[colorIndex] : generateRandomColor(),
          });
        });

        // Process totals
        timeFrameTotal.push({
          [`quantity${timeFrameIndex}`]: timeFrameResult.currencies[c].totalQuantity,
          [`revenue${timeFrameIndex}`]: timeFrameResult.currencies[c].totalRevenue,
          currency: c,
        });
      });

      this.totals.push(timeFrameTotal);
      this.data.push(timeFrameData);
    });

    // Sort the data on quantity descending
    this.data.forEach(fr => {
      fr.sort(
        (a: {quantity0: number}, b: {quantity0: number}) => (a.quantity0 < b.quantity0 ? 1 : -1)
      );
    });

    let mergedData: any[] = [];
    let mergedTotals: any[] = [];

    // A single payment method can appear in both timeframes for the same currency,
    // in that case we want to group them into a single row and add up the numbers
    this.data.forEach((timeFrameData: any, i: number) => {
      if (i === 0) {
        mergedData = timeFrameData.map((d: any) => d);

        return;
      }

      timeFrameData.map((d: any) => {
        const itemIndex = mergedData.findIndex(md => {
          return (
            md.type === d.type && md.reportgroup === d.reportgroup && md.currency === d.currency
          );
        });

        if (itemIndex === -1) {
          mergedData.push(d);
        } else {
          mergedData[itemIndex] = Object.assign(mergedData[itemIndex], d);
        }
      });
    });

    // Adds up the totals from both timeframes grouped by currency
    this.totals.forEach((timeFrameTotal: any, i: number) => {
      if (i === 0) {
        mergedTotals = timeFrameTotal.map((d: any) => d);

        return;
      }

      timeFrameTotal.map((t: any) => {
        const itemIndex = mergedTotals.findIndex(mt => {
          return t.currency === mt.currency;
        });

        if (itemIndex === -1) {
          mergedTotals.push(t);
        } else {
          mergedTotals[itemIndex] = Object.assign(mergedTotals[itemIndex], t);
        }
      });
    });

    this.tableData = [...mergedData];
    this.totals = [...mergedTotals];
  }

  public render() {
    const {results} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <div className={`${classes.chartWrapper} pageBreakOnPrint`}>
                <div className={`${classes.tableContainer} fullHeightOnPrint`}>
                  <Table className={classes.tableWrapper}>
                    {/* Sticky Headers */}
                    <TableHead>
                      <TableRow>
                        <TableCell className={`${classes.headerCell} ${classes.firstCell}`}>
                          {t(`chart.default_charts.payment_types.${this.xAxis}`)}
                        </TableCell>
                        {/* Metric Headers */}
                        {results.map((r, i) => (
                          <React.Fragment key={i}>
                            <TableCell
                              className={`${classes.headerCell} ${classes.metricCell}`}
                              style={{color: timeFrameColors[i]}}
                            >
                              {t(`chart.default_charts.payment_types.avg_payment`)}
                            </TableCell>
                            <TableCell
                              className={`${classes.headerCell} ${classes.metricCell}`}
                              style={{color: timeFrameColors[i]}}
                            >
                              {t(`chart.default_charts.payment_types.quantity`)}
                            </TableCell>
                            <TableCell
                              className={`${classes.headerCell} ${classes.metricCell}`}
                              style={{color: timeFrameColors[i]}}
                            >
                              {t(`chart.default_charts.payment_types.revenue`)}
                            </TableCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Rows */}
                    <TableBody>
                      {this.tableData.map((d, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row" className={classes.firstCell}>
                            <Grid container={true} direction="row" alignItems="center">
                              <Grid item={true}>
                                <FiberManualRecordIcon style={{color: d.color}} />
                              </Grid>
                              <Grid item={true}>
                                {t(`chart.default_charts.payment_types.${d[this.xAxis]}`)}{' '}
                                {d.reportgroup ? `(${d.reportgroup})` : ''}
                              </Grid>
                            </Grid>
                          </TableCell>
                          {/* Metric data */}
                          {results.map((r, j) => (
                            <React.Fragment key={j}>
                              <TableCell component="th" scope="row" className={classes.metricCell}>
                                {`${price(d['avgrevenue' + j] || 0)} ${d.currency}`}
                              </TableCell>
                              <TableCell component="th" scope="row" className={classes.metricCell}>
                                {cleanNumber(d['quantity' + j]) || 0}
                              </TableCell>
                              <TableCell component="th" scope="row" className={classes.metricCell}>
                                {`${price(d['revenue' + j] || 0)} ${d.currency}`}
                              </TableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* Sticky footer */}
                    <TableFooter>
                      <TableRow>
                        <TableCell className={`${classes.footerCell} ${classes.firstCell}`}>
                          {t(`chart.default_charts.payment_types.total`)}
                        </TableCell>

                        {/* Totals metric columns */}
                        {results.map((timeFrame: any, j: number) => (
                          <React.Fragment key={j}>
                            <TableCell className={classes.footerCell} />
                            {['quantity', 'revenue'].map((propertyName, i) => (
                              <TableCell
                                key={i}
                                className={`${classes.footerCell} ${classes.metricCell}`}
                              >
                                {this.totals.map((total, k) => (
                                  <Grid
                                    container={true}
                                    key={i + k}
                                    direction={'column'}
                                    spacing={16}
                                  >
                                    <Grid item={true}>
                                      {propertyName === 'revenue'
                                        ? `${price(total[propertyName + j] || 0)}\u00a0${
                                            total.currency
                                          }`
                                        : cleanNumber(total[propertyName + j]) || 0}
                                    </Grid>
                                  </Grid>
                                ))}
                              </TableCell>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
                <div className={`${classes.chartContainer} hideOnPrint`}>
                  <PieChartCmp
                    data={this.data}
                    nameProperty={this.xAxis}
                    metricProperty={'quantity'}
                    labelFormat={labelFormat}
                    valueFormat={cleanNumber}
                  />
                </div>
              </div>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}

function labelFormat(label: string | number, payload: any) {
  let reportGroup;

  if (payload && payload && payload.payload && payload.payload.reportgroup) {
    reportGroup = payload.payload.reportgroup;
  }

  return `${i18n.t(`filter.properties.${label}`, {defaultValue: label})}${
    reportGroup ? ' (' + reportGroup + ')' : ''
  }`;
}
