import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';

import {createStyled} from '../style';

const Styled = createStyled(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  statusIcon: {
    fontSize: 36,
  },
}));

export type OverlayStatus = undefined | 'none' | 'inProgress' | 'error' | 'warning';

export default function ProgressOverlay(props: {status?: OverlayStatus}) {
  const {status} = props;

  return (
    <Styled>
      {({classes}) => (
        <React.Fragment>
          {status !== undefined && (
            <Grid
              className={classes.container}
              container={true}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item={true}>
                {status === 'inProgress' && (
                  <CircularProgress className={classes.statusIcon} disableShrink={true} />
                )}
                {status === 'error' && (
                  <ErrorIcon color="disabled" className={classes.statusIcon} fontSize="large" />
                )}
                {/*
                TODO: handle warnings in a different way
                status === 'warning' && (
                  <WarningIcon color="disabled" className={classes.statusIcon} fontSize="large" />
                )*/}
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Styled>
  );
}
