import * as React from 'react';
import {I18n} from 'react-i18next';
import {Provider, Subscribe} from 'unstated';
import CircularProgress from '@material-ui/core/CircularProgress';

import PageWrapper from '../components/PageWrapper';
import {createStyled} from '../style/index';
import {
  ApiClient,
  IReportData,
  findUserReport,
  createUserReport,
  findUserReportById,
  duplicateUserReport,
} from '../api';
import Report from '../components/Report';
import {RouteComponentProps} from 'react-router-dom';
import ReportSelector from '../components/Report/ReportSelector';
import WithCancelToken, {InjectedCancelSourceProps} from '../components/hoc/WithCancelToken';
import GoogleAnalytics from '../GoogleAnalytics';

interface IState {
  reports: IReportData[];
  fullscreen?: boolean;
  reportId?: number;
  tabIndex: number;
  showDefaultReport: boolean;
  loadingReport: boolean;
  newReportDialogOpen: boolean;
}

interface IProps extends InjectedCancelSourceProps {
  api: ApiClient;
  ga: GoogleAnalytics;
}

const ReportWithCancelToken = WithCancelToken(Report);
const Styled = createStyled(theme => {
  return {
    tabContainer: {
      position: 'fixed',
      top: '100px',
    },
    statusIcon: {
      fontSize: 36,
    },
    formControl: {
      minWidth: '100px',
      flexGrow: 1,
    },
    fullscreenWrapper: {
      width: '98%',
      margin: 'auto',
    },
  };
});

export default class ReportPage extends React.Component<RouteComponentProps<any> & IProps, IState> {
  constructor(props: RouteComponentProps<any> & IProps) {
    super(props);

    const urlParams = new URLSearchParams(this.props.match.params);
    const reportId = urlParams.get('id');

    this.state = {
      reports: [],
      fullscreen: props.api.isFullscreenMode(),
      reportId: reportId ? parseInt(reportId, 10) : undefined,
      tabIndex: 0,
      showDefaultReport: true,
      loadingReport: true,
      newReportDialogOpen: false,
    };
  }

  private setReports = async (isUpdate?: boolean) => {
    let {reportId} = this.state;
    const {history} = this.props;

    const urlParams = new URLSearchParams(this.props.match.params);
    const reportIdUrlParam = urlParams.get('id');
    const isDifferentReportPage = reportIdUrlParam && parseInt(reportIdUrlParam, 10) !== reportId;

    if (reportIdUrlParam && isDifferentReportPage) {
      reportId = parseInt(reportIdUrlParam, 10);
    }

    if (!isUpdate || isDifferentReportPage) {
      const {api, source} = this.props;
      try {
        const reports = await findUserReport(api, {}, {cancelToken: source.token});
        const defaultReport = reports.find(r => r.isDefault);

        if (!defaultReport) {
          throw new Error('Could not find default user report');
        }

        this.setState({
          reports,
          reportId: reportId ? reportId : defaultReport.id,
          loadingReport: false,
        });
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e);
        history.push(`/404`);
      }
    }
  };

  public async componentDidMount() {
    await this.setReports();
  }

  public componentWillUnmount() {
    const {source} = this.props;

    source.cancel('Operation canceled.');
  }

  public async componentDidUpdate() {
    await this.setReports(true);
  }

  public handleReportChange = async (api: ApiClient, reportId: number) => {
    const newReport = await findUserReportById(api, reportId);

    this.setState(state => {
      const newReportsList = state.reports.map(r => {
        if (r.id !== reportId) {
          return r;
        }

        return newReport;
      });

      return {
        reports: newReportsList,
      };
    });
  };

  public handleNewReportCreate = async (reportName: string) => {
    this.setState({loadingReport: true});

    const {api, history, ga} = this.props;
    const {reports} = this.state;
    const {id} = await createUserReport(api, {
      name: reportName,
      isDraft: false,
    });
    const newReportList = [...reports, {id, name: reportName, isDraft: false, isDefault: false}];

    ga.trackEvent({
      category: 'New Reports',
      action: 'Tab Click',
      label: 'Create Page',
    });

    this.setState({reportId: id, reports: newReportList, loadingReport: false});
    // Navigate to the report page
    history.push(`/reports/${id}`);
  };

  public handleDuplicateReport = async (id: number, name: string) => {
    this.setState({loadingReport: true});

    const {api, history, ga} = this.props;
    const {reports} = this.state;

    ga.trackEvent({
      category: 'New Reports',
      action: 'Page Settings',
      label: 'Duplicate report page',
    });

    const {id: newReportId} = await duplicateUserReport(
      api,
      {
        name,
      },
      {report: id}
    );
    const newReportList = [...reports, {id: newReportId, name, isDraft: false, isDefault: false}];

    this.setState({reportId: newReportId, reports: newReportList, loadingReport: false});
    // Navigate to the newly created report page
    history.push(`/reports/${newReportId}`);
  };

  public renderReport() {
    const {api, ga} = this.props;
    const {reportId} = this.state;

    return (
      <ReportWithCancelToken
        api={api}
        history={this.props.history}
        reportId={reportId || undefined}
        onReportChange={(id: number) => this.handleReportChange(api, id)}
        onDuplicateReport={(id: number, name: string) => this.handleDuplicateReport(id, name)}
        ga={ga}
      />
    );
  }

  public handleReportNavigation = (reportId: number) => {
    const {history} = this.props;

    history.replace(`/reports/${reportId}`);
  };

  public render() {
    const {fullscreen, reports, reportId, loadingReport} = this.state;
    const {ga} = this.props;

    if (loadingReport) {
      return (
        <Styled>
          {({classes}) => (
            <React.Fragment>
              <CircularProgress className={classes.statusIcon} disableShrink={true} />
            </React.Fragment>
          )}
        </Styled>
      );
    }

    return (
      <I18n>
        {t =>
          fullscreen ? (
            <Styled>
              {({classes}) => (
                <div className={classes.fullscreenWrapper}>
                  <ReportSelector
                    reports={reports}
                    onCreateReport={this.handleNewReportCreate}
                    onReportNavigation={this.handleReportNavigation}
                    selectedReport={reportId}
                    ga={ga}
                  />
                  {this.renderReport()}
                </div>
              )}
            </Styled>
          ) : (
            <PageWrapper title={t('topbar.header')}>
              <ReportSelector
                reports={reports}
                onCreateReport={this.handleNewReportCreate}
                onReportNavigation={this.handleReportNavigation}
                selectedReport={reportId}
                ga={ga}
              />
              {this.renderReport()}
            </PageWrapper>
          )
        }
      </I18n>
    );
  }
}

const move = (originalArray: any[], from: number, to: number) => {
  const clone = [...originalArray];
  Array.prototype.splice.call(clone, to, 0, Array.prototype.splice.call(clone, from, 1)[0]);
  return clone;
};
