import * as React from 'react';
import {I18n} from 'react-i18next';
import {Provider, Subscribe} from 'unstated';

import CircularProgress from '@material-ui/core/CircularProgress';
import PageWrapper from '../components/PageWrapper';
import {ApiClient, findChartById, IChart, removeChartById} from '../api';
import {apiUrl} from '../config';
import Chart from '../components/Chart/Chart';
import {RouteComponentProps} from 'react-router-dom';
import WithCancelToken, {InjectedCancelSourceProps} from '../components/hoc/WithCancelToken';
import GoogleAnalytics from '../GoogleAnalytics';

interface IState {
  chart?: IChart;
  fullscreen?: boolean;
}

interface IProps extends InjectedCancelSourceProps {
  api: ApiClient;
  ga: GoogleAnalytics;
}

const ChartWithCancelToken = WithCancelToken(Chart);
export default class ChartPage extends React.Component<RouteComponentProps<any> & IProps, IState> {
  constructor(props: RouteComponentProps<any> & IProps) {
    super(props);
    this.state = {
      fullscreen: props.api.isFullscreenMode(),
    };
  }

  public async componentWillMount() {
    const {history} = this.props;
    const api = new ApiClient({apiUrl});

    try {
      const chart = await findChartById(api, this.props.match.params.id);

      this.setState({chart});
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e);
      history.push(`/404`);
    }
  }

  public handleDeleteChart = async (id: number) => {
    const {api, history} = this.props;

    // delete remote chart
    await removeChartById(api, id);
    history.push(`/`);
  };

  public handleReplaceChart = (chartId: number, newChartData: IChart) => {
    const {chart} = this.state;

    if (chart && chartId === chart.id) {
      this.setState({chart: newChartData});
    }
  };

  public renderChart(api: ApiClient, ga: GoogleAnalytics, chart?: IChart, fullscreen?: boolean) {
    return chart ? (
      <ChartWithCancelToken
        key={chart.id.toString()}
        chart={chart}
        api={api}
        ga={ga}
        fullscreen={fullscreen}
        onDelete={this.handleDeleteChart}
        isDefaultReport={false}
        onReplaceChart={this.handleReplaceChart}
        chartOnly={true}
      />
    ) : (
      <CircularProgress disableShrink={true} />
    );
  }

  public render() {
    const {chart, fullscreen} = this.state;

    return (
      <I18n>
        {t => (
          <Provider>
            <Subscribe to={[ApiClient, GoogleAnalytics]}>
              {(api: ApiClient, ga: GoogleAnalytics) =>
                fullscreen ? (
                  this.renderChart(api, ga, chart, fullscreen)
                ) : (
                  <PageWrapper title={t('topbar.header')}>
                    {this.renderChart(api, ga, chart, fullscreen)}
                  </PageWrapper>
                )
              }
            </Subscribe>
          </Provider>
        )}
      </I18n>
    );
  }
}
