import {omit} from 'lodash';

const startTime = {hour: 0, minute: 0, second: 0};
const endTime = {hour: 23, minute: 59, second: 59};

const descriptiveDateRange = {
  'cur:day': {
    start: {year: 0, month: 0, day: 0, week: null, ...startTime},
    end: {year: 0, month: 0, day: 0, week: null, ...endTime},
    type: 'descriptive',
    value: 'cur:day',
    title: 'today',
  },
  'cur:week': {
    start: {year: 0, month: null, day: 1, week: 0, ...startTime},
    end: {year: 0, month: null, day: 7, week: 0, ...endTime},
    type: 'descriptive',
    value: 'cur:week',
    title: 'current week',
  },
  'last:day:7': {
    start: {year: 0, month: 0, day: -6, week: null, ...startTime},
    end: {year: 0, month: 0, day: 0, week: null, ...endTime},
    type: 'descriptive',
    value: 'last:day:7',
    title: 'past 7 days',
  },
  'last:day:30': {
    start: {year: 0, month: 0, day: -29, week: null, ...startTime},
    end: {year: 0, month: 0, day: 0, week: null, ...endTime},
    type: 'descriptive',
    value: 'last:day:30',
    title: 'past 30 days',
  },
  'cur:month': {
    start: {year: 0, month: 0, day: 1, week: null, ...startTime},
    end: {year: 0, month: 0, day: 0, week: null, ...endTime},
    type: 'descriptive',
    value: 'cur:month',
    title: 'current month',
  },
  'cur:year': {
    start: {year: 0, month: 1, day: 1, week: null, ...startTime},
    end: {year: 0, month: 0, day: 0, week: null, ...endTime},
    type: 'descriptive',
    value: 'cur:year',
    title: 'current year',
  },
  yesterday: {
    start: {year: 0, month: 0, day: -1, week: null, ...startTime},
    end: {year: 0, month: 0, day: -1, week: null, ...endTime},
    type: 'descriptive',
    value: 'yesterday',
    title: 'yesterday',
  },
  'prev:week:day': {
    start: {year: 0, month: 0, day: -7, week: null, ...startTime},
    end: {year: 0, month: 0, day: -7, week: null, ...endTime},
    type: 'descriptive',
    value: 'prev:week:day',
  },
  'prev:month:day': {
    start: {year: 0, month: -1, day: 0, week: null, ...startTime},
    end: {year: 0, month: -1, day: 0, week: null, ...endTime},
    type: 'descriptive',
    value: 'prev:month:day',
  },
  'prev:year:day': {
    start: {year: -1, month: 0, day: 0, week: null, ...startTime},
    end: {year: -1, month: 0, day: 0, week: null, ...endTime},
    type: 'descriptive',
    value: 'prev:year:day',
  },
  'prev:week': {
    start: {year: 0, month: null, day: 1, week: -1, ...startTime},
    end: {year: 0, month: null, day: 7, week: -1, ...endTime},
    type: 'descriptive',
    value: 'prev:week',
    title: 'last week',
  },
  'prev:week:partial': {
    start: {year: 0, month: null, day: 1, week: -1, ...startTime},
    end: {year: 0, month: null, day: 0, week: -1},
    type: 'descriptive',
    value: 'prev:week:partial',
    title: 'last week',
  },
  'prev:year:week': {
    start: {year: -1, month: null, day: 1, week: 0, ...startTime},
    end: {year: -1, month: null, day: 7, week: 0, ...endTime},
    type: 'descriptive',
    value: 'prev:year:week',
  },
  prev7days: {
    start: {year: 0, month: 0, day: -13, week: null, ...startTime},
    end: {year: 0, month: 0, day: -7, week: null, ...endTime},
    type: 'descriptive',
    value: 'prev7days',
  },
  'prev:month:full': {
    start: {year: 0, month: -1, day: 1, week: null, ...startTime},
    end: {year: 0, month: -1, day: 'last', week: null, ...endTime},
    type: 'descriptive',
    value: 'prev:month:full',
    title: 'last month',
  },
  'prev:month:partial': {
    start: {year: 0, month: -1, day: 1, week: null, ...startTime},
    end: {year: 0, month: -1, day: 0, week: null},
    type: 'descriptive',
    value: 'prev:month:partial',
  },
  'prev:year:month': {
    start: {year: -1, month: 0, day: 1, week: null, ...startTime},
    end: {year: -1, month: 0, day: 'last', week: null, ...endTime},
    type: 'descriptive',
    value: 'prev:year:month',
  },
  'prev:year:full': {
    start: {year: -1, month: 1, day: 1, week: null, ...startTime},
    end: {year: -1, month: 12, day: 31, week: null, ...endTime},
    type: 'descriptive',
    value: 'prev:year:full',
    title: 'last year',
  },
  'prev:year:partial': {
    start: {year: -1, month: 1, day: 1, week: null, ...startTime},
    end: {year: -1, month: 0, day: 0, week: null},
    type: 'descriptive',
    value: 'prev:year:partial',
  },
};

const periods = {
  today: () => descriptiveDateRange['cur:day'],
  yesterday: () => descriptiveDateRange.yesterday,
  'current week': () => descriptiveDateRange['cur:week'],
  'last week': () => descriptiveDateRange['prev:week'],
  'current month': () => descriptiveDateRange['cur:month'],
  'last month': () => descriptiveDateRange['prev:month:full'],
  'current year': () => descriptiveDateRange['cur:year'],
  'last year': () => descriptiveDateRange['prev:year:full'],
  'past 7 days': () => descriptiveDateRange['last:day:7'],
  'past 30 days': () => descriptiveDateRange['last:day:30'],
  trailing: () => ({type: 'trailing', title: 'trailing'}),
  'fixed date': () => ({type: 'fixed', title: 'fixed date'}),
};

const comparisonRanges = {
  today: {
    yesterday: () => descriptiveDateRange.yesterday,
    'last week same day': () => descriptiveDateRange['prev:week:day'],
    'last month same day': () => descriptiveDateRange['prev:month:day'],
    'last year same day': () => descriptiveDateRange['prev:year:day'],
    'fixed date': () => ({}),
  },
  yesterday: {
    'previous period': () => {
      return {
        ...descriptiveDateRange.yesterday,
        referenceDescription: descriptiveDateRange.yesterday.start,
      };
    },
    'last week same day': () => {
      return {
        ...descriptiveDateRange['prev:week:day'],
        referenceDescription: descriptiveDateRange.yesterday.start,
      };
    },
    'last month same day': () => {
      return {
        ...descriptiveDateRange['prev:month:day'],
        referenceDescription: descriptiveDateRange.yesterday.start,
      };
    },
    'last year same day': () => {
      return {
        ...descriptiveDateRange['prev:year:day'],
        referenceDescription: descriptiveDateRange.yesterday.start,
      };
    },
    'fixed date': () => ({}),
  },
  'current week': {
    'previous week': () => descriptiveDateRange['prev:week'],
    'previous week (partial)': () => descriptiveDateRange['prev:week:partial'],
    'last year same week': () => descriptiveDateRange['prev:year:week'],
    'fixed date': () => ({}),
  },
  'last week': {
    'previous week': () => {
      return {
        ...descriptiveDateRange['prev:week'],
        referenceDescription: descriptiveDateRange['prev:week:day'].start,
      };
    },
    'last year same week': () => {
      return {
        ...descriptiveDateRange['prev:year:week'],
        referenceDescription: descriptiveDateRange['prev:week:day'].start,
      };
    },
    'fixed date': () => ({}),
  },
  'past 7 days': {
    'previous 7 days': () => descriptiveDateRange.prev7days,
    'fixed date': () => ({}),
  },
  'past 30 days': {
    'previous period': () => {
      return {
        ...descriptiveDateRange['last:day:30'],
        referenceDescription: {year: 0, month: 0, day: -30, week: null, ...startTime},
      };
    },
    'fixed date': () => ({}),
  },
  'current month': {
    'previous month (full)': () => descriptiveDateRange['prev:month:full'],
    'previous month (partial)': () => descriptiveDateRange['prev:month:partial'],
    'last year same month': () => descriptiveDateRange['prev:year:month'],
    'fixed date': () => ({}),
  },
  'last month': {
    'previous month (full)': () => {
      return {
        ...descriptiveDateRange['prev:month:full'],
        referenceDescription: omit(descriptiveDateRange['prev:month:day'].start, [
          'hour',
          'minute',
          'second',
        ]),
      };
    },
    'last year same month': () => {
      return {
        ...descriptiveDateRange['prev:year:month'],
        referenceDescription: descriptiveDateRange['prev:month:day'].start,
      };
    },
    'fixed date': () => ({}),
  },
  'current year': {
    'previous year (full)': () => descriptiveDateRange['prev:year:full'],
    'previous year (partial)': () => descriptiveDateRange['prev:year:partial'],
    'fixed date': () => ({}),
  },
  'last year': {
    'previous year (full)': () => {
      return {
        ...descriptiveDateRange['prev:year:full'],
        referenceDescription: descriptiveDateRange['prev:year:day'].start,
      };
    },
    'fixed date': () => ({}),
  },
  'fixed date': {
    'fixed date': () => ({}),
    trailing: () => ({}),
  },
  trailing: {
    'fixed date': () => ({}),
    trailing: () => ({}),
  },
};

const rangeGroups = [
  {
    title: 'day',
    ranges: [periods.today, periods.yesterday],
  },
  {
    title: 'week',
    ranges: [periods['current week'], periods['last week']],
  },
  {
    title: 'month',
    ranges: [periods['current month'], periods['last month']],
  },
  {
    title: 'year',
    ranges: [periods['current year'], periods['last year']],
  },
  {
    title: 'trailing',
    ranges: [
      periods['past 7 days'],
      periods['past 30 days'],
      periods.trailing,
      periods['fixed date'],
    ],
  },
];

export {periods, comparisonRanges, descriptiveDateRange, rangeGroups};
