import * as React from 'react';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import BarChartIcon from '@material-ui/icons/BarChart';
import WarningIcon from '@material-ui/icons/Warning';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {I18n} from 'react-i18next';
import Chip from '@material-ui/core/Chip/Chip';
import Typography from '@material-ui/core/Typography/Typography';
import green from '@material-ui/core/colors/green';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {differenceInSeconds} from 'date-fns';

import {format, readableDateTimeFormat} from '../../lib/dateUtils';
import {createStyled} from '../../style/';
import {i18n} from '../../i18n';
import {IChart, ChartType} from '../../api';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import {ViewMode} from './Chart';
import {isTableViewChart} from '../../lib/chartUtils';
import {timeFrameColors} from '../TimeFrameSelector';
import {replaceSpecialCharacters} from '../../lib/stringUtils';
import ChartGenerationTime from './ChartGenerationTime';

const Styled = createStyled(themeStyle => {
  return {
    chartFooterWrapper: {
      position: 'relative',
      height: '100%',
    },
    chartViewModeCont: {
      position: 'absolute',
      left: 5,
      bottom: 0,
      [themeStyle.breakpoints.down('sm')]: {
        position: 'relative',
      },
    },
    chartInfoCont: {
      height: '100%',
      [themeStyle.breakpoints.down('sm')]: {
        paddingTop: 1 * themeStyle.spacing.unit,
        height: 'auto',
      },
    },
    chartViewModeIcon: {
      padding: 0.6 * themeStyle.spacing.unit,
    },
    extra: {
      color: '#a3a6a8',
    },
  };
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: {
    useNextVariants: true,
  },
});

interface IProps {
  chart: IChart;
  viewMode: ViewMode;
  supportedViewModes: ViewMode[];
  onRefreshChart: (forceRealod: boolean) => void;
  onViewModeClick: (viewMode: ViewMode) => void;
}

export default class ChartFooter extends React.Component<IProps, {}> {
  public handleRefreshChart = (forceRealod: boolean) => {
    const {onRefreshChart} = this.props;

    onRefreshChart(forceRealod);
  };

  public handleViewMode = (viewMode: ViewMode) => {
    const {onViewModeClick} = this.props;

    onViewModeClick(viewMode);
  };

  public render() {
    const {chart, supportedViewModes} = this.props;

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <div className={classes.chartFooterWrapper}>
                {/* Chart view modes */}
                <div className={classes.chartViewModeCont}>
                  {(chart.view === 'table' || !chart.view) && (
                    <Grid container={true} alignItems="center" justify="flex-end">
                      {/* Icon */}
                      <Grid item={true}>
                        <Grid container={true} direction={'row'}>
                          <Grid item={true}>
                            {isTableViewChart(chart.type, chart.view) &&
                              chart.criteria.timeFrames.length === 1 &&
                              supportedViewModes.map((viewMode: ViewMode) => (
                                <Tooltip
                                  key={viewMode}
                                  title={t(`chart.footer.view_mode.${viewMode}`)}
                                >
                                  <span>
                                    <IconButton
                                      className={classes.chartViewModeIcon}
                                      onClick={this.handleViewMode.bind(this, viewMode)}
                                    >
                                      <ViewModeIcon
                                        viewMode={viewMode}
                                        selectedViewMode={this.props.viewMode}
                                      />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </div>
                {/* Chart info */}
                <Grid
                  className={classes.chartInfoCont}
                  container={true}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item={true}>
                    {/* When chart is in progress... */}
                    {chart.status === 'inProgress' && (
                      <Typography align="center" variant="caption">
                        {t('chart.footer.in_progress_text')}
                      </Typography>
                    )}
                    {/* When chart was errored... */}
                    {chart.status === 'error' && (
                      <Typography align="center" variant="caption">
                        {t('chart.status.error')}
                      </Typography>
                    )}
                    {/* When chart had a warning... */}
                    {chart.status === 'warning' && (
                      <Grid
                        container={true}
                        direction="row"
                        spacing={8}
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item={true}>
                          <Grid
                            container={true}
                            direction="row"
                            spacing={8}
                            justify="center"
                            alignItems="center"
                          >
                            {/* Icon */}
                            <Grid item={true}>
                              <WarningIcon color="disabled" />
                            </Grid>
                            {/* Text */}
                            <Grid item={true}>
                              <Typography align="center" variant="caption">
                                {chart.statusDetails
                                  ? chart.statusDetails
                                  : t('chart.status.warning')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {/* When chart results are ready */}
                    {chart.status === 'ready' &&
                      chart.jobFinishedAt &&
                      chart.jobStartedAt &&
                      !hasFixedTimeFrame(chart) && (
                        <Grid container={true} spacing={8} justify="center" alignItems="center">
                          {/* TimeFrame */}
                          <Grid item={true}>
                            <TimeFrame chart={chart} />
                          </Grid>
                          <Grid item={true}>
                            <ChartGenerationTime chart={chart} />
                          </Grid>
                          {/* Refresh button */}
                          <Grid item={true}>
                            <RefreshButton chart={chart} onClick={this.handleRefreshChart} />
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </div>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}

function TimeFrame(props: {chart: IChart}) {
  const {chart} = props;
  const {timeFrames} = chart.criteria;

  return (
    <I18n>
      {t => (
        <React.Fragment>
          <React.Fragment>
            <Grid container={true} direction="row" spacing={8} justify="center" alignItems="center">
              {timeFrames.map((timeFrame, index) => (
                <Grid key={index} item={true}>
                  <Grid
                    container={true}
                    direction="row"
                    spacing={8}
                    justify="center"
                    alignItems="center"
                  >
                    {/* Icon */}
                    <Grid item={true}>
                      {timeFrame.type === 'fixed' ? (
                        <CalendarIcon color="action" style={{color: timeFrameColors[index]}} />
                      ) : (
                        <AccessTimeIcon color="action" style={{color: timeFrameColors[index]}} />
                      )}
                    </Grid>
                    {/* Text */}
                    <Grid item={true}>
                      <Typography
                        align="left"
                        variant="caption"
                        style={{color: timeFrameColors[index]}}
                      >
                        {timeFrame.type === 'fixed' &&
                          `${format(
                            moment(timeFrame.startAt).toDate(),
                            readableDateTimeFormat
                          )} - ${format(moment(timeFrame.endAt).toDate(), readableDateTimeFormat)}`}
                        {timeFrame.type === 'trailing' &&
                          t('chart.footer.trailing_timeframe', {
                            hours: timeFrame.hoursToSub,
                          })}
                        {timeFrame.type !== 'trailing' &&
                          timeFrame.type !== 'fixed' &&
                          getDescriptiveTimeFrameName({
                            isPeriod1: index === 0,
                            description: timeFrame.description,
                          })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        </React.Fragment>
      )}
    </I18n>
  );
}

const customTimeFrameNames = {
  period1: {
    'prev:year:full': 'last year',
    'prev:week': 'last week',
    'prev:month:full': 'last month',
  },
  period2: {
    yesterday: 'previous period',
  },
};

function getDescriptiveTimeFrameName({
  isPeriod1,
  description,
}: {
  isPeriod1: boolean;
  description: string;
}) {
  const periodName = isPeriod1 ? 'period1' : 'period2';
  const customName =
    customTimeFrameNames[periodName] && customTimeFrameNames[periodName][description];

  return i18n.t(
    `date.period_type.${customName ? customName : replaceSpecialCharacters(description)}`
  );
}

function RefreshButton(props: {chart: IChart; onClick: (forceReaload: boolean) => void}) {
  const {chart, onClick} = props;

  return (
    <I18n>
      {t => (
        <MuiThemeProvider theme={theme}>
          {chart.criteria.timeFrames.some(timeFrame => timeFrame.type !== 'fixed') && (
            <Chip
              color="primary"
              icon={<RefreshIcon fontSize="small" />}
              variant="outlined"
              label={t('chart.actions.reload_bt')}
              clickable={true}
              onClick={() => onClick(true)}
            />
          )}
        </MuiThemeProvider>
      )}
    </I18n>
  );
}

function ViewModeIcon(props: {viewMode: ViewMode; selectedViewMode: ViewMode}) {
  const {viewMode, selectedViewMode} = props;

  if (viewMode === 'flat') {
    return <ViewHeadlineIcon color={viewMode === selectedViewMode ? 'primary' : undefined} />;
  } else if (viewMode === 'group') {
    return <ViewQuiltIcon color={viewMode === selectedViewMode ? 'primary' : undefined} />;
  } else {
    return <BarChartIcon color={viewMode === selectedViewMode ? 'primary' : undefined} />;
  }
}

function hasFixedTimeFrame(chart: IChart) {
  return (
    chart.type === 'offline_machines' ||
    chart.type === 'machine_cleaning' ||
    chart.type === 'machine_issues'
  );
}
