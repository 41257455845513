import * as React from 'react';
import {I18n} from 'react-i18next';
import LazyLoad from 'react-lazyload';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import scrollIntoView from 'scroll-into-view-if-needed';
import {History} from 'history';

import AddChartBt from './AddChartBt';
import ReportActions from './ReportActions';
import {
  ApiClient,
  IChart,
  IChartCriteria,
  findUserReport,
  findUserReportById,
  createUserReport,
  updateUserReport,
  updateChart,
  createChart,
  findChartsByReportId,
  findChartById,
  removeChartById,
  removeReportById,
  ChartType,
  ChartView,
  IReportData,
  IReportCriteria,
  refreshChart,
  IChartData,
  createDefaultChart,
} from '../../api';
import {i18n} from '../../i18n';
import Chart from '../Chart';
import {createStyled} from '../../style/index';
import ReportFilter from './ReportFilter';
import WithCancelToken, {InjectedCancelSourceProps} from '../hoc/WithCancelToken';
import GoogleAnalytics from '../../GoogleAnalytics';

const ChartWithCancelToken = WithCancelToken(Chart);
const Styled = createStyled(theme => {
  return {
    header: {
      marginBottom: theme.spacing.unit * 1.5,
      minHeight: '59px',
    },
    noReportWrapper: {
      textAlign: 'center',
    },
    chartsWrapper: {
      marginTop: theme.spacing.unit,
    },
    addChartPaper: {
      marginBottom: 4 * theme.spacing.unit,
      width: '100%',
      height: 40 * theme.spacing.unit,
    },
    addChartPaperGrid: {
      width: '100%',
      height: '100%',
    },
    fab: {
      position: 'fixed',
      bottom: 4 * theme.spacing.unit,
      left: 'auto',
      right: 4 * theme.spacing.unit,
    },
    bottomElement: {
      float: 'left',
      clear: 'both',
      width: '100%',
      height: 4 * theme.spacing.unit,
    },
    row: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  };
});

export interface IReport {
  id: number;
  name: string;
  isDraft: boolean;
}
interface IReportState {
  report?: IReportData | null;
  charts: IChart[];
  actionsMenuAnchorEl: HTMLElement | undefined;
  isLoading: boolean;
}
interface IProps extends InjectedCancelSourceProps {
  api: ApiClient;
  ga: GoogleAnalytics;
  history: History;
  reportId: number | undefined;
  onReportChange: (reportId: number) => Promise<void>;
  onDuplicateReport: (reportId: number, reportName: string) => Promise<void>;
}

export default class Report extends React.Component<IProps, IReportState> {
  public state: IReportState = {charts: [], actionsMenuAnchorEl: undefined, isLoading: false};
  public bottomEl: HTMLElement | null = null;

  public async componentWillMount() {
    try {
      const {reportId} = this.props;

      if (!reportId) {
        await this.setDefaultReport();
      } else {
        this.loadReport(reportId);
      }
    } catch (err) {
      // TODO: add error handling
      // tslint:disable-next-line:no-console
      console.error(err);
    }
  }

  public componentWillUnmount() {
    const {source} = this.props;

    source.cancel('Operation canceled.');
  }

  public async componentDidUpdate() {
    const {reportId} = this.props;

    if (reportId) {
      if (!this.state.report || (this.state.report && reportId !== this.state.report.id)) {
        this.setState((prevState: IReportState) => {
          if (prevState.isLoading !== true) {
            return {isLoading: true};
          }

          return null;
        });
        this.loadReport(reportId);
      }
    }
  }

  public scrollToBottom() {
    if (this.bottomEl) {
      scrollIntoView(this.bottomEl, {behavior: 'smooth'});
    }
  }

  public async setDefaultReport() {
    const {api, source} = this.props;
    const [defaultReport] = await findUserReport(
      api,
      {where: {isDefault: 1}},
      {cancelToken: source.token}
    );

    if (!defaultReport) {
      throw new Error('Default user report does not exist');
    }

    const reportCharts = await findChartsByReportId(api, defaultReport.id, {
      cancelToken: source.token,
    });

    this.setState({report: defaultReport, charts: reportCharts});
  }

  public async loadReport(reportId: number) {
    const {api, source} = this.props;
    const {history} = this.props;

    try {
      const [userReport, reportCharts] = await Promise.all([
        findUserReportById(api, reportId, {
          cancelToken: source.token,
        }),
        findChartsByReportId(api, reportId, {
          cancelToken: source.token,
        }),
      ]);

      this.setState({report: userReport, charts: reportCharts, isLoading: false});
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e);
      history.push('/404');
    }
  }

  public handleLoadReport = async (reportId: number) => {
    const {history} = this.props;

    this.setState({report: null, charts: []});
    // Navigate to the report page
    history.push(`/reports/${reportId}`);
  };

  public handleDeleteReport = async (): Promise<void> => {
    const {api, history, ga} = this.props;

    if (!this.state.report) {
      throw new Error('Invalid state');
    } else {
      const id = this.state.report.id;

      ga.trackEvent({
        category: 'New Reports',
        action: 'Page Settings',
        label: 'Remove report page',
      });

      await removeReportById(api, id);

      const [defaultReport] = await findUserReport(api, {where: {isDefault: 1}});

      // Navigate to the default report page
      history.push(`/reports/${defaultReport.id}`);
    }
  };

  public handleNewReport = async (name: string) => {
    const {api, history} = this.props;
    const {id} = await createUserReport(api, {
      name,
      isDraft: false,
    });

    this.setState({report: null, charts: []});
    // Navigate to the newly created report page
    history.push(`/reports/${id}`);
  };

  public handleCreateDefaultChart = async (data: {
    name?: string;
    type: ChartType;
    criteria?: IChartCriteria;
  }) => {
    const {api, ga} = this.props;
    const {report, charts} = this.state;
    const {name, type, criteria} = data;

    if (!report) {
      throw new Error('Invalid state');
    }

    const newChartPosition = charts.length ? charts[charts.length - 1].position + 1 : 0;
    // Create fake temporary chart to show the spinner
    const tempChart: IChart = {
      id: -1,
      position: newChartPosition,
      view: 'table',
      name: name || i18n.t(`chart_dialog.steps.chart_type.types.${type}`),
      type,
      criteria: criteria || {
        where: [],
        timeFrames: [],
        from: 'paymenttransaction',
        select: [],
        orderBy: [],
      },
    };

    // Add a temp chart so we start rendering the spinner
    this.setState({charts: [...charts, tempChart]});
    this.scrollToBottom();

    ga.trackEvent({
      category: 'New Reports',
      action: 'Form Submit',
      label: `Create default report (${type})`,
    });

    // Replace the temp chart by the real one
    const {id: newChartId} = await createDefaultChart(api, report.id, {
      name: tempChart.name,
      type: tempChart.type,
      position: newChartPosition,
    });
    const newChart = await findChartById(api, newChartId);
    this.setState(state => {
      const filterdCharts = state.charts.filter(c => c !== tempChart);

      filterdCharts.push(newChart);

      return {charts: filterdCharts};
    });
  };

  public handleCreateChart = async (data: {
    name: string;
    type: ChartType;
    view: ChartView;
    criteria: IChartCriteria;
  }) => {
    const {api, ga} = this.props;
    const {report, charts} = this.state;

    if (!report) {
      throw new Error('Invalid state');
    }

    const newChartPosition = charts.length ? charts[charts.length - 1].position + 1 : 0;
    const tempChart: IChart = {
      ...data,
      id: -1,
      position: newChartPosition,
    };

    // Add a temp chart so we start rendering the spinner
    this.setState({charts: [...charts, tempChart]});
    this.scrollToBottom();

    ga.trackEvent({
      category: 'New Reports',
      action: 'Form Submit',
      label: 'Create custom report',
    });
    // Replace the temp chart by the real one
    const {id: newChartId} = await createChart(api, report.id, {
      ...data,
      position: newChartPosition,
    });
    const newChart = await findChartById(api, newChartId);
    this.setState(state => {
      const filterdCharts = state.charts.filter(c => c !== tempChart);

      filterdCharts.push(newChart);

      return {charts: filterdCharts};
    });
  };

  public handleDeleteChart = async (id: number) => {
    const {api, ga} = this.props;

    ga.trackEvent({
      category: 'New Reports',
      action: 'Chart Settings',
      label: 'Delete report',
    });

    // delete remote chart
    await removeChartById(api, id);

    // update state
    this.setState((prevState: IReportState) => {
      const index = prevState.charts.findIndex(chart => chart.id === id);

      if (index === -1) {
        return {};
      }

      const newCharts = [...prevState.charts];
      newCharts.splice(index, 1);

      return {...prevState, charts: newCharts};
    });
  };

  public handleReportTitleUpdate = async (label: string) => {
    const {api, ga, onReportChange, reportId} = this.props;
    const {report} = this.state;

    if (report && report.name !== label) {
      const reportData = {name: label, isDraft: false};

      ga.trackEvent({
        category: 'New Reports',
        action: 'Page Settings',
        label: 'Rename report page',
      });

      await updateUserReport(api, {id: report.id, ...reportData});
      const updatedReport = await findUserReportById(api, report.id);
      this.setState({report: updatedReport});

      if (reportId) {
        onReportChange(reportId);
      }
    }
  };

  public handleOrderCharts = (api: ApiClient) => async (charts: IChart[]) => {
    const orderedCharts = charts.map((chart, index) => ({
      ...chart,
      position: index + 1,
    }));

    orderedCharts.sort((a, b) => (a.position > b.position ? 1 : -1));

    this.setState({charts});

    await Promise.all(orderedCharts.map(l => updateChart(api, l.id, l)));
  };

  public handleSaveFilter = async (criteria: IReportCriteria) => {
    const {api, reportId} = this.props;
    const {charts} = this.state;

    if (!reportId) {
      throw new Error('Report id is missing');
    }

    await updateUserReport(api, {id: reportId, criteria});
    await Promise.all([charts.map(c => refreshChart(api, c.id, true))]);
    const updatedReport = await findUserReportById(api, reportId);

    this.setState({
      report: updatedReport,
      charts: charts.map(nc => {
        const chart: IChart = {...nc, status: 'inProgress'};

        return chart;
      }),
    });
  };

  public handleReplaceChart = (chartId: number, newChartData: IChart) => {
    const {charts} = this.state;

    const newCharts = charts.map(c => {
      if (c.id !== chartId) {
        return c;
      }

      return newChartData;
    });

    this.setState({charts: newCharts});
  };

  public render() {
    const {charts, report, isLoading} = this.state;
    const {api, ga, onDuplicateReport} = this.props;

    if (!report || isLoading) {
      return (
        <Styled>
          {({classes}) => (
            <div className={classes.noReportWrapper}>
              <CircularProgress disableShrink={true} />
            </div>
          )}
        </Styled>
      );
    }

    return (
      <Styled>
        {({classes}) => (
          <I18n>
            {t => (
              <React.Fragment>
                {/* Report header */}
                <ReportHeader
                  report={report}
                  charts={charts}
                  handleNewReport={this.handleNewReport.bind(
                    this,
                    t('report.report_default_title')
                  )}
                  handleReportTitleUpdate={this.handleReportTitleUpdate}
                  handleDuplicateReport={onDuplicateReport}
                  handleLoadReport={this.handleLoadReport}
                  handleDeleteReport={this.handleDeleteReport}
                  handleOrderCharts={this.handleOrderCharts(api)}
                  handleCreateChart={this.handleCreateChart}
                  handleCreateDefaultChart={data => this.handleCreateDefaultChart(data)}
                  handleSaveFilter={this.handleSaveFilter}
                  api={api}
                  ga={ga}
                />
                {/* Report charts */}
                <ReportCharts
                  api={api}
                  ga={ga}
                  charts={charts}
                  handleCreateChart={this.handleCreateChart}
                  handleCreateDefaultChart={data => this.handleCreateDefaultChart(data)}
                  handleDeleteChart={this.handleDeleteChart}
                  handleReplaceChart={this.handleReplaceChart}
                  isDefaultReport={report.isDefault}
                />
                {/* Bottom invisible element (used for auto-scrolling) */}
                <div
                  className={classes.bottomElement}
                  ref={el => {
                    this.bottomEl = el;
                  }}
                />
              </React.Fragment>
            )}
          </I18n>
        )}
      </Styled>
    );
  }
}

function ReportHeader(props: {
  report: IReportData;
  charts: IChart[];
  handleNewReport: () => Promise<void>;
  handleLoadReport: (reportId: number) => Promise<void>;
  handleDeleteReport: () => Promise<void>;
  handleOrderCharts: (chart: IChart[]) => void;
  handleReportTitleUpdate: (name: string) => Promise<void>;
  handleDuplicateReport: (id: number, name: string) => Promise<void>;
  handleCreateChart: (
    data: {
      name: string;
      type: ChartType;
      view: ChartView;
      criteria: IChartCriteria;
    }
  ) => Promise<void>;
  handleCreateDefaultChart: (
    data: {
      name?: string;
      type: ChartType;
    }
  ) => Promise<void>;
  handleSaveFilter: (criteria: IReportCriteria) => void;
  api: ApiClient;
  ga: GoogleAnalytics;
}) {
  const {
    report,
    charts,
    handleNewReport,
    handleLoadReport,
    handleDeleteReport,
    handleOrderCharts,
    handleReportTitleUpdate,
    handleDuplicateReport,
    handleCreateChart,
    handleCreateDefaultChart,
    handleSaveFilter,
    api,
    ga,
  } = props;

  return (
    <Styled>
      {({classes}) => (
        <Grid
          className={classes.header}
          container={true}
          justify={'space-between'}
          alignItems={'center'}
        >
          <Grid item={true} className={classes.row}>
            {Boolean(report.isDefault) && (
              <ReportFilter report={report} api={api} ga={ga} onSaveFilter={handleSaveFilter} />
            )}
          </Grid>
          {/* Report Actions */}
          <ReportActions
            selectedReportId={report && report.id}
            newReportEnabled={Boolean(report)}
            report={report}
            onRewReportClick={handleNewReport}
            onLoadReportClick={handleLoadReport}
            onDeleteReportClick={handleDeleteReport}
            onChartOrderUpdate={handleOrderCharts}
            onReportNameUpdate={handleReportTitleUpdate}
            onDuplicateReport={handleDuplicateReport}
            onCreateChartClick={handleCreateChart}
            onCreateDefaultChartClick={handleCreateDefaultChart}
            charts={charts}
            ga={ga}
          />
        </Grid>
      )}
    </Styled>
  );
}

function ReportCharts(props: {
  api: ApiClient;
  ga: GoogleAnalytics;
  charts: IChart[];
  isDefaultReport: boolean;
  handleCreateChart: (
    data: {
      name: string;
      type: ChartType;
      view: ChartView;
      criteria: IChartCriteria;
    }
  ) => Promise<void>;
  handleCreateDefaultChart: (
    data: {
      name?: string;
      type: ChartType;
    }
  ) => Promise<void>;
  handleDeleteChart: (id: number) => Promise<void>;
  handleReplaceChart: (chartId: number, newChartData: IChart) => void;
}) {
  const {
    api,
    ga,
    charts,
    handleCreateChart,
    handleCreateDefaultChart,
    handleDeleteChart,
    handleReplaceChart,
    isDefaultReport,
  } = props;

  return (
    <Styled>
      {({classes}) => (
        <div className={classes.chartsWrapper}>
          {/* Charts list */}
          {charts.map((chart: IChart) => (
            <LazyLoad key={chart.id.toString()} height={400}>
              <ChartWithCancelToken
                chart={chart}
                api={api}
                ga={ga}
                onDelete={handleDeleteChart}
                onReplaceChart={handleReplaceChart}
                isDefaultReport={isDefaultReport}
              />
            </LazyLoad>
          ))}

          {/* Add report button */}
          {!charts.length ? (
            <Paper className={classes.addChartPaper}>
              <Grid
                className={classes.addChartPaperGrid}
                container={true}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item={true}>
                  <AddChartBt
                    variant="contained"
                    onSave={handleCreateChart}
                    onDefaultChartSave={handleCreateDefaultChart}
                    isDefaultReport={isDefaultReport}
                    ga={ga}
                  />
                </Grid>
              </Grid>
            </Paper>
          ) : null}
        </div>
      )}
    </Styled>
  );
}
