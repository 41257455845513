import * as React from 'react';
import {Subscribe} from 'unstated';
import {I18n} from 'react-i18next';
import memoize from 'memoize-one';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import LogoutIcon from '@material-ui/icons/ExitToApp';

import {createStyled, theme as mainTheme} from '../style';
import {ApiClient, userMe} from '../api';
import Request from './Request';
import Grid from '@material-ui/core/Grid/Grid';

const memoizedCreateStyled = memoize(sidebarWidth =>
  createStyled(theme => ({
    appbar: {
      // [theme.breakpoints.up('md')]: {
      //   marginLeft: sidebarWidth,
      //   width: `calc(100% - ${
      //     typeof sidebarWidth === 'number' ? `${sidebarWidth}px` : sidebarWidth
      //   })`,
      // },
    },
    sidebarMenuButton: {
      marginLeft: -2 * theme.spacing.unit,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    title: {
      flex: 1,
    },
  }))
);

interface IProps {
  title: string | JSX.Element;
  sidebarWidth?: number | string;
  onSidebarOpen: () => void;
}

interface IState {
  logoutConfirmationOpen?: boolean;
  menuAnchorEl: EventTarget | undefined;
}

export default class Topbar extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {sidebarWidth: 32 * mainTheme.spacing.unit};

  public state = {logoutConfirmationOpen: false, menuAnchorEl: undefined, onSidebarOpen: undefined};

  public handleMenuOpen = (event: any) => {
    this.setState({menuAnchorEl: event.currentTarget || undefined});
  };

  public handleMenuClose = () => {
    this.setState({menuAnchorEl: undefined});
  };

  public handleLogoutClick = () => this.setState({logoutConfirmationOpen: true});

  public handleCancelLogout = () =>
    this.setState({logoutConfirmationOpen: false, menuAnchorEl: undefined});

  public render() {
    const {onSidebarOpen, sidebarWidth} = this.props;
    const {menuAnchorEl, logoutConfirmationOpen} = this.state;
    const Styled = memoizedCreateStyled(sidebarWidth);
    const isMenuOpen = Boolean(menuAnchorEl);

    return (
      <Subscribe to={[ApiClient]}>
        {({logout}: ApiClient) => (
          <Styled>
            {({classes}) => (
              <I18n>
                {t => (
                  <React.Fragment>
                    <AppBar className={classes.appbar} position="sticky">
                      <Toolbar>
                        {/* Sidebar menu button (top-left, small screens only)*/}
                        <IconButton
                          color="inherit"
                          aria-label="Open Side menu"
                          className={classes.sidebarMenuButton}
                          onClick={onSidebarOpen}
                        >
                          <MenuIcon />
                        </IconButton>

                        {/* User context menu (top-right) */}
                        <Request for={userMe}>
                          {result => (
                            <Grid container={true} alignItems="center">
                              {/* Left container */}
                              <Grid item={true} xs={10}>
                                <Grid container={true} justify="flex-start" alignItems="center">
                                  <Grid item={true}>
                                    {/* Title */}
                                    <Typography
                                      variant="h5"
                                      color="inherit"
                                      noWrap={true}
                                      className={classes.title}
                                    >
                                      {this.props.title}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* Right container */}
                              <Grid item={true} xs={2}>
                                <Grid container={true} justify="flex-end" alignItems="center">
                                  {/* Username */}
                                  {result.data && (
                                    <Grid item={true}>
                                      <Typography variant="caption" color="inherit" noWrap={true}>
                                        {result.data.username}
                                      </Typography>
                                    </Grid>
                                  )}

                                  {/* User icon */}
                                  <Grid item={true}>
                                    <IconButton
                                      aria-owns={open ? 'menu-appbar' : undefined}
                                      aria-haspopup="true"
                                      color="inherit"
                                      onClick={this.handleMenuOpen}
                                    >
                                      <AccountCircle />
                                    </IconButton>
                                    <Menu
                                      id="menu-appbar"
                                      anchorEl={menuAnchorEl}
                                      getContentAnchorEl={undefined}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      open={isMenuOpen}
                                      onClose={this.handleMenuClose}
                                    >
                                      <MenuItem onClick={this.handleLogoutClick}>
                                        <ListItemText>
                                          {t('topbar.user_menu.logout_opt')}
                                        </ListItemText>
                                        <ListItemIcon>
                                          <LogoutIcon />
                                        </ListItemIcon>
                                      </MenuItem>
                                    </Menu>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Request>
                      </Toolbar>
                    </AppBar>

                    {/* Logout confirmation dialog */}
                    <Dialog
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      open={logoutConfirmationOpen}
                      onClose={this.handleCancelLogout}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {t('topbar.logout_confirm.title')}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {t('topbar.logout_confirm.content')}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleCancelLogout} color="primary">
                          {t('topbar.logout_confirm.cancel_bt')}
                        </Button>
                        <Button onClick={logout} color="primary" autoFocus={true}>
                          {t('topbar.logout_confirm.confirm_bt')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                )}
              </I18n>
            )}
          </Styled>
        )}
      </Subscribe>
    );
  }
}
