import * as React from 'react';
import moment from 'moment-timezone';
import qs from 'qs';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {I18n} from 'react-i18next';

import {getClientTimeZone, format, dateFormat, readableDateFormat} from '../../../lib/dateUtils';
import {apiUrl} from '../../../config';
import {createStyled} from '../../../style/';
import {IChart, findChartById, ApiClient} from '../../../api';
import {i18n} from '../../../i18n';
import LineChartCmp from '../resultRenderers/LineChart';
import ValueBox from '../../ValueBox';
import DownloadRawTransactionsBt from '../DownloadRawTransactionsBt';
import {Subscribe} from 'unstated';
import {TimeFrame} from '../../ChartDialog/ChartDialog';
import {replaceSpecialCharacters} from '../../../lib/stringUtils';
import {price, stringToDate} from '../../../formatters';
import {descriptiveDateRange} from '../../../data/periods';
import {colors} from '../../../data/colors';
import dateRangeToString from '../../../lib/relativeDateParser';
import cleanNumber from '../../../formatters/cleanNumber';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import GoogleAnalytics from '../../../GoogleAnalytics';
import {toFileName} from '../../../lib/fileUtils';

const Styled = createStyled(theme => {
  return {
    chartWrapper: {
      paddingRight: theme.spacing.unit * 3,
      width: '100%',
      margin: 'auto',
      overflow: 'hidden',
    },
    chartContainer: {
      margin: 0,
    },
    resultsWrapper: {
      position: 'relative',
      width: '100%',
      height: 40 * theme.spacing.unit,
      paddingTop: 0,
    },
    resultsContainer: {
      width: '100%',
      height: '100%',
      paddingRight: 2.5 * theme.spacing.unit,
      paddingTop: 1.5 * theme.spacing.unit,
      paddingBottom: '5px',
    },
    header: {
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px 0px ${theme.spacing.unit *
        3}px`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    valueBoxBlock: {
      margin: `0px ${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 1.5}px 0px`,
      width: theme.spacing.unit * 23,
      [theme.breakpoints.down('sm')]: {
        margin: `0px ${theme.spacing.unit / 2}px 0px 0px`,
        width: '30%',
      },
    },
    downloadButton: {
      margin: `0px 0px 0px ${theme.spacing.unit * 2}px`,
      [theme.breakpoints.down('md')]: {
        margin: `0px ${theme.spacing.unit * 2}px 0px 0px`,
      },
    },
  };
});

type TimeUnit = 'year' | 'month' | 'weekday' | 'hour';
type MetricPropertyName = 'totalrevenue' | 'numpaymenttransactions' | 'numrevenuetransactions';

type RevenueData = {
  total: number;
  dataSet: Array<{[key in TimeUnit]: number} & {[key in MetricPropertyName]: number}>;
};

export type RevenueFullResult = Array<{
  xAxis: TimeUnit;
  revenue: {
    yAxis: MetricPropertyName;
    currencies: {
      [key: string]: RevenueData;
    };
  };
  revenueTx: {
    yAxis: MetricPropertyName;
  } & RevenueData;
  paymentTx: {
    yAxis: MetricPropertyName;
  } & RevenueData;
}>;

interface IProps {
  chart: IChart;
  results: RevenueFullResult;
}
interface IState {
  lineData: any[];
  data: object;
  xProperty: string;
  xAxisLabel?: string;
  metricProperty: string;
  yAxisLabel?: string;
  activeBox: StateByType;
  activeCurrency: string;
  downloadOpen: boolean;
}

type StateByType = 'revenue' | 'revenueTx' | 'paymentTx';

const typeToMetricPropertyMapping = {
  revenue: 'totalrevenue',
  paymentTx: 'numpaymenttransactions',
  revenueTx: 'numrevenuetransactions',
};

const typeToTranslationKey = {
  revenue: 'revenue',
  revenueTx: 'transactions',
  paymentTx: 'payments',
};

export default class RevenueFull extends React.Component<IProps, IState> {
  private xAxis: string;

  constructor(props: IProps) {
    super(props);

    this.xAxis = '';

    const initialReduce: {xProperty?: string; data: {}; currencies: string[]} = {
      data: {},
      currencies: [],
    };

    /**
     * Turns the results into a flat array of objects.
     * Also, it adds suffixes to the metric properties,
     * just so we can still show the timeframes separetly
     */
    const res = props.results.reduce((final, timeFrameResult, i) => {
      this.xAxis = timeFrameResult.xAxis;

      ['revenue', 'revenueTx', 'paymentTx'].forEach(resultType => {
        Object.keys(timeFrameResult[resultType].currencies).forEach(currency => {
          // Extract list of currencies
          if (final.currencies.indexOf(currency) === -1) {
            final.currencies.push(currency);
          }

          // Gather line chart data
          if (!final.data[currency]) {
            final.data[currency] = {};
          }

          final.data[currency][`${resultType}${i}`] = {
            data: timeFrameResult[resultType].currencies[currency].dataSet,
            total: timeFrameResult[resultType].currencies[currency].total,
            metricProperty: timeFrameResult[resultType].yAxis,
            xProperty: timeFrameResult.xAxis,
          };
        });
      });

      return final;
    }, initialReduce);

    const data = props.results.map((r, i) => {
      const timeFrameData = res.data[res.currencies[0]]
        ? res.data[res.currencies[0]][`revenue${i}`]
        : null;

      return timeFrameData ? timeFrameData.data : [];
    });

    // A single "date" can appear in both timeframes for the same currency,
    // in that case we want to group them into a single row and add up the numbers
    const mergedData = mergeData(data, 'totalrevenue', this.xAxis).sort((itemA, itemB) => {
      return itemA[this.xAxis] > itemB[this.xAxis] ? 1 : -1;
    });

    this.state = {
      lineData: mergedData,
      data: res.data,
      xProperty: this.xAxis,
      metricProperty: 'totalrevenue',
      activeBox: 'revenue',
      activeCurrency: res.currencies[0],
      downloadOpen: false,
    };
  }

  public handleValueBoxClick = (ga: GoogleAnalytics, type: StateByType, currency: string) => {
    const {results} = this.props;
    const {activeBox, xProperty, activeCurrency, data} = this.state;

    if (activeBox === type && activeCurrency === currency) {
      return;
    }

    const resultData = results.map((r, i) => {
      const timeFrameData = data[currency][`${type}${i}`];

      return timeFrameData ? timeFrameData.data : [];
    });
    const newData = mergeData(resultData, typeToMetricPropertyMapping[type], xProperty).sort(
      (itemA, itemB) => {
        return itemA[this.xAxis] > itemB[this.xAxis] ? 1 : -1;
      }
    );

    ga.trackEvent({
      category: 'New Reports',
      action: 'Timeline switcher',
      label: type,
    });

    this.setState({
      lineData: newData,
      metricProperty: typeToMetricPropertyMapping[type],
      activeBox: type,
      activeCurrency: currency,
    });
  };

  public getValueBoxtext = (
    propertyName: string,
    currency: string,
    options: {showCurrency: boolean} = {showCurrency: true}
  ) => {
    const {results} = this.props;
    const {data} = this.state;

    return results
      .map((v, i) => {
        const timeFrameData = data[currency][`${propertyName}${i}`];
        const value = timeFrameData ? timeFrameData.total || 0 : 0;

        return options.showCurrency ? `${price(value)} ${currency}` : cleanNumber(value);
      })
      .map<React.ReactNode>((v, i) => {
        return (
          <React.Fragment key={i}>
            {results.length > 1 ? <span style={{color: colors[i]}}>{v}</span> : v}
          </React.Fragment>
        );
      })
      .reduce((prev, curr) => [prev, ' vs ', curr]);
  };

  public getPercentageValue = (propertyName: string, currency: string) => {
    const {results} = this.props;
    const {data} = this.state;

    if (!results || results.length !== 2) {
      throw new Error('Percentage can only be calculated with 2 timeframes');
    }

    const values = results.map((r, i) => {
      return data[currency][`${propertyName}${i}`]
        ? data[currency][`${propertyName}${i}`].total
        : 0;
    });
    const sign = values[0] > values[1] ? 1 : -1;
    const color = sign === 1 ? '#0ab74d' : '#d91f06';
    const percentage =
      sign === 1
        ? ((values[0] - values[1]) / values[1]) * 100
        : ((values[1] - values[0]) / values[1]) * 100;

    return (
      <React.Fragment>
        {values[1] !== 0 ? <span style={{color}}>{(percentage * sign).toFixed(1)}%</span> : '-'}
      </React.Fragment>
    );
  };

  public handleDownloadTransactionPeriod = async (
    api: ApiClient,
    ga: GoogleAnalytics,
    index: number,
    chartId: number
  ) => {
    const chart = await findChartById(api, chartId);

    const criteria = buildCriteriaFromChart(chart, index);
    const fileName = toFileName(
      i18n
        .t('chart.default_charts.revenue_full.transactions')
        .toLowerCase()
        .replace(' ', '-'),
      ''
    );

    const stringifiedQueryString = qs.stringify(
      {
        ...criteria,
        timezone: getClientTimeZone(),
        access_token: api.state.state === 'authenticated' && api.state.tokens.access_token,
        filename: fileName,
      },
      {arrayFormat: 'brackets'}
    );

    ga.trackEvent({
      category: 'New Reports',
      action: 'Download',
      label: 'Transactions CSV',
    });

    const lastChar = apiUrl.charAt(apiUrl.length - 1);
    window.open(
      `${apiUrl}${lastChar === '/' ? '' : '/'}report-export/transactions?${stringifiedQueryString}`
    );

    this.setState({downloadOpen: false});
  };

  public handleDownloadButtonToggle = () => {
    this.setState(prevState => {
      return {downloadOpen: !prevState.downloadOpen};
    });
  };

  public handleCloseDownload = () => {
    this.setState({downloadOpen: false});
  };

  public render() {
    const {results, chart} = this.props;
    const {activeBox, activeCurrency, data, xProperty, downloadOpen} = this.state;
    const {timeFrames} = chart.criteria;
    const countCurrencies = Object.keys(data).length;

    return (
      <Subscribe to={[ApiClient, GoogleAnalytics]}>
        {(api: ApiClient, ga: GoogleAnalytics) => (
          <Styled>
            {({classes}) => (
              <I18n>
                {t => (
                  <div className={classes.chartWrapper}>
                    <Grid
                      className={classes.chartContainer}
                      container={true}
                      spacing={24}
                      direction="column"
                    >
                      <Grid justify="space-between" container={true}>
                        {/* Card selector for Revenue, Revenue transactions and Payment transactions */}
                        <Grid item={true} className={classes.header}>
                          {Object.keys(data).map(c => (
                            <Grid key={c} container={true}>
                              <Grid item={true} className={classes.valueBoxBlock}>
                                <ValueBox
                                  isActive={activeBox === 'revenue' && activeCurrency === c}
                                  title={`${t('chart.default_charts.revenue_full.revenue')} ${
                                    countCurrencies > 1 ? c : ''
                                  }`}
                                  value={
                                    <>
                                      {results.length === 2
                                        ? this.getPercentageValue('revenue', c)
                                        : this.getValueBoxtext('revenue', c)}
                                    </>
                                  }
                                  captionText={
                                    <>
                                      {results.length === 2
                                        ? this.getValueBoxtext('revenue', c)
                                        : undefined}
                                    </>
                                  }
                                  onClick={this.handleValueBoxClick.bind(this, ga, 'revenue', c)}
                                />
                              </Grid>
                              <Grid item={true} className={classes.valueBoxBlock}>
                                <ValueBox
                                  isActive={activeBox === 'revenueTx' && activeCurrency === c}
                                  title={`${t('chart.default_charts.revenue_full.transactions')} ${
                                    countCurrencies > 1 ? c : ''
                                  }`}
                                  value={
                                    <>
                                      {results.length === 2
                                        ? this.getPercentageValue('revenueTx', c)
                                        : this.getValueBoxtext('revenueTx', c, {
                                            showCurrency: false,
                                          })}
                                    </>
                                  }
                                  captionText={
                                    <>
                                      {results.length === 2
                                        ? this.getValueBoxtext('revenueTx', c, {
                                            showCurrency: false,
                                          })
                                        : undefined}
                                    </>
                                  }
                                  onClick={this.handleValueBoxClick.bind(this, ga, 'revenueTx', c)}
                                />
                              </Grid>
                              <Grid item={true} className={classes.valueBoxBlock}>
                                <ValueBox
                                  isActive={activeBox === 'paymentTx' && activeCurrency === c}
                                  title={`${t('chart.default_charts.revenue_full.payments')}  ${
                                    countCurrencies > 1 ? c : ''
                                  }`}
                                  value={
                                    <>
                                      {results.length === 2
                                        ? this.getPercentageValue('paymentTx', c)
                                        : this.getValueBoxtext('paymentTx', c, {
                                            showCurrency: false,
                                          })}
                                    </>
                                  }
                                  captionText={
                                    <>
                                      {results.length === 2
                                        ? this.getValueBoxtext('paymentTx', c, {
                                            showCurrency: false,
                                          })
                                        : undefined}
                                    </>
                                  }
                                  onClick={this.handleValueBoxClick.bind(this, ga, 'paymentTx', c)}
                                />
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        {/* Raw transactions download buttons */}
                        {api.isAllowed('report', 'export') && (
                          <Grid item={true} className={`${classes.header} hideOnPrint`}>
                            <Grid container={true} direction={'column'} spacing={0}>
                              <Grid item={true} className={classes.downloadButton}>
                                {timeFrames.length === 1 ? (
                                  <DownloadTransactionsButton
                                    timeFrame={timeFrames[0]}
                                    onClick={() =>
                                      this.handleDownloadTransactionPeriod(api, ga, 0, chart.id)
                                    }
                                  />
                                ) : (
                                  <>
                                    <Button
                                      size="medium"
                                      variant="contained"
                                      aria-label="Add"
                                      onClick={this.handleDownloadButtonToggle}
                                      disabled={false}
                                    >
                                      {t('report.download_raw_transactions')}
                                      <ArrowDropDownIcon />
                                    </Button>
                                    <Popper
                                      open={downloadOpen}
                                      anchorEl={null}
                                      transition={true}
                                      disablePortal={true}
                                      style={{zIndex: 9, position: 'absolute'}}
                                    >
                                      {({TransitionProps, placement}) => (
                                        <Grow
                                          {...TransitionProps}
                                          style={{
                                            transformOrigin:
                                              placement === 'bottom'
                                                ? 'center top'
                                                : 'center bottom',
                                          }}
                                        >
                                          <Paper id="menu-list-grow">
                                            <ClickAwayListener
                                              onClickAway={this.handleCloseDownload}
                                            >
                                              <MenuList>
                                                {timeFrames.map((tf, index) => (
                                                  <MenuItem
                                                    key={index}
                                                    disabled={isDowloadable(tf)}
                                                    onClick={() =>
                                                      this.handleDownloadTransactionPeriod(
                                                        api,
                                                        ga,
                                                        index,
                                                        chart.id
                                                      )
                                                    }
                                                  >
                                                    {DownloadTransactionsButtonTitle(tf)}
                                                  </MenuItem>
                                                ))}
                                              </MenuList>
                                            </ClickAwayListener>
                                          </Paper>
                                        </Grow>
                                      )}
                                    </Popper>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {/* Chart view (Line) */}
                      <Grid container={true} justify="center">
                        <Grid className={`${classes.resultsContainer} hideOnPrint`} item={true}>
                          <Grid
                            className={classes.resultsWrapper}
                            container={true}
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <LineChartCmp
                              data={this.state.lineData}
                              xProperty={this.state.xProperty}
                              lines={generateLinesData(results.length, this.state.metricProperty)}
                              xAxisLabelFormat={v => xAxisLabelFormatter(this.state.xProperty, v)}
                              yAxisLabelFormat={
                                activeBox === 'revenue' ? yValueFormatter : undefined
                              }
                              tooltipValueFormat={
                                activeBox === 'revenue' ? tooltipValueFormatter : undefined
                              }
                              tooltipLabelFormat={v =>
                                tooltipLabelFormatter(this.state.xProperty, v)
                              }
                              yAxisLabel={getYAxisLabel(activeBox, activeCurrency)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </I18n>
            )}
          </Styled>
        )}
      </Subscribe>
    );
  }
}

function getYAxisLabel(type: StateByType, currency: string) {
  const translatedType = i18n.t(`chart.default_charts.revenue_full.${typeToTranslationKey[type]}`);

  if (type === 'revenue' && currency) {
    return `${translatedType} (${currency})`;
  }

  return translatedType;
}

function DownloadTransactionsButtonTitle(timeFrame: TimeFrame) {
  let period;

  if (timeFrame.type === 'trailing') {
    period = i18n.t('chart.footer.trailing_timeframe', {
      hours: timeFrame.hoursToSub,
    });
  } else if (timeFrame.type === 'descriptive') {
    period = i18n.t(`date.period_type.${replaceSpecialCharacters(timeFrame.description)}`);
  } else {
    period = `${format(moment(timeFrame.startAt).toDate(), readableDateFormat)} - ${format(
      moment(timeFrame.endAt).toDate(),
      readableDateFormat
    )}`;
  }

  return period;
}

function isDowloadable(timeFrame: TimeFrame) {
  const dateSixMonthsAgo = moment()
    .subtract(6, 'months')
    .toDate();

  return isOlderThan(dateSixMonthsAgo, timeFrame);
}

function DownloadTransactionsButton(props: {timeFrame: TimeFrame; onClick: () => void}) {
  const {timeFrame, onClick} = props;

  return (
    <DownloadRawTransactionsBt
      label={i18n.t('report.download_raw_transactions')}
      onClick={onClick}
      disabled={isDowloadable(timeFrame)}
      disableDescription={i18n.t(`report.date_range_too_old`)}
    />
  );
}

function mergeData(dataSet: any[][], metricProperty: string, xProperty: string): any[] {
  const dataSetLength = dataSet.length;

  if (!dataSetLength) {
    return [];
  }

  const fullData: any[] = [];

  dataSet.forEach((d, i) => {
    d.map((data: any) => {
      const newData = {...data};
      const itemIndex = fullData.findIndex(mt => {
        return data[xProperty] === mt[xProperty];
      });

      newData[`${metricProperty}${i}`] = newData[metricProperty];
      delete newData[metricProperty];

      if (itemIndex === -1) {
        fullData.push(newData);
      } else {
        fullData[itemIndex] = Object.assign(fullData[itemIndex], newData);
      }
    });
  });

  return fullData;
}

function generateLinesData(numberOfLines: number, propertyName: string) {
  return Array.from({length: numberOfLines}).map((it, i) => {
    return {
      dataKey: `${propertyName}${i}`,
      label: i18n.t('chart.default_charts.revenue_full.total'),
      color: colors[i],
    };
  });
}

const supportedDataTypes = ['week_day', 'day_hour', 'month', 'quarter'];

function xAxisLabelFormatter(dataType: string, v: string | number) {
  if (typeof v === 'number' && supportedDataTypes.indexOf(dataType) > -1) {
    return i18n.t(`formatter.${dataType}.${v}`);
  }

  if (typeof v === 'string' && (dataType === 'date_day' || dataType === 'date_hour')) {
    return stringToDate(v, {type: dataType});
  }

  return v;
}

function tooltipLabelFormatter(dataType: string, label: string | number) {
  if ((dataType === 'date_day' || dataType === 'date_hour') && typeof label === 'string') {
    return stringToDate(label, {type: dataType, withYear: true});
  }

  return label.toString();
}

function tooltipValueFormatter(value: string | number | Array<string | number>) {
  return typeof value === 'number' ? price(value) : value;
}

function yValueFormatter(value: string | number) {
  return typeof value === 'number' ? price(value) : value;
}

function buildCriteriaFromChart(chart: IChart, timeframeIndex: number) {
  const {criteria} = chart;

  if (!criteria) {
    throw new Error('Missing chart criteria');
  }

  if (!criteria.timeFrames || !criteria.timeFrames[timeframeIndex]) {
    throw new Error('Invalid timeframe');
  }

  const hasCriteria = criteria.where && criteria.where.length;
  const periodDates = calculatePeriodDatesFromCriteria(criteria.timeFrames[timeframeIndex]);
  const endCriteria = {
    period1: {
      ...periodDates,
      referenceDate: new Date(),
    },
  };

  if (hasCriteria) {
    const resourceName = getResourceFromCriteria(criteria.where[0]);

    endCriteria[resourceName] = criteria.where[0].value;
  }

  return endCriteria;
}

function getResourceFromCriteria(where: {
  column: string;
  operator: string;
  value: string | number | number[];
}) {
  const validResources = Object.keys(resourcesMapping);
  const columnCriteria = where.column;
  const resource = validResources.find(
    validResource => columnCriteria === resourcesMapping[validResource].name
  );

  if (!resource) {
    throw new Error(`${columnCriteria} is not a valid resource`);
  }

  return resourcesMapping[resource].plural;
}

function calculatePeriodDatesFromCriteria(timeframe: TimeFrame) {
  if (timeframe.type === 'trailing') {
    return {
      startDate: {
        year: '0',
        month: '0',
        day: '0',
        hour: `-${timeframe.hoursToSub}`,
        minute: '0',
        second: '0',
      },
      endDate: {
        year: '0',
        month: '0',
        day: '0',
        hour: '0',
        minute: '0',
        second: '0',
      },
    };
  }

  if (timeframe.type === 'fixed') {
    return {
      startDate: timeframe.startAt,
      endDate: timeframe.endAt,
    };
  }

  if (timeframe.type === 'descriptive') {
    const matchingKey = Object.keys(descriptiveDateRange).find(
      key => descriptiveDateRange[key].value === timeframe.description
    );

    if (matchingKey) {
      const descriptiveRange = descriptiveDateRange[matchingKey];

      return {
        startDate: descriptiveRange.start,
        endDate: descriptiveRange.end,
      };
    }
  }

  throw new Error('Invalid timeframe type');
}

const resourcesMapping = {
  location: {
    name: 'location',
    plural: 'locations',
  },
  locationgroup: {
    name: 'locationgroup',
    plural: 'locationGroups',
  },
  machine: {
    name: 'machine',
    plural: 'machines',
  },
  machinegroup: {
    name: 'machinegroup',
    plural: 'machinegroups',
  },
};

function isOlderThan(dateLimit: Date, timeFrame: TimeFrame) {
  const momentDate = moment();
  let timeFrameStartDate: Date;

  if (timeFrame.type === 'trailing') {
    timeFrameStartDate = momentDate.subtract('hour', timeFrame.hoursToSub).toDate();
  } else if (timeFrame.type === 'descriptive') {
    const range = descriptiveDateRange[timeFrame.description].start;
    const now = moment().format();

    timeFrameStartDate = moment(dateRangeToString(range, now)).toDate();
  } else {
    timeFrameStartDate = moment(timeFrame.startAt).toDate();
  }

  return timeFrameStartDate < dateLimit;
}
