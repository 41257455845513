import * as React from 'react';
import {Subscribe} from 'unstated';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import {I18n} from 'react-i18next';

import {createStyled} from '../style';
import UpdateManager from '../UpdateManager';

const Styled = createStyled(theme => ({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 20,
  },
  updateIcon: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
}));

export default function UpdateNotification() {
  return (
    <Subscribe to={[UpdateManager]}>
      {(updateManager: UpdateManager) => (
        <I18n>
          {t => (
            <Styled>
              {({classes}) => (
                <Snackbar
                  open={updateManager.updateAvailable}
                  message={
                    <span className={classes.message}>
                      <SystemUpdateIcon className={classNames(classes.icon, classes.updateIcon)} />
                      {t('update_notification.message')}
                    </span>
                  }
                  action={[
                    <Button
                      key="apply"
                      aria-label={t('update_notification.apply')}
                      color="primary"
                      size="small"
                      onClick={updateManager.apply}
                    >
                      {t('update_notification.apply')}
                    </Button>,
                    <IconButton
                      key="close"
                      aria-label={t('update_notification.dismiss')}
                      color="inherit"
                      onClick={updateManager.dismiss}
                    >
                      <CloseIcon className={classes.icon} />
                    </IconButton>,
                  ]}
                />
              )}
            </Styled>
          )}
        </I18n>
      )}
    </Subscribe>
  );
}
