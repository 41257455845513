import withStyles, {
  StyleRules,
  StyleRulesCallback,
  WithStyles,
  WithStylesOptions,
} from '@material-ui/core/styles/withStyles';

/**
 * Create a Styled component with `children` render prop.
 *
 * This may be included in material-ui itself at some point:
 * https://github.com/mui-org/material-ui/issues/11047
 */
export function createStyled<
  S extends StyleRules | StyleRulesCallback,
  O extends WithStylesOptions
>(style: S, options?: O) {
  type RenderProp = (props: WithStyles<S, O['withTheme']>) => JSX.Element;
  type Props = WithStyles<S, O['withTheme']> & {children: RenderProp};

  function Styled(props: Props) {
    const args = Object.assign({}, props);
    delete args.children;
    return props.children(args);
  }

  const result = withStyles(style, options)(Styled as any) as any;
  return result as React.ComponentType<{children: RenderProp}>;
}
