import * as React from 'react';
import {I18n} from 'react-i18next';

import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import yellow from '@material-ui/core/colors/yellow';

import {createStyled} from '../style';

const Styled = createStyled(theme => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 2,
    backgroundColor: yellow[100],
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
}));

export default function Error({className, children}: {className?: string} & React.Props<any>) {
  return (
    <Styled>
      {({classes}) => (
        <I18n>
          {t => (
            <Paper className={classNames(classes.warning, className)}>
              <ErrorIcon className={classes.icon} />
              <Typography>{children || t('error.something_went_wrong')}</Typography>
            </Paper>
          )}
        </I18n>
      )}
    </Styled>
  );
}
