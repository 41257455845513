/* tslint:disable:no-console */
import ReactGA, {EventArgs} from 'react-ga';
import {Container} from 'unstated';

import {googleAnalyticsTrackerId} from './config';
import {ApiClient} from './api';

type GAState = {
  initialized: boolean;
  api: ApiClient;
};

type GAOptions = {
  api: ApiClient;
};

export default class GoogleAnalytics extends Container<GAState> {
  constructor(options: GAOptions) {
    super();

    this.state = {
      initialized: false,
      api: options.api,
    };
  }

  public initialize = () => {
    if (this.state.initialized) {
      return;
    }

    // if (!googleAnalyticsTrackerId) {
    //   console.warn(`Failed to initialize GA with trackerId: ${googleAnalyticsTrackerId}`);

    //   return;
    // }

    // ReactGA.initialize(googleAnalyticsTrackerId);
    // console.info('GA initialized');
    this.setState({initialized: true});
  };

  public trackEvent = (options: EventArgs) => {
    const {api, initialized} = this.state;

    if (!initialized) {
      console.warn('GA should first be initialized before tracking any event');

      return;
    }

    // if (!api.isAdmin()) {
    //   ReactGA.event(options);
    // }

    if (window.top) {
      window.top.postMessage({data: options, messageType: 'gaEvent'}, '*');
    }
  };
}
