import * as React from 'react';
import {I18n} from 'react-i18next';
import {Subscribe} from 'unstated';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {createStyled} from '../../style/index';
import Notification from '../Notification';
import {ApiClient} from '../../api';

const Styled = createStyled(theme => ({
  button: {},
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
}));

interface IProps {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  disableDescription?: string;
}

interface IState {
  hasError: boolean;
}

export default class DownloadRawTransactionsBt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  public handleClick = async () => {
    const {onClick} = this.props;

    try {
      onClick();
    } catch (e) {
      this.setState({hasError: true});
    }
  };

  public closeNotification = (): void => {
    this.setState({hasError: false});
  };

  public render() {
    const {hasError} = this.state;
    const {label, disabled, disableDescription} = this.props;

    return (
      <Subscribe to={[ApiClient]}>
        {(api: ApiClient) => (
          <Styled>
            {({classes}) => (
              <I18n>
                {t => (
                  <React.Fragment>
                    {hasError ? (
                      <Notification
                        message={t('chart.something_went_wrong')}
                        type="error"
                        onClose={this.closeNotification}
                      />
                    ) : null}

                    <Button
                      className={classes.button}
                      size="medium"
                      variant="contained"
                      aria-label="Add"
                      onClick={this.handleClick.bind(this, api)}
                      disabled={disabled || false}
                    >
                      <CloudDownloadIcon className={classes.leftIcon} />
                      {disabled && disableDescription ? disableDescription : label}
                    </Button>
                  </React.Fragment>
                )}
              </I18n>
            )}
          </Styled>
        )}
      </Subscribe>
    );
  }
}
